import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'utils/classNames';

import twitter from 'assets/svg/twitter.svg';
import linkedin from 'assets/svg/linkedin.svg';
import youtube from 'assets/svg/youtube.svg';
import idtm from 'assets/images/logo-id6-bw.png';

import Input from 'components/common/Input/Input';
import Button from 'components/buttons/Button';

import useStyles from './styles';

interface IProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: string;
  name?: string;
}

const Footer = ({ onChange, name }: IProps) => {
  const classes = useStyles();
  return (
    <footer className={classes.footerContainer}>
      <div className={classes.questionContainer}>
        <span className={classes.text}>Un site proposé par :</span>
        <a rel="noopener noreferrer" href="https://id6tm.org" target="_blank">
          <img src={idtm} alt="" className={classes.idtmImage} />
        </a>
        <span className={classNames(classes.text, classes.textStyle)}>
          Une question ou une suggestion d’amélioration à nous transmettre ?
        </span>
      </div>
      <div className={classes.newsteller}>
        <div className={classes.newstellerContent}>
          <div className={classes.secondContainer}>
            {/*<span className={classes.text}>S’abonner à la newsletter :</span>
          <Input
            className={classes.input}
            onChange={onChange}
            name={name}
            placeholder="example@mail.com"
            inputBaseClassName={classes.width}
            inputRootClassName={classes.gridColumn}
            inputClassName={classes.textInput}
          />
          <Button className={classNames(classes.btn, classes.width)} variant="outlined">
            S’abonner
          </Button>*/}
            <Button className={classes.contact} variant="outlined" href="mailto:support@diva-badges.fr">
              Nous contacter
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.iconRoot}>
        <div className={classes.iconContainer}>
          <div className={classNames(classes.circle, classes.circleTwitter)}>
            <a href={'https://twitter.com/id6tm'} rel={'noopener noreferrer'}>
              <img src={twitter} alt="twitter" />
            </a>
          </div>
          <div className={classNames(classes.circle, classes.circleLinkedin, classes.marginIcons)}>
            <a href={'https://fr.linkedin.com/company/id6'} rel={'noopener noreferrer'}>
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
          <div className={classes.circle}>
            <a href={'https://www.youtube.com/channel/UC9qG0gRv-OXzZGO5K5D3bAA'} rel={'noopener noreferrer'}>
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </div>
        <p className={classes.textTop}>
          <Link to="/insigno">Insigno</Link> <br />
          Qui sommes nous ? <br />
          Mentions légales <br />
          Politique de confidentialité
          <br />
          Retrait des données personnelles
        </p>
      </div>
    </footer>
  );
};

export default Footer;
