import React from 'react';
import classNames from 'utils/classNames';
import './scss/checkbox.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
}

const CheckBox = ({ label, className, ...rest }: Props) => (
  /* eslint-disable-next-line */
  <label className={classNames('checkbox_container', className)}>
    <div className="container_input">
      <input type="checkbox" {...rest} />
    </div>
    <div className="label_checkbox">{label}</div>
  </label>
);
export default CheckBox;
