import React from 'react';

import classNames from 'utils/classNames';

import ErrorText from 'components/inputs/ErrorText';
import Popup from 'components/common/Popup/Popup';
import Close from 'assets/svg/grey-poubelle.svg';
import Edit from 'assets/svg/picto-edit.svg';

import Button from 'components/buttons/Button';
import Title from 'components/common/Title/Title';
import useStyles from './styles';

interface Props {
  done?: boolean;
  initialOpen?: boolean;
  children?: React.ReactChild;
  title?: string;
  errorText?: string;
  onDelete?: () => void;
  open?: boolean;
  onToggle?: () => void;
  className?: string;
  titleForm?: React.ReactChild | React.ReactChild[];
  borderForm?: boolean;
  popUpTitle?: React.ReactChild | React.ReactChild[];
  popUpDescription?: React.ReactChild | React.ReactChild[];
  popUptext?: React.ReactChild | React.ReactChild[];
  addFormPropBorder?: string;
  TextTitleRowClassName?: string;
  addFormPropComponentClassName?: string;
}

const AddPropForm = ({
  done,
  open,
  onToggle,
  title,
  errorText,
  onDelete,
  children,
  className,
  titleForm,
  borderForm,
  popUpTitle,
  popUptext,
  popUpDescription,
  addFormPropBorder,
  TextTitleRowClassName,
  addFormPropComponentClassName,
}: Props) => {
  let header = null;
  const [openPopup, setOpenPopUp] = React.useState(false);
  const classes = useStyles();
  if (done) {
    header = (
      <div className={classes.headerClosed}>
        <div className={classes.title}>
          {titleForm && (
            <Title size={30} className={classNames(classes.TextTitleRow, TextTitleRowClassName)} title={titleForm} />
          )}
          <span className={classes.criteriaTextTitle}>{title}</span>
        </div>

        <div className={classes.iconsContainer}>
          <div onClick={onToggle} className={classes.iconsRoot}>
            <span className={classes.iconText}> Modifier</span>

            <img width={17} src={Edit} alt="Edit" />
          </div>

          <div
            onClick={() => {
              setOpenPopUp(true);
            }}
            className={classes.iconsRoot}
          >
            <span className={classes.iconText}> Supprimer</span>

            <img width={13} height={19} src={Close} alt="Delete" />
          </div>
        </div>
      </div>
    );
  }
  const handleClose = () => {
    setOpenPopUp(false);
  };
  return (
    <div
      className={classNames(classes.addPropFormContainer, className)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={classNames(
          classes.addFormPropComponent,
          borderForm && (addFormPropBorder || classes.addFormPropBorder),

          errorText && classes.addFormPropComponentError,
          addFormPropComponentClassName,
        )}
      >
        {header}
        {open && children}
      </div>
      {errorText && <ErrorText className={classes.addFormErrorText}>{errorText}</ErrorText>}
      <Popup open={openPopup} handleClose={handleClose}>
        <div className={classes.popUpContainer}>
          <Title title={popUpTitle} />
          <p className={classes.textDescription}>{popUpDescription}</p>
          <div className={classes.deleteContainer}>
            <Button
              size="large"
              onClick={() => {
                onDelete && onDelete();
                handleClose();
              }}
              className={classes.buttonPopUp}
            >
              Oui, supprimer
            </Button>
            <p onClick={() => handleClose()} className={classes.cancelText}>
              Non, annuler
              {' '}
              {popUptext}
            </p>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default AddPropForm;
