import React, { FunctionComponent, useState } from "react";
import classNames from 'utils/classNames';
import Button from 'components/buttons/Button';
import Title from 'components/common/Title/Title';

import badgeWhite from 'assets/svg/badge-icon.svg';
import badgeParentWhite from 'assets/svg/badge-group.svg';

import useStyles from './styles';

interface Props {
  className?: string;
  onSubmit: (simple: boolean) => void;
}

const CreateBadgeType: FunctionComponent<Props> = ({ className, onSubmit }) => {
  const [type, setType] = useState('');
  const classes = useStyles();

  function handleSubmit() {
    onSubmit(type === 'OPEN BADGE SIMPLE');
  }

  function renderType(title: string, src: string) {
    return (
      <div onClick={() => setType(title)} className={classNames(classes.renderType)}>
        <div className={classNames(classes.type_container, type === title && classes.typeContainerSelected)}>
          <img src={src} alt="" />
        </div>
        <div className={classes.typeTitle}>{title}</div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.createBadgeTypeContainer, className)}>
      <Title title="Type de l'Open Badge" size={30} className={classes.title} />
      <div className={classes.text}>Sélectionnez le type de l'Open Badge que vous souhaitez créer :</div>
      <div className={classes.consigne}>
        Un Metabadge est un Open Badge regroupant plusieurs Open Badges. Il est notamment utile lorsque vous souhaitez
        badger individuellement plusieurs éléments d'une même activité et ensuite les regrouper dans le même Open Badge.
      </div>
      <div className={classes.textWrapper}>
        {renderType('OPEN BADGE SIMPLE', badgeWhite)}
        {renderType('OPEN BADGE À ECHELONS', badgeParentWhite)}
      </div>
      <Button max size="large" onClick={handleSubmit} disabled={!type}>
        Continuer
      </Button>
    </div>
  );
};

export default CreateBadgeType;
