import React, { useState, useRef, useEffect } from 'react';

import Modal from '@material-ui/core/Modal/Modal';
import Button from 'components/buttons/Button';
import SnackBar from 'components/ui/SnackBar';
import CustomiseVisual from 'components/modals/CustomiseVisual/CustomiseVisual';
import ErrorText from 'components/inputs/ErrorText';

import addVisual from 'assets/svg/add-visual.svg';
import importVisual from 'assets/svg/import-visual.svg';
import deleteImage from 'assets/svg/pinkcancel.svg';
import file from 'assets/svg/file-export.svg';

import { readAsDataURL } from 'utils/fileReader';

import classNames from 'utils/classNames';

import useStyles from './styles';

interface Props {
  className?: string;
  value: Blob | null;
  onChange: (value: Blob | null, modelId: string) => void;
  modelId: string;
  errorText?: string;
  setValueSrc?: (e: string) => void;
  valueSrc?: string;
}

const BadgeVisualInput = ({ className, value, modelId, onChange, errorText, setValueSrc, valueSrc }: Props) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (value) {
      readAsDataURL(value).then((url) => {
        setValueSrc && setValueSrc(url);
      });
    }
    // eslint-disable-next-line
  }, [value]);

  async function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (error) {
      setError('');
    }
    if (e.target.files) {
      const file = e.target.files[0];
      const img = new Image();
      const src = await readAsDataURL(file);

      img.src = src;
      img.onload = () => {
        const aspect = img.height / img.width;

        if (aspect <= 1.1 && aspect >= 0.9 && img.height > 90 && img.width > 90 && file.size < 256 * 1024 * 1024) {
          if (onChange) {
            onChange(file, '');
          }
        } else {
          setError(
            // eslint-disable-next-line
            'Les images doivent être carrées et ne dépasse pas  256 Ko.\net la dimension ne doit pas être inférieure  à 90X90px',
          );
        }
      };
    }
  }

  return (
    <div
      className={classNames(
        classes.badgeVisualInputContainer,
        errorText && 'badge_visual_input_component_error',
        className,
      )}
    >
      <input
        className="display_none"
        accept="image/svg+xml,image/png,image/jpeg"
        type="file"
        onChange={handleInputChange}
        ref={fileInputRef}
        value=""
      />

      <div className={classes.buttonContainer}>
        <div className={classes.createVisual}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
            size="large"
            className={classNames(classes.button, classes.buttonAddVisual)}
            endIcon={<img src={addVisual} className={classes.visualImage} alt="" />}
            disabled={!!value}
          >
            Créer mon visuel
          </Button>
          <div className={classes.fileDeleteContainer}>
            {valueSrc && modelId ? (
              <div className={classes.badgeValueSrc}>
                <div className={classes.fileContainer}>
                  <img src={valueSrc} alt="badge visuel" height={40} width={40} style={{ color: 'red' }} />
                  <span className={classes.fileText}>Mon visuel personnalisé</span>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null, '');
                    setValueSrc && setValueSrc('');
                  }}
                  className={classes.deleteButton}
                >
                  <img src={deleteImage} alt="" />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.importVisualContainer}>
          <Button
            endIcon={<img src={importVisual} className={classes.visualImage} alt="" />}
            onClick={(e) => {
              e.stopPropagation();
              fileInputRef.current?.click();
            }}
            size="large"
            className={classes.button}
            disabled={!!value}
          >
            Importer mon visuel
          </Button>

          <div className={classes.fileDeleteContainer}>
            {value && !modelId && (
              <div className={classes.fileDeleteRoot}>
                <div className={classes.fileContainer}>
                  <img height={22} width={24} src={file} alt="" />{' '}
                  <span className={classes.fileText}>{(value as any)?.name}</span>
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null, '');
                    setValueSrc && setValueSrc('');
                  }}
                  className={classes.deleteButton}
                >
                  <img src={deleteImage} alt="" />
                </div>
              </div>
            )}
          </div>

          <p className={classes.visualText}>
            Votre image doit être au <b>format carré.</b> <br /> Son poids ne doit pas dépasser <b>256 ko.</b> <br />
            Ses dimensions doivent être au minimum de<b> 90x90 px.</b>
            <br /> Formats acceptés : <b>JPEG, PNG et SVG.</b>
          </p>
        </div>
      </div>

      <Modal open={open}>
        <CustomiseVisual
          selectedModelId={modelId}
          blob={modelId ? value : null}
          onSubmit={(blob, id) => {
            onChange(blob, id);
            setOpen(false);
          }}
          handleClose={() => setOpen(false)}
          setOpen={setOpen}
        />
      </Modal>
      <SnackBar variant="error" message={error} open={!!error} handleClose={() => setError('')} />
      {errorText && <ErrorText className={classes.errorText}>{errorText}</ErrorText>}
    </div>
  );
};

export default BadgeVisualInput;
