import React, { useState } from 'react';
import './AwardBadge.scss';
import { isStringEmpty, validateEmail } from 'utils/validation';
import { useForm } from 'hooks/useInputs';
import TextField from '@material-ui/core/TextField';
import Input from 'components/common/Input/Input';
import Button from 'components/buttons/Button';
import Container from "@material-ui/core/Container"
import { awardBadge, getBadge } from 'requests/badges';
import useApiState from 'hooks/useApiState';
import { RouteComponentProps } from 'react-router-dom';
// import CustomizedSnackbars from 'components/';
import { useDidMount, useDidUpdate } from 'hooks/useLifeCycle';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';
import Spinner from '../../components/ui/Spinner';
import CustomizedSnackbars from 'components/ui/SnackBar';

type IVariant = 'success' | 'warning' | 'error' | 'info';

const AwardBadgeContainer = ({ match, history }: RouteComponentProps) => {
  const [formState, formActions] = useForm({
    initialValues: {
      firstName: '',
      email: '',
    },
    validation: {
      firstName: isStringEmpty,
      email: validateEmail,
    },
  });
  const { values, errors, touched } = formState;
  const { handleChange } = formActions;

  const [awrdBadgeState, awardbadgeCall] = useApiState(awardBadge);
  const [openModal, setOpenModal] = useState(false);
  const [variantModal, setVariantModal] = React.useState<IVariant>('success');
  const [textModal, setTextModal] = useState('');
  const [badgeState, badgeCall] = useApiState(getBadge);

  const award = () => {
    if (formActions.validateForm()) {
      const uri: any = match.params;
      awardbadgeCall({ recepientName: values.firstName, recepientEmail: values.email, idBadge: uri.id });
    } else {
      formActions.setAllTouched(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useDidMount(() => {
    badgeCall((match.params as any).id);
  });

  useDidUpdate(() => {
    if (!awrdBadgeState.fetching) {
      setOpenModal(true);
      if (!awrdBadgeState.errors && !awrdBadgeState.errorCode && awrdBadgeState.data) {
        setVariantModal('success');
        setTextModal('Partagé avec succès');
        setTimeout(() => history.goBack(), 2000);
      } else {
        setVariantModal('error');
        setTextModal(awrdBadgeState.errorCode ?? 'Une erreur est survenue');
      }
    }
  }, [awrdBadgeState.fetching]);

  if (badgeState.fetching) return <Spinner />;

  return (
    <Container>
      <div className="container_award_badge">
        <div className="award_badge_form boxShadowRaduis">
          <div style={{ textAlign: 'center' }}>
            <img height={192} width={192} src={badgeState.data?.image} alt={badgeState.data?.title} />
          </div>
          <h1 className="title_header">
            {' '}
            <CheckCircle style={{ color: '#fc5e2d', width: 40, height: 30 }} />
            Attribution du badge "{badgeState.data?.title}"
          </h1>
          <h4 className="description_header">
            Vous pouvez attribuer ce badge via l’adresse email du destinataire. Pour partager votre badge par email,
            veuillez remplir le formulaire ci-dessous.
          </h4>
          <Input
            label="Nom"
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            autoComplete="off"
            required
            error={touched.firstName ? errors.firstName : ''}
          />
          <Input
            style={{ marginTop: 15 }}
            label="Adresse email"
            name="email"
            onChange={handleChange}
            value={values.email}
            autoComplete="off"
            required
            error={touched.email ? errors.email : ''}
          />
          <div style={{ justifyContent: "center", marginTop: 30 }} className="button_Container">
            <Button className="button" onClick={award}>
              Confirmer l'attribution de ce badge
            </Button>
          </div>
        </div>
        <CustomizedSnackbars
          variant={variantModal}
          open={openModal}
          handleClose={handleCloseModal}
          message={textModal}
        />
      </div>
    </Container>
  );
};
export default AwardBadgeContainer;
