import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 1024,
      [theme.breakpoints.down('md')]: {
        maxWidth: 850,
      },
    },
    featureFormSelectRoot: {
      display: 'grid',
      gridTemplateAreas: '"title empty" "category domain" "description description" "key emptykey" "checkbox checkbox"',
      gridGap: '30px 84px',
      gridTemplateColumns: '1fr 1fr',
      width: '100%',
    },

    title: {
      gridArea: 'title',
    },

    category: {
      gridArea: 'category',
    },

    domain: {
      gridArea: 'domain',
    },

    description: {
      gridArea: 'description',
      marginTop: 30,
    },

    key: {
      gridArea: 'key',
    },
    checkbox: {
      gridArea: 'checkbox',
    },
    titleBadgeFeature: {
      fontWeight: 'normal',
      fontSize: 30,
      textAlign: 'center',
      marginBottom: 80,
    },
    featureFormSelectContainer: {
      width: '100%',
      display: 'flex',
    },
    textInputClassName: {
      backgroundColor: 'rgba(227, 229, 255, 0.5)',
      border: '1px solid #E3E5FF',
    },
    textBadge: {
      color: theme.palette.grey.A400,
      fontSize: 20,
      marginTop: 40,
      marginBottom: 70,
      textAlign: 'center',
    },
    textBadgeFeatures: {
      marginTop: 0,
    },
    tabNavigation: {
      minWidth: 489,
      color: theme.palette.primary.main,
      fontSize: 30,
      fontWeight: 'normal',
      fontFamily: 'Rakkas',
      textTransform: 'capitalize',
      background: '#ffff',
      border: '2px solid #DDF7FF',
      width: '50% !important',
    },
    selectedTabClassName: {
      background: theme.palette.secondary.main,
    },
    echelonFormRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonFeature: {
      alignSelf: 'center',
      marginBottom: 20,
      padding: '0px !important',
    },
    buttonAddNewEchelon: {
      padding: '0px !important',
      alignSelf: 'center',
      marginBottom: 50,
      background: theme.palette.success.dark,
      width: 326,
      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    selectMenu: {
      backgroundColor: 'transparent !important',
    },
    itemClassName: {
      textTransform: 'capitalize',
    },
    badgeEchelonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    badgeEchelonText: {
      marginLeft: 50,
    },
    echelonImage: {
      marginRight: 57,
    },
    addFormPropBorder: {
      border: '1px solid #008EAD',
    },
    echelonText: {
      color: theme.palette.success.dark,
    },
    addFormPropComponentClassName: {
      padding: 0,
    },
    echelonDescription: {
      margin: 0,
      padding: '20px 100px 50px 100px',
    },
    textRequired: {
      fontSize: 18,
      fontWeight: 'bold',
      display: 'flex',
      marginBottom: 35,
    },
    requiredMark: {
      marginRight: 5,
      color: theme.palette.primary.main,
    },
  }),
);
