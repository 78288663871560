import React from 'react';
import classNames from 'utils/classNames';
import './scss/text.scss';

interface props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLDivElement> {
  label: string | undefined;
  className?: string;
  type?: 'title' | 'sub-title' | 'menu' | 'textClassic' | 'textSecondaire';
}
const Text = ({
 label, type, className, ...other
}: props) => (
  <div className={classNames(className, 'text_container_component')} {...other}>
    {label}
  </div>
);
export default Text;
