import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card_container_rating: {
    width: 244,
    position: 'relative',
    margin: 5,
    alignItems: 'center',
  },
  image_container_rating: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: 33,
    height: 33,
  },
  title_rating: {
    marginLeft: 15,
  },
  text_menu_component: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.grey.A400,
  },
}));
