import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    createBadgeVisualComponent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    titleBadgeVisual: {
      fontWeight: 'normal',
      fontSize: 30,
      textAlign: 'center',
      marginBottom: 20,
    },
    title: {
      fontSize: 20,
      fontWeight: 'normal',
      color: theme.palette.grey.A400,
    },
    createBadgeContainer: {
      marginTop: 43,
      paddingBottom: 50,
      display: 'flex',
      flexDirection: 'column',
    },
    echelonCheckbox: {
      marginTop: 30,
    },
    echelonInputContainer: {
      width: '100%',
    },
    tabNavigation: {
      minWidth: 489,
      color: theme.palette.primary.main,
      fontSize: 30,
      fontWeight: 'normal',
      fontFamily: 'Rakkas',
      textTransform: 'capitalize',
      background: '#ffff',
      border: '2px solid #DDF7FF',
      width: '50% !important',
    },
    selectedTabClassName: {
      background: theme.palette.secondary.main,
    },
    badgeEchelonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    badgeEchelonText: {
      marginLeft: 50,
    },
    echelonRow: {
      background: 'red',
    },
    useBadgeVisual: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 30,
      marginTop: 30,
    },
    badgeVisualImage: {
      width: 170,
      marginRight: 50,
    },
    badgeVisualDescription: {
      flex: 1,
    },
    useBadgeVisualButton: {
      padding: '0px !important',
      width: 424,
      background: theme.palette.success.main,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      '&:hover': {
        background: theme.palette.success.light,
      },
    },
    createBadgeVisualButton: {
      width: 424,
      background: theme.palette.primary.main,
      color: '#ffff',
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },

    badgeVisualText: {
      fontSize: 20,
      lineHeight: '30px',
      margin: 0,
      marginBottom: 15,
    },
    echelonClassName: {
      marginBottom: 75,
      paddingLeft: 60,
    },
    collapseRoot: {
      border: '1px solid #008EAD',
    },
    collapseContainer: {
      paddingBottom: 95,
      borderRadius: 10,
    },
    echelonHeaderBorder: {
      borderBottom: '1px solid #008EAD',
    },
    addFormPropBorder: {
      border: '1px solid #008EAD',
      borderRadius: 0,
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
    },
    addFormPropComponentClassName: {
      marginBottom: 0,
    },
    CriteriaFormContainer: {
      width: '100%',
    },
    textRequired: {
      fontSize: 18,
      fontWeight: 'bold',
      display: 'flex',
      marginTop: 25,
    },
    requiredMark: {
      marginRight: 5,
      color: theme.palette.primary.main,
    },
    submitButton:{
      width: '300px !important'
    }
  }),
);
