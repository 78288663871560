import React, { useState, useEffect } from 'react';
import CriteriaForm, { CriteriaValues } from 'components/forms/CriteriaForm';
import useKeys from 'hooks/useKeys';

import criteriaImage from 'assets/svg/pictocriteria.svg';
import add from 'assets/svg/add.svg';
import EchelonInput from '../EchelonInput/EchelonInput';

import 'components/forms/scss/createBadgeCriteria.scss';
import 'components/forms/scss/createBadgeFeatures.scss';

import useStyles from './styles';

interface Props {
  index: number;
  echelon: string;
  values: CriteriaValues[];
  onDelete: (i: number) => void;
  onModify: (nextValues: CriteriaValues, i: number) => void;
  errorText?: string;
  onAdd: (nextValues: CriteriaValues) => void;
}
const EchelonCriteriaInput = ({
 index, echelon, values, onDelete, onModify, errorText, onAdd,
}: Props) => {
  const classes = useStyles();
  const [showEchelonCriteria, setShowEchelonCriteria] = useState(true);
  const [keys, addKey, deleteKey] = useKeys(values || []);
  useEffect(() => {
    if (values.length >= 1) setShowEchelonCriteria(false);
    else if (values.length === 0) setShowEchelonCriteria(true);
  }, [values.length]);

  return (
    <EchelonInput
      className={classes.collapseContainerClassName}
      borderBottomNone={classes.borderBottomNone}
      echelonHeaderBorder={classes.echelonHeaderBorder}
      index={index}
      echelon={echelon}
      errorText={errorText}
    >
      <div className="flex_column">
        {values.map((criteria, i) => (
          <CriteriaForm
            TextTitleRowClassName={classes.TextTitleRowClassName}
            addFormPropBorder={i !== values.length - 1 ? classes.addFormPropBorder : classes.removeBorder}
            onDelete={() => {
              deleteKey(i);
              onDelete(i);
            }}
            onSubmit={(nextValues) => onModify(nextValues, i)}
            {...criteria}
            done
            resetOnClose
            borderForm
            titleForm={(
              <div className="title_form_image_container">
                <img alt="" src={criteriaImage} className="criteria_form_image" />
                {' '}
                <span>
                  Critère
                  {i + 1}
                </span>
              </div>
            )}
            key={keys[i]}
          />
        ))}
        {showEchelonCriteria && (
          <CriteriaForm
            errorText={errorText}
            resetOnSubmit
            initialOpen
            onSubmit={(nextValues) => {
              addKey();
              onAdd(nextValues);
            }}
          />
        )}
      </div>
      {values.length >= 1 && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowEchelonCriteria(true);
          }}
          className={classes.addCriteria}
        >
          <img className="add_container_image" src={add} alt="" />
          <span>Nouveau critère</span>
        </div>
      )}
    </EchelonInput>
  );
};

export default EchelonCriteriaInput;
