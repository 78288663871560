import React, { useState, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';

import EchelonHeader from '../EchelonHeader/EchelonHeader';

import classNames from 'utils/classNames';

import useStyles from './styles';
interface Props {
  echelon: string;
  index: number;
  className?: string;
  errorText?: string;
  children?: React.ReactNode;
  rightComponent?: React.ReactNode;
  echelonHeaderBorder?: string;
  borderBottomNone?: string;
  collapseContainer?: string;
}

const EchelonVisual = ({
  echelon,
  index,
  className,
  children,
  rightComponent,
  errorText,
  echelonHeaderBorder,
  borderBottomNone,
  collapseContainer,
}: Props) => {
  const [checked, setChecked] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (errorText) setChecked(true);
  }, [errorText]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setChecked((check) => !check);
      }}
      className={classNames(className, checked && (classes.collapseContainer , collapseContainer))}
    >
      <EchelonHeader
        echelonHeaderClassName={classNames(echelonHeaderBorder, !checked && borderBottomNone)}
        echelon={echelon}
        index={index + 1}
        rightComponent={rightComponent}
      />
      <Collapse in={checked}>{children}</Collapse>
    </div>
  );
};

export default EchelonVisual;
