import React from 'react';

import './scss/loader.scss';

const Loader = () => (
  <div className="components_ui_loader fixed_fill flex_center">
    <div className="loading">
      <div className="bounceball" />
      <div className="loader_text">Veuillez patienter</div>
    </div>
  </div>
);

export default Loader;
