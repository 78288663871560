import React from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import classNames from 'utils/classNames';

import './scss/button.scss';

interface Props extends ButtonProps {
  [key: string]: any | { children?: React.ReactChild;
  className?: string;
  variant: 'text' | 'outlined' | 'contained';
  rounded?: boolean;
  large?: boolean;
  max?: boolean;
  fetching?: boolean;
  download?: boolean;}
}
const Button = ({ children, className, rounded, large, size, fetching, max, ...rest }: Props) => (
  <MuiButton
    {...rest}
    color="primary"
    className={classNames(
      'button_component',
      large && 'button_padding',
      rounded && 'button_radius',
      max && 'button_max',
      size && `submit_button_base submit_button_${size}`,
      className,
    )}
  >
    {children}
    {fetching && (
      <div className="button_loader flex_center">
        <CircularProgress color="inherit" size={24} />
      </div>
    )}
  </MuiButton>
);

Button.defaultProps = {
  variant: 'contained',
};

export default Button;
