import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    customiseVisualComponent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
      padding: '45px 57px',
      margin: 'auto',
      alignItems: 'center',
      overflow: 'auto',
    },
    cancel: {
      display: 'flex',
      alignSelf: 'flex-end',
      height: 30,
      width: 30,
      cursor: 'pointer',
    },
    title: {
      fontSize: 30,
      fontWeight: 'normal',
    },
    textVisual: {
      color: theme.palette.grey.A400,
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 60,
    },
    badgeVisualRoot: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px 80px',
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    modelSelect: {
      flex: '1 1 0%',
    },
    button: {
      marginTop: 40,
      padding: '0px !important',
    },
  }),
);
