export const critereTypeToText = (type: string): string => {
  switch (type) {
    case 'text':
      return 'Texte';
    case 'pdf':
      return 'Fichier PDF';
    case 'image':
      return 'Image';
    case 'questionnaire':
      return 'Questionnaire';
    default:
      return `Inconnu (${type})`;
  }
};

export default critereTypeToText;
