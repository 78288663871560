import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  badge_component_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 29,
    paddingBottom: 50,
  },
  sub_title_component: {
    fontFamily: 'Rakkas',
    fontSize: 30,
    lineHeight: '95%',
    color: theme.palette.primary.main,
  },
  badge_card_container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 31,
    alignItems: 'center',
  },
  badge_card_content: {
    margin: '12px 31px 12px 0px',
  },
  add_badgeContainer: {
    marginLeft: 20,
  },
  add_badge: {
    width: 78,
    height: 78,
    background: theme.palette.success.light,
    cursor: 'pointer',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  text_add: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    color: '#008EAD',
    margin: 1,
  },
  add_img: {
    fontSize: 68,
    color: '#d4d4d4',
  },
  linkToBadges: {
    display: ' flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: 46,
  },
  textLink: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    color: '#5B78C2',
    cursor: 'pointer',
  },
}));
