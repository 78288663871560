import React, { useContext, useState } from 'react';
import moment from 'moment';

import { RouteComponentProps, Link } from 'react-router-dom';
import localforage from 'localforage';
import { useForm } from 'hooks/useInputs';
import { useDidUpdate } from 'hooks/useLifeCycle';
import { register } from 'requests/auth';
import CustomizedSnackbars, { IVariant } from 'components/ui/SnackBar';
import {
  validateEmail,
  validatePassword,
  isStringEmpty,
  hasUppercase,
  hasLowercase,
  hasNumber,
  hasSpecial,
  validationRadio,
} from 'utils/validation';
import { setAuthorizationBearer } from 'requests/http';
import classNames from 'utils/classNames';

import useApiState from 'hooks/useApiState';
import userContext from 'contexts/userContext';

import Button from 'components/buttons/Button';
import Checkbox from 'components/common/Checkbox/Checkbox';
import Title from 'components/common/Title/Title';
import Input from 'components/common/Input/Input';
import DatePicker from 'components/pickers/DatePicker';
import RadioButton from 'components/common/Radio/Radio';

import useStyles from './styles';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

const RegisterContainer = ({ history }: RouteComponentProps) => {
  const { setUser } = useContext(userContext);
  const classes = useStyles();

  const [formState, formActions] = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      secondPassword: '',
      structure: '',
      age: '',
      userType: '',
      website: '',
      checked: false,
    },
    validation: {
      userType: validationRadio(['user', 'organization']),
      firstName: isStringEmpty,
      lastName: isStringEmpty,
      email: validateEmail,
      password: validatePassword,
      secondPassword: validatePassword,
      structure: (value: string, values) => {
        if (values.userType === 'organization') {
          return isStringEmpty(value);
        }
      },
      website: (value: string, values) => {
        if (values.userType === 'organization') {
          return isStringEmpty(value);
        }
      },
      checked: (checked) => {
        if (checked) return '';
        return 'Il faut accepter les conditions générales d’utilisation';
      },
    },
  });
  const { values, errors, touched } = formState;
  const { handleChange, setValues } = formActions;
  const [registerState, registerCall] = useApiState(register);
  const [openModal, setOpenModal] = useState(false);
  const [variantModal, setVariantModal] = React.useState<IVariant>('success');
  const [textModal, setTextModal] = useState('');
  const [showPasswordState, setShowPassword] = useState(false);
  const [showSecondPasswordState, setShowSecondPassword] = useState(false);

  useDidUpdate(() => {
    if (!registerState.fetching) {
      setOpenModal(true);
      if (!registerState.errors && !registerState.errorCode && registerState.data) {
        setVariantModal('success');
        setTextModal('inscription effectué avec succès');
        setAuthorizationBearer(registerState.data.token.accessToken);
        localforage.setItem('auth', registerState.data);
        setUser(registerState.data.user);
        history.push('/');
      } else {
        setVariantModal('error');
        setTextModal(registerState.errorCode ?? 'Une erreur est survenue');
      }
    }
  }, [registerState.fetching]);
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {
      email,
      password,
      firstName,
      lastName,
      structure,
      secondPassword,
      age,
      userType,
      website,
    } = formState.values;
    const validAge = () => {
      if (!age) return true;
      const f = moment().diff(age, 'years');
      if (f >= 18) {
        return true;
      }
    };
    if (formActions.validateForm()) {
      const matchedPassword = password === secondPassword;
      if (matchedPassword && validAge()) {
        registerCall({
          email,
          password,
          firstName,
          lastName,
          structure,
          age,
          userType,
          website,
        });
      } else if (!validAge()) {
        setOpenModal(true);
        setVariantModal('error');
        setTextModal('Vous devez avoir plus de 18 ans');
      } else {
        setOpenModal(true);
        setVariantModal('error');
        setTextModal('Les mots de passe ne correspondent pas');
      }
    } else {
      formActions.setAllTouched(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onShowPassword = () => {
    setShowPassword(!showPasswordState);
  };

  const onShowSecondPassword = () => {
    setShowSecondPassword(!showSecondPasswordState);
  };

  const text = [
    "J'accepte les",
    <span key="span" className={classes.checkboxLabel}>
      &nbsp;conditions générales d’utilisation *
    </span>,
  ];
  return (
    <div className={classes.connexionContainer}>
      <div className={classes.connexionTitleRegister}>
        <Title row title="Inscription" />
      </div>
      <form className={classes.connexionRoot} onSubmit={onSubmit}>
        <RadioButton
          label="Vous êtes"
          radios={[
            {
              key: 'user',
              value: 'Un individu',
            },
            {
              key: 'organization',
              value: 'Une structure',
            },
          ]}
          onChangeValue={(value) => {
            setValues({ userType: value, structure: '', website: '' });
          }}
          value={values.userType}
          required
          error={touched.userType ? errors.userType : ''}
        />
        <Input
          label="Prénom"
          name="firstName"
          onChange={handleChange}
          value={values.firstName}
          autoComplete="off"
          required
          error={touched.firstName ? errors.firstName : ''}
          className={classes.inputPicker}
        />
        <Input
          label="Nom"
          name="lastName"
          onChange={handleChange}
          value={values.lastName}
          autoComplete="off"
          required
          error={touched.lastName ? errors.lastName : ''}
          className={classes.input}
        />
        {values.userType === 'user' && (
          <>
            <DatePicker
              label="Date de naissance"
              name="age"
              type="date"
              optionnalText
              value={values.age}
              onChange={handleChange}
              autoComplete="off"
              classes={{ wrapper: classes.inputPicker, label: classes.labelPicker }}
            />
          </>
        )}
        {values.userType === 'organization' && (
          <Input
            label="Structure"
            name="structure"
            onChange={handleChange}
            value={values.structure}
            autoComplete="off"
            error={touched.structure ? errors.structure : ''}
            required={values.userType === 'organization'}
            className={classes.input}
          />
        )}
        <Input
          label="Adresse e-mail"
          name="email"
          onChange={handleChange}
          value={values.email}
          autoComplete="off"
          required
          error={touched.email ? errors.email : ''}
          className={classes.input}
        />
        <Input
          label="Mot de passe"
          name="password"
          value={values.password}
          onChange={handleChange}
          showPassword={() => onShowPassword()}
          autoComplete="off"
          required
          error={touched.password ? errors.password : ''}
          type={!showPasswordState ? 'password' : ''}
          placeholder="*******"
        />
        <div style={{ marginTop: 0 }} className={classes.optionItem}>
          <div className={classes.option}>Ton mot de passe doit comporter 6 caractères minimum, dont au moins :</div>
          <div className={classes.option}>
            <div className={classes.optionWrapper}>
              <div className={classNames(hasUppercase(values.password) && classes.checkOption)}>• 1 majuscule</div>
              <div className={classNames(hasLowercase(values.password) && classes.checkOption)}>• 1 minuscule</div>
            </div>
            <div className={classes.optionWrapper}>
              <div className={classNames(hasNumber(values.password) && classes.checkOption)}>• 1 chiffre</div>
              <div className={classNames(hasSpecial(values.password) && classes.checkOption)}>
                • 1 caractère spécial
              </div>
            </div>
          </div>
        </div>
        <Input
          label="Confirmez votre mot de passe"
          name="secondPassword"
          type={!showSecondPasswordState ? 'password' : ''}
          placeholder="*******"
          showPassword={() => onShowSecondPassword()}
          value={values.secondPassword}
          onChange={handleChange}
          autoComplete="off"
          required
          error={touched.secondPassword ? errors.secondPassword : ''}
          className={classes.input}
        />
        {values.userType === 'user' && (
          <>
            <Input
              label="Structure"
              name="structure"
              onChange={handleChange}
              value={values.structure}
              autoComplete="off"
              optionnalText
              error={touched.structure ? errors.structure : ''}
              className={classes.input}
            />
          </>
        )}
        <Input
          label="Site web"
          name="website"
          onChange={handleChange}
          value={values.website}
          autoComplete="off"
          optionnalText={values.userType === 'user'}
          required={values.userType === 'organization'}
          error={touched.website ? errors.website : ''}
          className={classes.input}
        />
        <div className={classes.linkContainer}>
          <Checkbox
            name="checked"
            error={touched.checked ? errors.checked : ''}
            checked={values.checked}
            onChange={handleChange}
            label={text}
            className={classes.checkbox}
          />
          <div className={classes.textRequired}>
            <span className={classes.requiredMark}>* </span>
            champs obligatoires
          </div>
          <div className={classes.registerButtonContainer}>
            <div className={classes.registerButtonRoot}>
              <Button className={classes.submitButton} variant="contained" type="submit" size="large">
                Valider mon inscription
              </Button>
              <Link className={classes.registerLink} to="/login">
                J&lsquo;ai déjà un compte ?
              </Link>
            </div>
          </div>
        </div>
      </form>
      <CustomizedSnackbars variant={variantModal} open={openModal} handleClose={handleCloseModal} message={textModal} />
    </div>
  );
};

export default RegisterContainer;
