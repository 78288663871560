import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  badges_container: {},
  connexionTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 55,
  },
  dataContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
    backgroundColor: '#f1fcff',
    paddingBottom: 40,
  },
  content: {
    maxWidth: 1080,
    width: '100%',
    marginTop: 50,
  },
  badges_container_header: {
    marginBottom: '35px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightItems: {
    display: 'flex',
    alignItems: 'center',
  },
  frame: {
    marginRight: 30,
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 30,
    lineHeight: '28px',
  },
  create_badges_button: {
    marginBottom: '20px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      opacity: 0.7,
    },
  },
  textBtn: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },

  badges_title: {
    fontSize: 20,
    fontWeight: 300,
    marginBottom: 25,
  },

  badge_card: {
    marginBottom: 20,
  },

  show_profile: {
    textDecoration: 'underline',
    cursor: 'pointer',
    textAlign: 'right',
  },
}));
