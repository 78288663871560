import { axiosGet, Response, axiosPatch, axiosDelete, axiosPost, axiosPostFilesData } from './http';
import { Badge, Question } from './badges';

export type Status = 'accepted' | 'refused' | 'pending';

export interface Critere {
  _id: string;
  title: string;
  type: string;
  description: string;
  questions: Question[];
  display: string;
  file:string;
  ext:string
}

interface PatchCandidatureParams {
  status?: Status;
  id?: string;
  endorsement?: Status;
}

export interface CandidatureBase {
  _id: string;
  criterions: Critere[];
  status: Status;
  date: string;
  createdAt: string;
  endorsement: Status;
}

export interface Candidature extends CandidatureBase {
  idBadge: Badge;
  idUser: {
    role: string;
    active: boolean;
    _id: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    activeToken: string;
    createdAt: string;
    updatedAt: string;
    image: string;
  };
}

export interface CandidatureById extends CandidatureBase {
  idBadge: {
    _id: string;
    title: string;
    description: string;
    image: string;
    issuerId: string;
  };
  idUser: {
    role: string;
    active: boolean;
    _id: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    activeToken: string;
    createdAt: string;
    updatedAt: string;
  };
}

export const getCandidature = (): Promise<Response<any>> => axiosGet('/candidature/user');
export const getMyCandidature = (): Promise<Response<any>> => axiosGet('/candidature/myCandidature');

export const getCandidatureById = (id: string): Promise<Response<CandidatureById>> => axiosGet(`/candidature/${id}`);

export const PatchCandidature = ({ id, ...data }: PatchCandidatureParams): Promise<Response<any>> =>
  axiosPatch(`/candidature/${id}`, { data });

export interface BadgeCandidatureParams {
  status?: string;
  endorsed?: boolean;
}

export const getBadgeCandidature = (
  id: string,
  params: BadgeCandidatureParams = {},
): Promise<Response<{ candidatures: Candidature[]; badge: Badge }>> => axiosGet(`/candidature/badge/${id}`, { params });

export const deleteCandidature = (id: string): Promise<any> => axiosDelete(`/candidature/${id}`);

export const submitTextCritere = (data: { idCritere: string; title: string; type: string; description: string }): Promise<Response<any>> =>
  axiosPost('/critere', { data: { ...data, isEvidence: true }, sendToken: true });

export const submitQuestionCritere = (data: {
  idCritere: string;
  title: string;
  type: string;
  questions: any;
}): Promise<Response<any>> => axiosPost('/critere', { data: { ...data, isEvidence: true }, sendToken: true });

export const submitFileCriter = (data: {
  idCritere: string;
  title: string;
  type: string;
  file: string | Blob;
}): Promise<Response<any>> => {
  const dataForm = new FormData();
  dataForm.set('idCritere', data.idCritere);
  dataForm.set('title', data.title);
  dataForm.set('type', data.type);
  dataForm.set('file', data.file);

  return axiosPostFilesData('/critere/upload', {
    data: dataForm,
    sendToken: true,
  });
};

export const getCritere = (id: string): Promise<Response<any>> => axiosGet(`/critere/${id}`);

export interface AddCandidatureData {
  criterions: string[];
  idBadge: string;
  endorsement: boolean;
  deliveranceCode?: string;
}

export const addCandidature = (data: AddCandidatureData): Promise<Response<any>> =>
  axiosPost('candidature/', { data, sendToken: true });

export const getCandidatureCount = (params: { status?: string } = {}): Promise<Response<{ count: number }>> =>
  axiosGet('candidature/user/count', { params });

/* export const getEndorsement = (id: string): Promise<Response<{ candidatures: Candidature[]; badge: Badge }>> =>
  axiosGet(`/endossement/${id}`); */
export const getEndorsement = (id: string): Promise<Response<any>> => axiosGet(`/endossement/${id}`);
export const patchEndorsement = (
  idEndo: string,
  tokenEndo: string,
  text: string,
  status: string,
  typeEndo: string,
  idBadge: string,
  name: string,
  website: string,
): Promise<Response<any>> =>
  axiosPatch(`/endossement/${idEndo}`, {
    data: {
      idBadge,
      token: tokenEndo,
      text,
      status,
      typeEndo,
      name,
      website
    },
  });

export const addAgrement = (
  email: string,
  idBadge: string,
  name: string,
  typeEndo: 'badge' | 'benif' | 'emetteur',
): Promise<Response<any>> =>
  axiosPost('/endossement', {
    data: {
      email,
      idBadge,
      name,
      typeEndo,
    },
  });

export const getAllEndossementById = (id: string): Promise<Response<any>> => axiosGet(`/endossement/badge/${id}`);
export const getAllEndossementByUser = (id: string): Promise<Response<any>> => axiosGet(`/endossement/user/${id}`);

export const getCandidatureBadge = (id: string): Promise<Response<Candidature>> =>
  axiosGet(`/candidature/candidatureBadge/${id}`);
