import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  titleEchelonVisual: {
    color: theme.palette.success.dark,
    fontSize: 30,
  },
  collapseContainer: {
    background: '#ffff',
    border: '1px solid #008EAD',
    borderRadius: 10,
    marginBottom: 50,
  },
  collapseContainerClassName: {
    background: 'rgba(164, 252, 252, 0.4)',
  },
}));
