import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  containersRoot: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  appbarRootConfirm: {
    color: '#fff',
    backgroundColor: '#fc5e2d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  renvoyerButtonRoot: {
    padding: '0px 30px',
    backgroundColor: '#e43600',
    height: '50%',
  },
  headerImageContainer: {
    display: 'flex',
    width: '100%',
  },
  headerImage: {
    width: 605,
    [theme.breakpoints.down('sm')]: {
      width: 500,
    },
    [theme.breakpoints.down('md')]: {
      width: 500,
    },
  },
  headerTextContainer: {
    flex: '1 1 0',
  },
  headerText: {
    fontSize: 40,
    fontFamily: 'Rakkas',
    width: 400,
  },
  textBadge: {
    color: '#a4fcfc',
  },
  headerRoot: {
    padding: '50px 80px',

    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 40,
      paddingLeft: 40,
    },
  },
  btnHomeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnHome: {
    backgroundColor: '#00C4D1',
    padding: 20,
    color: '#0C3D9C',
    fontSize: 18,
    marginRight: 120,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#A4FCFC',
    },
  },
  paragraph: {
    fontSize: 18,
    fontWeight: 'bold',
    width: 460,
  },
}));
