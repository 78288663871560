import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme, { color?: string; size?: number; font?: string; width?: number }>((theme) => ({
  titleContainer: {
    position: 'relative',
  },
  title: {
    fontWeight: 900,
    fontSize: (props) => (props.size ? props.size : 40),
    fontFamily: (props) => (props.font ? props.font : 'Rakkas'),
    color: (props) => (props.color ? props.color : theme.palette.primary.main),
    margin: 0,
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 175,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: -1,
  },
  row1: {
    position: 'absolute',
    top: 0,
    width: 39,
    left: -71,
    borderRadius: 5,
    height: 5,
    backgroundColor: '#DDF7FF',
  },
  row2: {
    position: 'absolute',
    top: 16,
    width: 130,
    left: -173,
    height: 5,
    borderRadius: 5,
    backgroundColor: '#DDF7FF',
  },
  row3: {
    position: 'absolute',
    bottom: 0,
    width: 29,
    left: -124,
    top: 36,
    height: 8,
    borderRadius: 5,
    backgroundColor: '#DDF7FF',
  },
  row4: {
    position: 'absolute',
    top: 0,
    width: 29,
    right: -55,
    height: 5,
    borderRadius: 5,
    backgroundColor: '#DDF7FF',
  },
  row5: {
    position: 'absolute',
    top: 27,
    width: 76,
    right: -131,
    height: 6,
    borderRadius: 5,
    backgroundColor: '#DDF7FF',
  },
  row6: {
    position: 'absolute',
    top: 43,
    width: 39,
    right: -76,
    height: 5,
    borderRadius: 5,
    backgroundColor: '#DDF7FF',
  },
}));
