import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import useApiState from 'hooks/useApiState';
import ColorPicker from 'components/pickers/ColorPicker';
import CreateBadgeIconsCarousel from 'components/badge/CreateBadgeIconsCarousel';
import EditModelText from 'components/badge/EditModelText';
import EditModelIcon from 'components/badge/EditModelIcon';

import { useDidUpdate } from 'hooks/useLifeCycle';

import { readAsText } from 'utils/fileReader';
import classNames from 'utils/classNames';

import { ListIcons } from 'requests/model';

import useStyles from './styles';

interface Color {
  color: string;
  id: string;
  nodeName: string;
}

interface Props {
  modelId: string;
  className?: string;
  svgId: string;
  blob: Blob;
  setStep?: (e: number) => void;
}

const BadgeModelInput = ({ modelId, className, svgId, blob, setStep }: Props) => {
  const [colors, setColors] = useState<Color[]>([]);
  const [pickerOpen, setPickerOpen] = useState(-1);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [svgMounted, setSvgMounted] = useState(false);
  const [state, getListIcons] = useApiState(ListIcons);
  const emptyArrayRef = useRef([]);
  const prevBBoxRef = useRef<DOMRect | null>();
  const refColor = useRef([] as (HTMLDivElement | null)[]);
  const classes = useStyles();
  function createSvg(svg: Node): Color[] {
    const result: { color: string; id: string; nodeName: string }[] = [];
    const { length } = svg.childNodes;
    let r: Color[] = [];

    for (let i = 0; i < length; i += 1) {
      const child = svg.childNodes[i];
      r = [...r, ...createSvg(child)];
      if (!child.nodeName.startsWith('#')) {
        const { nodeName } = child;
        const color = (child as Element).getAttribute('fill');

        if (color) {
          const found = result.find((node) => node.nodeName === nodeName && node.color === color);
          if (!found) {
            const id = `_${Math.random()
              .toString(36)
              .substr(2, 9)}`;
            result.push({
              id,
              color,
              nodeName,
            });
            (child as Element).id = id;
          } else {
            (child as Element).id = found.id;
          }
        }
      }
    }

    return [...result, ...r];
  }

  function handleSvg(text: string) {
    // eslint-disable-next-line
    (document.getElementById(svgId) as Element).querySelector('svg')?.remove();

    const parser = new DOMParser();
    const svg: Element = parser.parseFromString(text, 'image/svg+xml').childNodes[0] as any;
    const nextColors = createSvg(svg);
    setColors(nextColors);
    if (nextColors.length) {
      const path = svg.querySelector(`#${nextColors[0].id}`);
      if (path) {
        setSelectedIcon(path.getAttribute('d') || '');
      }
    }

    (document.getElementById(svgId) as Element).appendChild(svg);

    setSvgMounted(true);
  }

  useEffect(() => {
    prevBBoxRef.current = null;

    readAsText(blob).then((text) => handleSvg(text));

    getListIcons(modelId);
    // eslint-disable-next-line
  }, [modelId, blob]);

  useDidUpdate(() => {
    colors.forEach((c) => {
      document.querySelectorAll(`#${c.id}`).forEach((el) => {
        const fill = el.getAttribute('fill');
        if (fill !== c.color) {
          el.setAttribute('fill', c.color);
        }
      });
    });
  }, [colors]);

  function onColorChange(c: string, i: number) {
    const newColors = [...colors];
    newColors[i] = { ...newColors[i], color: c };
    setColors(newColors);
  }

  function onIconChange(d: string) {
    if (colors.length) {
      const path = document.querySelector<SVGPathElement>(`#${colors[0].id}`);

      if (path) {
        const prevBox = prevBBoxRef.current || path.getBBox();
        if (!prevBBoxRef.current) {
          prevBBoxRef.current = prevBox;
        }
        path.setAttribute('d', d);

        const newPath = document.querySelector<SVGPathElement>(`#${colors[0].id}`);
        if (newPath) {
          const newBox = newPath.getBBox();

          const translate = `translate(${prevBox.x - newBox.x + (prevBox.width - newBox.width) / 2},${prevBox.y -
            newBox.y +
            (prevBox.height - newBox.height) / 2})`;
          newPath.setAttribute('transform', `${translate}`);
          setSelectedIcon(d);
        }
      }
    }
  }
  useEffect(() => {
    const visualModal = document.getElementById('visual_modal');
    if (visualModal) {
      visualModal.setAttribute('style', 'overflow:' + (pickerOpen !== -1 ? 'hidden' : 'auto'));
    }
  }, [pickerOpen]);
  function renderColor({ color, id }: Color, index: number) {
    let style = {};
    const openDiv = refColor.current[pickerOpen];

    if (openDiv) {
      const { top, left, height, width } = openDiv.getBoundingClientRect();
      style = { top: top + height + 15, left: left, width: Math.min(325, window.innerWidth - (left + width)) };
    }

    return (
      <div
        onClick={() => {
          setPickerOpen(index);
        }}
        key={id}
        className={classes.badgeModelColor}
        style={{ backgroundColor: color }}
        ref={(ref) => {
          refColor.current[index] = ref;
        }}
      >
        {ReactDOM.createPortal(
          <ColorPicker
            style={style}
            onClose={() => {
              setTimeout(() => {
                setPickerOpen((picker) => (picker === index ? -1 : picker));
              }, 300);
            }}
            onSelect={(c) => onColorChange(c, index)}
            open={pickerOpen === index}
          />,
          document.getElementById('visual_modal') as any,
        )}
      </div>
    );
  }

  return (
    <div className={classNames(classes.componentsInputsBadgeModelInput, className)}>
      <div className={classes.componentSvgContainer}>
        <span className={classes.badgeModelText}>Aperçu de votre Open Badge</span>
        <div
          onClick={() => {
            setStep && setStep(0);
          }}
          className={classes.badgeModelInputSvgContainer}
          id={svgId}
        >
          <span className={classes.badgeModelInputSvgText}>Changer de modèle de badge</span>
        </div>

        {svgMounted && <EditModelText className={classes.changeItem} id="badge-model-svg" />}
      </div>
      <div className={classes.badgeModelInputChangeContainer}>
        <span className={classes.badgeModelText}>Couleurs</span>
        <div className={classes.textBadgeModel}>
          <b>Conseil : </b> Privilégiez les couleurs complémentaires (bleu/orange, jaune/violet, rouge/vert, etc.) et ne
          choisissez pas plus de 3 teintes différentes pour un rendu plus esthétique !
        </div>
        <div className={classNames(classes.badgeModelColors, classes.colorPaddingContainer)}>
          {colors.map(renderColor)}
        </div>
        {svgMounted && <EditModelIcon path={selectedIcon} className={classes.changeItem} id="badge-model-svg" />}

        <div className={classes.iconsContainer}>
          <span className={classes.badgeModelText}>Pictogramme</span>
          <div className={classes.textBadgeModel}>
            <b> Conseil : </b>Le pictogramme renseigne en coup d’oeil sur le domaine de votre Open Badge. Choisissez-le
            bien !
          </div>
          <CreateBadgeIconsCarousel
            className={classes.badgeModelColors}
            icons={state.data || emptyArrayRef.current}
            onIconChange={onIconChange}
            selectedIcon={selectedIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default BadgeModelInput;
