import React, { useState, useContext, useEffect } from 'react';
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { isEmpty } from 'lodash';
import NotifContext from 'contexts/notifContext';
import Title from 'components/common/Title/Title';
import moment from 'moment';
// requests
import { Notif, getAllNotification, patchNotif } from 'requests/notif';
// utils
import classNames from 'utils/classNames';
// components
import Text from 'components/ui/Text';
// hooks
import useApiState from 'hooks/useApiState';
import { useWillUnmount, useDidMount } from 'hooks/useLifeCycle';

// style
import useStyles from './style';

const NotifPanel = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setNotif } = useContext(NotifContext);
  const [allNotifState, allNotifCall] = useApiState(getAllNotification);
  const [patchNotifState, patchNotifCall] = useApiState(patchNotif);
  const [notifSelected, setNotifSelected] = useState<Notif | null>(null);

  useDidMount(() => {
    allNotifCall();
    setNotif([]);
  });

  const onPatch = (notif: any) => {
    setNotifSelected(notif);
    patchNotifCall(true, notif._id);
  };
  useEffect(() => {
    if (patchNotifState.data && !isEmpty(notifSelected)) {
      history.push({
        state: { id: notifSelected?._id },
        pathname:
          notifSelected?.detail === 'demande'
            ? `/candidature/${notifSelected.idCandidature?.idBadge._id}/${notifSelected.idCandidature?._id}`
            : `/badge/${notifSelected?.idCandidature?.idBadge._id}`,
      });
    }
  }, [patchNotifState.data, notifSelected, history]);

  useWillUnmount(() => {
    setNotifSelected(null);
  });

  return (
    <div>
      <div className={classes.connexionTitle}>
        <Title
          row
          title="Notifications"
          className={classes.titleContainer}
          color="#F14E7F"
          rowColor={classes.rowColor}
          imageContainerClassName={classes.titlesContainer}
        />
      </div>
      <div className={classes.container_notif_panel}>
        {allNotifState.data && allNotifState.data.length !== 0
          ? allNotifState.data
              ?.map((el: Notif, i: number) => (
                <div key={el._id} className={classes.data_notif_container}>
                  <div
                    className={classNames(
                      classes.circleContainer,
                      el.isRead ? classes.backgroundWhite : classes.backgroundGray,
                    )}
                  />
                  <div>
                    <p className={classes.withOutMargin}>
                      <b>{el.from}</b>
                    </p>
                    <p>{el.text}</p>
                    <div>
                      <Text label={moment(el.date).format('L')} />
                    </div>
                    <p className={classes.link} onClick={() => onPatch(el)}>
                      Voir Plus
                    </p>
                  </div>
                  {allNotifState.data?.length !== i + 1}
                </div>
              ))
              .sort((a:any, b:any) => {
                const dateA = new Date(a.date).getTime();
                const dateB = new Date(b.date).getTime();
                return dateA > dateB ? 1 : -1;
               })
          : 'Aucune notification'}
      </div>
    </div>
  );
};

export default withRouter(NotifPanel);
