import React, { forwardRef, Ref, useState, useEffect, useRef } from 'react';

import { Model } from 'requests/model';

import Button from 'components/buttons/Button';
import BadgeModelInput from 'components/inputs/BadgeModelInput/BadgeModelInput';
import ModelSelect from '../../inputs/ModelSelect';
import cancel from 'assets/svg/cancel.svg';
import Title from 'components/common/Title/Title';

import useStyles from './styles';
const id = 'badge-model-svg';

interface Props {
  handleClose?: () => void;
  onSubmit?: (blob: Blob, id: string) => void;
  selectedModelId?: string;
  blob: Blob | null;
  setOpen?: (open: boolean) => void;
}

const CustomiseVisual = forwardRef(
  (
    { handleClose, onSubmit, selectedModelId: initialModelId, blob: initialBlob, setOpen }: Props,
    ref?: Ref<HTMLDivElement>,
  ) => {
    const [model, setModel] = useState<Model | null>(null);
    const [selectedModelId, setSelectedModelId] = useState(initialModelId);
    const [blob, setBlob] = useState<Blob | null>(initialBlob);
    const [step, setStep] = useState(0);
    const divRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    useEffect(() => {
      const controller = new AbortController();
      if (model && selectedModelId === model._id) {
        fetch(model.file, { signal: controller.signal, method: 'get' }).then((res) =>
          res.blob().then((b) => {
            setBlob(b);
          }),
        );
      }
      return () => {
        controller.abort();
      };
    }, [model, setOpen, selectedModelId]);
    const changeModel = (el: any) => {
      setModel(el);
      setSelectedModelId(el?._id);
    };

    function handleSubmit() {
      if (model?.type === 'engagement' && step === 0) {
        if (setOpen) {
          setOpen(false);
        }
        if (blob && onSubmit && selectedModelId) {
          onSubmit(new Blob([blob], { type: 'image/svg+xml' }), selectedModelId);
        }
      } else if (step === 0) {
        setSelectedModelId(model?._id);
        setStep(1);
      } else {
        const svg = document.getElementById(id)?.querySelector('svg');
        if (svg && onSubmit && selectedModelId) {
          onSubmit(new Blob([svg.outerHTML], { type: 'image/svg+xml' }), selectedModelId);
        }
      }
    }

    return (
      <div onClick={handleClose} ref={divRef} className="width_100 height_100 flex_center" tabIndex={-1}>
        <div id="visual_modal" onClick={(e) => e.stopPropagation()} className={classes.customiseVisualComponent}>
          <img
            src={cancel}
            alt=""
            className={classes.cancel}
            onClick={() => {
              setOpen && setOpen(false);
            }}
          />
          <Title title="Créer mon visuel" className={classes.title} />
          <div className={classes.badgeVisualRoot}>
            {step === 0 ? (
              <>
                <p className={classes.textVisual}>1. Choisisssez votre modèle d'Open Badge </p>
                <ModelSelect className={classes.modelSelect} value={model} onChange={changeModel} />
              </>
            ) : (
              selectedModelId &&
              model?.type !== 'engagement' &&
              blob && (
                <>
                  <p className={classes.textVisual}>2. Personnalisez votre Open Badge </p>

                  <BadgeModelInput
                    setStep={setStep}
                    blob={blob}
                    svgId={id}
                    className={classes.modelSelect}
                    modelId={selectedModelId}
                  />
                </>
              )
            )}
          </div>
          <Button
            className={classes.button}
            max
            disabled={!model && !selectedModelId}
            onClick={handleSubmit}
            size="large"
          >
            {step === 0 ? 'Continuer' : 'Finaliser mon visuel'}
          </Button>
        </div>
      </div>
    );
  },
);

export default CustomiseVisual;
