import { isNaN, map } from 'lodash';

export function isNumberEmpty(value: any) {
  if (value !== '' && !isNaN(value - 0)) return false;
  return true;
}

export function isStringEmpty(value: string) {
  if (value && value.length > 0) return '';
  return 'Ce champ est obligatoire';
}

export function isArrayEmpty(values: any[]) {
  if (values.length) return '';
  return 'Ce champ est obligatoire';
}

export function validateEmail(email: string): string {
  if (!email) return 'Ce champ est obligatoire';
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(`${email}`.toLowerCase())) {
    return '';
  }
  return 'E-mail invalide';
}
export function hasUppercase(s: string) {
  return /[A-Z]/g.test(s);
}
export function hasLowercase(s: string) {
  return /[a-z]/g.test(s);
}
export function hasNumber(s: string) {
  return /[0-9]/g.test(s);
}
export function hasSpecial(s: string) {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g.test(s);
}
export function validatePassword(password: string) {
  if (!password) return 'Ce champ est obligatoire';
  if (password.length < 6) return 'Mot de passe doit dépasser 6 caractères';
  if (!hasNumber(password) || !hasUppercase(password) || !hasLowercase(password) || !hasSpecial(password)) {
    return 'Les deux mots de passe ne correspondent pas.';
  }
  return '';
}
export function codeValidation(code: string) {
  return code.length === 6 ? '' : 'Le code doit être composé de 6 caractères';
}

export function validationRadio(values: Array<string>) {
  return (value: string) => {
    if (!value) return 'Vous devez sélectionner un champ';
    if (values.includes(value)) {
      return '';
    } else {
      return 'La valeur n\'existe pas';
    }
  }
}