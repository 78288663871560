import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  titleEchelonVisual: {
    color: theme.palette.success.dark,
    fontSize: 30,
  },
  collapseContainer: {
   // paddingBottom: 95,
  },
}));
