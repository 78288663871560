import { useLocation } from 'react-router-dom';
import { encodeUri, decodeUri } from 'utils/url';

export default function useCreateUri(value: number) {
  const location = useLocation();
  const { url, redirect } = decodeUri(location.search);
  const search: { url: number; redirect?: number } = { url: value };
  if (value === Number(url)) {
    if (redirect !== undefined) search.redirect = Number(redirect) + 1;
    else search.redirect = 0;
  }
  return encodeUri(search);
}
