import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    footerContainer: {
      paddingTop: 25,
      paddingBottom: 25,
      background: theme.palette.grey.A200,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',

      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 180,
      paddingRight: 180,
      [theme.breakpoints.down('md')]: {
        paddingRight: 30,
        paddingLeft: 30,
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: 40,
        paddingLeft: 40,
        flexDirection: 'column',
        fontSize: 12,
        gridGap: '0px 20px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: 20,
        paddingLeft: 20,
      },
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100%',
    },
    title: {
      fontSize: 22,
      fontWeight: 'normal',
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },

    inovationImage: {
      marginTop: 32,
      width: 240,
      [theme.breakpoints.down('md')]: {
        height: 144,
      },
    },
    inovationPriceImage: {
      width: 217,
      marginTop: 50,
      [theme.breakpoints.down('md')]: {
        height: 80,
      },
    },
    inovationImageFr: {
      width: 220,
      marginTop: 30,
      [theme.breakpoints.down('md')]: {
        height: 130,
      },
    },
  }),
);
