import { makeStyles, Theme } from '@material-ui/core/styles';
import { HEADER_HEIGHT as PUBLIC_HEADER_HEIGHT } from '../PublicHeader/styles';
import { HEADER_HEIGHT as PRIVATE_HEADER_HEIGHT } from '../PrivateHeader/styles';

export default makeStyles<Theme, { protectedProp?: boolean; homePage?: boolean }>((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    flex: '1 0 auto',
    justifyContent: 'center',
    paddingTop: (props) => (props.protectedProp ? PRIVATE_HEADER_HEIGHT : PUBLIC_HEADER_HEIGHT),
  },

  layout: {
    overflow: 'auto',
  },
}));
