import React, { forwardRef, Ref } from 'react';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';
import Label from 'components/inputs/Label';
import ErrorText from 'components/inputs/ErrorText';
import classNames from 'utils/classNames';

import './scss/textInputs.scss';

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  label?: string;
  errorText?: string;
  value?: string;
  textInputClassName?: string;
  requiredClassName?: string;
  labelClassName?: string;
}

const TextInput = forwardRef(
  (
    {
      label,
      inputProps,
      className,
      textInputClassName,
      errorText,
      required,
      requiredClassName,
      labelClassName,
      children,
      ...rest
    }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const inputPropsDefault = inputProps || {};
    return (
      <div
        className={classNames(
          'components_inputs_text_input',
          errorText !== undefined && 'components_inputs_input_with_error',
          className,
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {label && (
          <Label className={labelClassName} requiredClassName={requiredClassName} required={required}>
            {label}
          </Label>
        )}
        <TextField
          error={!!errorText}
          className={classNames(
            'text_input_input',
            textInputClassName,
            !!errorText && 'components_inputs_text_input_with_error',
          )}
          inputProps={{
            ...inputPropsDefault,
            ref,
          }}
          autoComplete="off"
          {...rest}
          variant="outlined"
        />

        {errorText !== undefined && <ErrorText>{errorText}</ErrorText>}
      </div>
    );
  },
);

export default TextInput;
