import React, { useState, useRef, useContext } from 'react';
import { useLocation, Link, matchPath } from 'react-router-dom';
import userContext from 'contexts/userContext';
import useOnclickOutside from 'hooks/useOnclickOutside';

import CreateBadgeContainer from 'containers/CreateBadgeContainer/CreateBadge';
import BadgesContainer from 'containers/BadgesContainer/BadgesContainer';

import Menu from 'components/common/Menu/Menu';
import MarketplaceContainer from 'containers/MarketplaceContainer';

import classNames from 'utils/classNames';
import useStyles from './stylesV2';

import useCreateUri from 'hooks/useCreateUri';
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import ProfileContainer from 'containers/ProfileContainer/ProfileContainer';
import NotifPanel from 'containers/NotificationContainer/NotifPanel';

export const mainRoutes = [
  { label: 'CRÉER UN BADGE', path: '/create-badge', component: CreateBadgeContainer },
  { label: 'SUIVRE MES OPEN BADGES', path: '/badges', component: BadgesContainer },

  {
    label: "CATALOGUE",
    path: '/marketplace',
    component: MarketplaceContainer,
    selectedRoutes: ['/badge/:id', '/badge/endossement/:id'],
  },
];

export const mainRoutesMobile = [
  { label: 'CRÉER UN BADGE', path: '/create-badge', component: CreateBadgeContainer },
  { label: 'SUIVRE MES OPEN BADGES', path: '/badges', component: BadgesContainer },

  {
    label: "CATALOGUE",
    path: '/marketplace',
    component: MarketplaceContainer,
    selectedRoutes: ['/badge/:id', '/badge/endossement/:id'],
  },
  { label: 'MON PROFILE', path: '/profile', component: ProfileContainer },
  { label: 'NOTIFICATIONS', path: '/notification', component: NotifPanel },
];

export interface IMainHeader {
    mobile?: () => void;
    showMobile?: boolean;
}

const MainHeaderV = (props: IMainHeader) => {
  const location = useLocation();
  const [openSetting, setOpenSetting] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const toggleSetting = () => setOpenSetting(!openSetting);
  const toggleNotif = () => setOpenNotif(!openNotif);

  const divRefSetting = useRef<HTMLDivElement>(null);
  const divRefNotif = useRef<HTMLDivElement>(null);
  useOnclickOutside(divRefSetting, toggleSetting);
  useOnclickOutside(divRefNotif, toggleNotif);
  const { user } = useContext(userContext);

  const classes = useStyles();
  const isProfile = location.pathname === '/profile';

  const links = [
    {
      label: 'QU’EST CE QUE C’EST ?',
      path: { pathname: '/', search: useCreateUri(0) },
    },
    {
      label: 'OBTENIR DES OPEN BADGES',
      path: { pathname: '/', search: useCreateUri(1) },
    },
    {
      label: 'ÉMETTRE DES OPEN BADGES ',
      path: { pathname: '/', search: useCreateUri(2) },
    },
    /*     { label: 'INSCRIPTION / CONNEXION', path: '/login' },
     */
  ];

  return (
    <>
      <div className={classes.responsiveWeb}>
        {user &&
          mainRoutes.map((route, i) => {
            let selected = location.pathname === route.path;
            if (!selected && route.selectedRoutes) {
              selected = !!route.selectedRoutes.find((r) => matchPath(location.pathname, r));
            }

            return (
              <Link style={{ height: '100%', padding: '0 10px' }} to={route.path}>
                <div key={i} className={classNames(classes.mainContainer, selected && classes.tabRootSelected)}>
                  <div
                    className={classNames(classes.mainRouteContainer, i === 0 && !selected && classes.wrapperButton)}
                  >
                    {route.label}
                  </div>
                </div>
              </Link>
            );
          })}
        {user ? (
          <Link style={{ height: '100%' }} to="/profile">
            <div className={classNames(classes.mainContainer, isProfile && classes.tabRootSelected)}>
              <div className={classNames(classes.mainRouteContainer)}>MON PROFIL</div>
            </div>
          </Link>
        ) : (
          ''
        )}
        {user ? (
          <Menu menuContainerClassName={classes.menuContainer} />
        ) : (
          <div className={classNames(classes.mainContainer)}>
            {links.map((link, i) => (
              <div key={i} className={classNames(classes.mainContainer, isProfile && classes.tabRootSelected)}>
                <div className={classNames(classes.mainRouteContainer)}>
                  <Link to={link.path}>{link.label}</Link>
                </div>
              </div>
            ))}
            <div className={classNames(classes.mainRouteContainer)}>
              <Link
                style={{ color: '#A4FCFC', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                className={classes.link}
                to={'/register'}
              >
                INSCRIPTION
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className={classes.headerMobile}>
        <div onClick={props.mobile} className={classes.iconMobile}>
          {props.showMobile ? (
              <CloseIcon style={{ fontSize: 28 }} />
          ) : (
            <MenuIcon style={{ fontSize: 28 }} />
          )}
        </div>
      </div>
    </>
  );
};

export default MainHeaderV;
