import React, { useEffect, useState } from 'react';

import { useForm } from 'hooks/useInputs';

import BadgeVisualInput from 'components/inputs/BadgeVisualInput/BadgeVisualInput';
import EchelonVisual from 'components/common/EchelonVisual/EchelonVisual';
import FormButton from 'components/buttons/Button';
import Title from 'components/common/Title/Title';
import TabNavigation from 'components/ui/TabNavigation/TabNavigation';
import EchelonRow from 'components/common/EchelonRow/EchelonRow';

import iconEchelon from 'assets/svg/badge-echelons.svg';

import classNames from 'utils/classNames';

import useStyles from './styles';
interface VisuelValue {
  blob: Blob | null;
  modelId: string;
}

export interface VisuelValues extends VisuelValue {
  echelons: VisuelValue[];
}

interface Props {
  className?: string;
  echelons: string[];
  initialValues: Partial<VisuelValues>;
  onSubmit: (values: VisuelValues) => void;
  simple?: boolean;
}

function blobValidation(blob: Blob | null) {
  if (blob) return '';
  return 'Champ vide';
}

function echelonsValidate(echelons: VisuelValue[]) {
  if (echelons.length === 0) return '';
  const errors = echelons.map((value) => blobValidation(value.blob));
  if (!errors.filter((e) => e).length) return '';
  return errors;
}

const CreateBadgeVisual = ({ className, echelons, initialValues, onSubmit, simple }: Props) => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [valueSrc, setValueSrc] = useState('');
  const [showEchelon, setShowEchelon] = useState(false);

  const [state, actions] = useForm({
    initialValues: {
      blob: null as Blob | null,
      modelId: '',
      echelons: echelons.map<VisuelValue>(() => ({ blob: null, modelId: '' })),
      ...initialValues,
    },
    validation: {
      blob: blobValidation,
      echelons: (echelonsVisuels, values) => echelonsValidate(echelonsVisuels),
    },
  });

  const [submitTouched, setSubmitTouched] = useState(false);

  useEffect(() => {
    actions.setValues({
      echelons: echelons.map((e, index) => state.values.echelons[index] || { blob: null, modelId: '' }),
    });
    // eslint-disable-next-line
  }, [echelons]);

  useEffect(() => {
    if (Object.keys(initialValues).length) {
      actions.setValues(initialValues);
    }
    // eslint-disable-next-line
  }, [initialValues]);
  useEffect(() => {
    if (valueSrc) setStep(1);
    // eslint-disable-next-line
  }, [valueSrc]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [step]);

  function handleSubmit(extendVisuel: boolean) {
    if (step === 0 && valueSrc && !simple) setStep(1);
    else if (actions.validateForm()) {
      const values = { blob: state.values.blob, modelId: state.values.modelId, echelons: state.values.echelons };

      onSubmit(values);
    } else if (extendVisuel && !state.errors.blob) {
      const values = { blob: state.values.blob, modelId: state.values.modelId, echelons: state.values.echelons };
      if (extendVisuel) {
        values.echelons = state.values.echelons.map((visual) =>
          visual.blob ? visual : { blob: state.values.blob, modelId: state.values.modelId },
        );
      }

      onSubmit(values);
    } else {
      actions.setAllTouched(true);
      setSubmitTouched(true);
    }
  }

  function renderEchelon(echelonValues: VisuelValue, index: number) {
    const echelon = echelons[index];
    function onEchelonChange(blob: Blob | null, modelId: string) {
      const nextEchelons = [...state.values.echelons];
      nextEchelons[index] = { blob, modelId };
      actions.setValues({ echelons: nextEchelons });
    }
    return (
      <EchelonVisual
        echelon={echelon}
        index={index}
        value={echelonValues.blob}
        modelId={echelonValues.modelId}
        onChange={onEchelonChange}
        className="mother_badge_input"
        errorText={submitTouched && state.errors.echelons[index]}
      />
    );
  }
  const BadgeEchelon = (
    <div className={classes.badgeEchelonContainer}>
      <img alt="" src={iconEchelon} />
      <span className={classes.badgeEchelonText}>Visuel des échelons</span>
    </div>
  );
  const VisualEchelon = (
    <div className={classes.badgeEchelonContainer}>
      <span className={classes.badgeEchelonText}>Visuel</span>
      <span style={{ textTransform: 'lowercase', marginLeft: '5px' }}> de l'</span>
      <span>Open Badge mère</span>
    </div>
  );

  const simpleBadge = (
    <>
      {!simple ? null : <Title title="Visuel de l'Open Badge" className={classes.titleBadgeVisual} />}

      <h3 className={classes.title}>Créez votre Open Badge de toutes pièces ou importez directement votre visuel.</h3>
      <div className={classNames(classes.createBadgeContainer)}>
        <BadgeVisualInput
          modelId={state.values.modelId}
          value={state.values.blob}
          onChange={(blob, modelId) => actions.setValues({ blob, modelId })}
          errorText={state.touched.blob && state.errors.blob}
          setValueSrc={setValueSrc}
          valueSrc={valueSrc}
        />
      </div>
    </>
  );

  const renderEchelonVisual = () => {
    if (echelons.length) {
      return <div className={classes.echelonInput}>{state.values.echelons.map(renderEchelon)}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className={classNames(classes.createBadgeVisualComponent, className)}>
      {simple ? (
        simpleBadge
      ) : (
        <TabNavigation
          isCreate
          fixed={false}
          value={step}
          className={classes.tabNavigation}
          selectedTabClassName={classes.selectedTabClassName}
          keepMounted
          onChange={(e, nextStep) => setStep(nextStep)}
          tabs={[
            {
              name: VisualEchelon,
              element: simpleBadge,
            },
            {
              name: BadgeEchelon,
              disabled: !valueSrc,
              element: (
                <div className={classes.echelonInputContainer}>
                  {showEchelon ? (
                    renderEchelonVisual()
                  ) : (
                    <>
                      <EchelonRow
                        echelonSrc={valueSrc}
                        echelonText="Vous pouvez utiliser le visuel de l'Open Badge mère pour tous les échelons :"
                        onClick={() => {
                          handleSubmit(true);
                        }}
                        buttonClassName={classes.useBadgeVisualButton}
                        imageClassName={classes.imageClassName}
                        children="Utiliser ce visuel"
                        className={classes.echelonClassName}
                      />
                      <EchelonRow
                        echelonSrc={iconEchelon}
                        echelonText="Ou bien créer des visuels personnalisés pour chaque échelon :"
                        onClick={() => {
                          setShowEchelon(true);
                        }}
                        buttonClassName={classes.createBadgeVisualButton}
                        size={92}
                        children="Créer des visuels pour chaque échelon"
                        className={classes.echelonClassName}
                      />
                    </>
                  )}
                </div>
              ),
            },
          ]}
        />
      )}

      {showEchelon || simple ? (
        <FormButton size="large" max onClick={() => handleSubmit(false)}>
          Continuer
        </FormButton>
      ) : null}
    </div>
  );
};

export default CreateBadgeVisual;
