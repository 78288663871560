import { useRef } from 'react';

export default function useKeys(values: any[]): [number[], () => void, (index: number) => void] {
  const keys = useRef(values.map((e, i) => i));
  function addKeys() {
    keys.current.push(keys.current[keys.current.length - 1] + 1 || 0);
  }
  function deleteKeys(index: number) {
    keys.current = keys.current.filter((e, i) => i !== index);
  }

  return [keys.current, addKeys, deleteKeys];
}
