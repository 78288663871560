import React from 'react';
import Title from '../Title/Title';
import classNames from 'utils/classNames';
import badgeEchelon from 'assets/svg/badge-echelons-visual.svg';

import useStyles from './styles';
interface Props {
  echelon?: string;
  index?: number;
  rightComponent?: React.ReactNode;
  echelonHeaderClassName?: string;
}

const EchelonHeader = ({ echelon, index, rightComponent, echelonHeaderClassName }: Props) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.echelonHeaderContainer, echelonHeaderClassName)}>
      <div className={classes.echelonHeaderRoot}>
        <img alt="" src={badgeEchelon} />
        <Title className={classes.titleEchelonVisual} title={`Échelon ${index}`} />
        <span className={classes.textEchelonVisual}> {echelon}</span>
      </div>
      <div className={classes.badgeEchelonValueSrc}>{rightComponent}</div>
    </div>
  );
};

export default EchelonHeader;
