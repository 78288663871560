import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme, { color?: string; checked?: boolean; border?: string; error?: string }>((theme) => ({
  container: {
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    borderRadius: 5,
    width: '100%',
    marginBottom: 20,
    '& input': {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      height: 0,
      width: 0,
    },

    '& $checkmark': {
      '&:after': {
        left: '6px',
        top: '2px',
        width: '5px',
        height: '10px',
        transform: 'rotate(45deg)',
      },
    },
  },

  checkmark: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 19,
    height: 20,
    backgroundColor: (props) => (props.checked ? props.color : '#fff'),
    borderRadius: 5,
    border: (props) => {
      let border = '1px solid #E0E0E0';
      if (props.error) border = '1px solid #D42061';
      else if (props.border) border = `1px solid ${props.border}`;
      return border;
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
    },
  },
  icon: {
    position: 'absolute',
    top: -4,
    left: 2,
    width: 23,
    height: 21,
  },
  label: {
    marginLeft: 35,
    display: 'flex',
    width: '100%',
  },
  requiredText: {
    color: theme.palette.info.main,
  },
  errorContainer: {
    width: '100%',
    paddingTop: 15,
    height: 50,
    whiteSpace: 'nowrap',
  },
  errorMessage: {
    color: theme.palette.info.main,
    width: '100%',
  },
}));
