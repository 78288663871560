import React, { useContext, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import localforage from 'localforage';
import { setAuthorizationBearer } from 'requests/http';

import Menu, { MenuProps } from '@material-ui/core/Menu';
import logout from 'assets/svg/logout.svg';
import userContext from 'contexts/userContext';
import useOnclickOutside from 'hooks/useOnclickOutside';
import NameFormator from 'utils/NameFormator';
import notifContext from 'contexts/notifContext';

import defaultAvatar from 'assets/svg/defaultUser_v3.svg';

import classNames from 'utils/classNames';

import useStyles from './styles';

const StyledMenu = withStyles((theme) => ({
  paper: {
    marginTop: 16,
    marginLeft: 24,
    background: theme.palette.secondary.main,
    border: '0.5px solid #828282',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
  },
}))((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

interface Props {
  menuContainerClassName?: string;
}
export default function CustomizedMenus({ menuContainerClassName }: Props) {
  const classes = useStyles();
  const history = useHistory();

  const [openSetting, setOpenSetting] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const { newNotif } = useContext(notifContext);

  const toggleSetting = () => setOpenSetting(!openSetting);
  const toggleNotif = () => setOpenNotif(!openNotif);

  const divRefSetting = useRef<HTMLDivElement>(null);
  const divRefNotif = useRef<HTMLDivElement>(null);
  useOnclickOutside(divRefSetting, toggleSetting);
  useOnclickOutside(divRefNotif, toggleNotif);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user, setUser } = useContext(userContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classNames(classes.MenuRoot, menuContainerClassName)}>
      <div onClick={handleClick} className={classes.headerFirstName}>
        {user?.fullName.charAt(0).toUpperCase()}
        {anchorEl && <div className={classes.backgroundMenu} />}
      </div>
      {newNotif.length !== 0 && <div className={classes.badgeNotif}>{newNotif.length}</div>}

      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div className={classes.userNameContainer}>
          <img src={user?.image ?? defaultAvatar} alt="" height={60} width={60} />
          {user && (
            <span className={classes.userName}>
              {NameFormator(user.fullName)}
            </span>
          )}
        </div>
        <div className={classes.menuProfil}>
          <Link to="/profile">Mon profil</Link>
          <Link to="/notification">
            <div className={classes.notifPanel}>              Notifications {newNotif.length !== 0 && <div className={classes.badgeNotifs}>{newNotif.length}</div>}
            </div>
          </Link>
          <div
            onClick={() => {
              localforage.clear();
              setAuthorizationBearer(null);
              setUser(null);
              toggleSetting();
              history.push('/');
            }}
            className={classes.logout}
          >
            <img src={logout} alt="" />
            <div className={classes.logoutText}>Déconnexion</div>
          </div>
        </div>
      </StyledMenu>
    </div>
  );
}
