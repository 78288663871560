import React, {
 useState, useRef, MutableRefObject, useEffect, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';

import TabNavigation from 'components/ui/TabNavigation/TabNavigation';
import CreateBadgeType from 'components/forms/CreateBadgeType/CreateBadgeType';
import CreateBadgeFeatures, { FeatureValues } from 'components/forms/CreateBadgeFeatures/CreateBadgeFeatures';
import CreateBadgeVisual, { VisuelValues } from 'components/forms/CreateBadgeVisual/CreateBadgeVisual';
import CreateBadgeCriteria, { CriteriaFormValues } from 'components/forms/CreateBadgeCriteria/CreateBadgeCriteria';
import Title from 'components/common/Title/Title';
import classNames from 'utils/classNames';
import useApiState from 'hooks/useApiState';

import { createBadge, createBadgeMulti } from 'requests/badges';

import useStyles from './styles';

const CreateBadge = () => {
  const [step, setStep] = useState(0);

  const typeRef = useRef<boolean | null>(null);
  const featuresRef = useRef<FeatureValues | null>(null);
  const visualRef = useRef<VisuelValues | null>(null);
  const criteriaRef = useRef<CriteriaFormValues | null>(null);

  const classes = useStyles();

  const [state, call] = useApiState(typeRef.current ? createBadge : createBadgeMulti);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const echelon = useMemo(
    () => (featuresRef.current && !typeRef.current ? featuresRef.current.echelons.map(({ title }) => title) : []),
    // eslint-disable-next-line
    [featuresRef.current,!typeRef.current],
  );


  useEffect(() => {
    window.scroll({ top: 0 });
  }, [step]);

  function onStepSubmit<T>(ref: MutableRefObject<T>, s: number) {
    return function (values: T) {
      // eslint-disable-next-line
      ref.current = values;
      setStep(s);
    };
  }

  function onSubmit(values: CriteriaFormValues) {
    criteriaRef.current = values;
    const features = featuresRef.current;
    const visual = visualRef.current;
    const simple = typeRef.current;
    if (features && visual) {
      const parent = {
        tags: features.tags,
        title: features.title,
        mainCategory: features.mainCategory,
        secondaryCategory: features.secondaryCategory,
        description: features.description,
        public: features.public,
        model: visual.blob as Blob,
        criteriaSummary: values.criteriaSummary,
        releaseStartDate: values.releaseStartDate,
        releaseEndDate: values.releaseEndDate,
        releaseCode: values.releaseAuto ? values.releaseCode : undefined,
        expectedCriteria: values.expectedCriteria,
      };
      if (!simple) {
        const children = features.echelons.map((echelon, index) => ({
          tags: echelon.tags,
          title: echelon.title,
          mainCategory: features.mainCategory,
          secondaryCategory: features.secondaryCategory,
          description: echelon.description,
          public: features.public,
          model: visual.echelons[index].blob as Blob,
          criteriaSummary: values.criteriaSummary,
          releaseStartDate: values.releaseStartDate,
          releaseEndDate: values.releaseEndDate,
          releaseCode: values.releaseAuto ? values.releaseCode : undefined,
          expectedCriteria: values.extendCriteria
            ? [...values.expectedCriteria, ...values.echelonCriteria[index]]
            : values.echelonCriteria[index],
        }));

        call({
          parent,
          children,
        });
      } else {
        call(parent);
      }
    }
  }

  useEffect(() => {
    if (state.data) setOpenModal(true);
  }, [state.data]);

  const onCloseModal = () => {
    setOpenModal(false);
    history.push('/profile');
  };

  const circleNumber = (number: number, text: string) => (
    <div className={classes.numberCircleContainer}>
      <div className={classNames(classes.numberCircle, step === number - 1 && classes.disabledCircle)}>{number}</div>
      <span>{text}</span>
    </div>
    );
  return (
    <div>
      <div className={classes.imageContainer}>
        <div className={classes.imageBackground} />
        {' '}
        {/* background  */}
        <Title title="Nouvel Open Badge" color="#ffff" className={classes.titleContainer} />
      </div>
      <TabNavigation
        value={step}
        keepMounted
        onChange={(e, value) => setStep(value)}
        tabs={[
          {
            name: circleNumber(1, "TYPE DE L'OPEN BADGE"),
            element: <CreateBadgeType onSubmit={onStepSubmit(typeRef, 1)} className="create-step" />,
          },
          {
            name: circleNumber(2, 'CARACTÉRISTIQUES'),
            element: (
              <CreateBadgeFeatures
                className="create-step"
                onSubmit={onStepSubmit(featuresRef, 2)}
                simple={!!typeRef.current}
                initialValues={featuresRef.current || {}}
              />
            ),
            disabled: typeRef.current === null,
          },
          {
            name: circleNumber(3, "VISUEL DE L'OPEN BADGE"),
            element: (
              <CreateBadgeVisual
                echelons={
                  featuresRef.current && !typeRef.current ? featuresRef.current.echelons.map(({ title }) => title) : []
                }
                className="create-step"
                initialValues={visualRef.current || {}}
                onSubmit={onStepSubmit(visualRef, 3)}
                simple={!!typeRef.current}
              />
            ),
            disabled: !featuresRef.current,
          },
          {
            name: circleNumber(4, 'CRITÈRES DE DÉLIVRANCE'),
            element: (
              <CreateBadgeCriteria
                simple={!!typeRef.current}
                echelons={echelon}
                initialValues={criteriaRef.current || {}}
                onSubmit={onSubmit}
                openModal={openModal}
                onCloseModal={onCloseModal}
                fetching={state.fetching}
              />
            ),
            disabled: !visualRef.current,
          },
        ]}
      />
    </div>
  );
};

export default CreateBadge;
