import React, { useEffect, useState } from 'react';

import Icon from 'components/ui/Icon';
import classNames from 'utils/classNames';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './scss/createBadgeIconsCarousel.scss';

interface Props {
  icons: string[];
  selectedIcon: string;
  onIconChange: (d: string) => void;
  className?: string;
}

const CreateBadgeIconsCarousel = ({
 icons, selectedIcon, onIconChange, className,
}: Props) => {
  const [slideIcons, setSlideIcons] = useState<string[][]>([]);

  useEffect(() => {
    const formatedIcons: string[][] = [];

    icons.forEach((icon) => {
      const last = formatedIcons[formatedIcons.length - 1];
      if (last && last.length < 6) {
        last.push(icon);
      } else {
        formatedIcons.push([icon]);
      }
    });

    setSlideIcons(formatedIcons);
  }, [icons]);

  if (slideIcons.length === 0) return null;

  return (
    <div className={classNames('components_badge_create_badge_icons_carousel width_100', className)}>
      {icons.map((icon) => (
        <Icon
          className="badge_model_icons"
          selected={icon === selectedIcon}
          key={icon}
          onClick={() => onIconChange(icon)}
          d={icon}
        />
      ))}
    </div>
  );
};

export default CreateBadgeIconsCarousel;
