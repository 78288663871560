import { User } from './auth';
import { axiosGet, Response, axiosPostFilesData, axiosPost, SuccessResponse, ErrorResponse } from './http';

import { Category } from './categories';

interface FilterBadge {
  categorie?: string;
  tags?: string;
  name?: string;
  currentPage?: number;
  perPage?: number;
  others?: string;
}
export interface Question {
  options: string[];
  answer: string[];
  _id: string;
  question: string;
  type: 'text' | 'select' | 'multiple';
}

export interface Criteria {
  _id: string;
  title: string;
  type: 'pdf' | 'image' | 'text' | 'questionnaire';
  description: string;
  questions: Question[];
}

export interface Badge {
  _id: string;
  title: string;
  description: string;
  mainCategory: Category;
  secondaryCategory?: Category;
  tags: string[];
  isPublic: boolean;
  resumeCriterion: string;
  startDate: string;
  endDate: string;
  criterion: Criteria[];
  deliveranceAuto: boolean;
  deliveranceCode: string;
  issuerId: User;
  image: string;
  createdAt: string;
  candidaturesCount: number;
  endo: {
    _id: string;
    idEndosseur: User;
    typeEndo: string;
    idBadge: string;
    text: string;
    status: string;
    email: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  }[];
  awardId: string;
  childBadges: Badge[];
  parentId: Badge;
  isOwnedByUser?: boolean;
}
export const badges = (params?: FilterBadge): Promise<Response<Badge[]>> => axiosGet('/badge', { params });

export const uploadModel = (data: Blob): Promise<Response<string>> => {
  const form = new FormData();
  form.append('file', new File([data], 'filename', { type: data.type }));
  return axiosPostFilesData('/badge/upload', { data: form });
};

interface CriteriaParams {
  title: string;
  type: string;
  description: string;
}

export const addCriteria = (data: CriteriaParams): Promise<Response<Criteria>> => axiosPost('/critere', { data });

interface AddBadgeData {
  title: string;
  description: string;
  mainCategory: string;
  secondaryCategory?: string;
  isPublic: boolean;
  resumeCriterion: string;
  startDate: string;
  endDate: string;
  deliveranceCode?: string;
  image: string;
  criterion: string[];
  tags: string[];
}

export const addBadge = (data: AddBadgeData): Promise<Response<Badge>> => axiosPost('/badge', { data });

export const addBadgeMulti = (data: { data: { parent: AddBadgeData; child: AddBadgeData[] } }) =>
  axiosPost('/badge/multi', { data });

interface CreateBadgeData {
  tags: string[];
  title: string;
  mainCategory: string;
  secondaryCategory?: string;
  description: string;
  public: boolean;
  model: Blob;
  criteriaSummary: string;
  releaseStartDate: string;
  releaseEndDate: string;
  releaseCode?: string;
  expectedCriteria: { title: string; type: string; description: string }[];
}

export const createBadge = async (data: CreateBadgeData): Promise<Response<Badge>> => {
  const uploadResponse = await uploadModel(data.model);

  if (uploadResponse.status !== 'OK') {
    return uploadResponse;
  }

  const expectedCriteriaResponse = await Promise.all(data.expectedCriteria.map((criteria) => addCriteria(criteria)));

  const criteriaError = expectedCriteriaResponse.find((criteriaResponse) => criteriaResponse.status !== 'OK');

  if (criteriaError) return criteriaError as ErrorResponse;

  return addBadge({
    title: data.title,
    description: data.description,
    mainCategory: data.mainCategory,
    secondaryCategory: data.secondaryCategory || undefined,
    isPublic: data.public,
    resumeCriterion: data.criteriaSummary,
    startDate: data.releaseStartDate,
    endDate: data.releaseEndDate,
    deliveranceCode: data.releaseCode,
    image: uploadResponse.data,
    criterion: expectedCriteriaResponse.map((response) => (response as SuccessResponse<Criteria>).data._id),
    tags: data.tags,
  });
};

export const createBadgeMulti = async (data: { parent: CreateBadgeData; children: CreateBadgeData[] }) => {
  const filesModels = [data.parent.model, ...data.children.map((child) => child.model)];
  const uploadResponses = await Promise.all(filesModels.map((model) => uploadModel(model)));
  const uploadError = uploadResponses.find((response) => response.status !== 'OK');
  if (uploadError) return uploadError as ErrorResponse;
  const badgeCriterion = [data.parent.expectedCriteria, ...data.children.map((child) => child.expectedCriteria)];
  const criterionResponse = await Promise.all(
    badgeCriterion.map((criterion) => Promise.all(criterion.map((criteria) => addCriteria(criteria)))),
  );
  let criteriaError = null;
  criterionResponse.find((criterion) => {
    const criteria = criterion.find((response) => response.status !== 'OK');
    if (criteria) criteriaError = criteria;
    return criteria;
  });

  if (criteriaError) return criteriaError as ErrorResponse;

  const children = data.children.map((child, i) => ({
    title: child.title,
    description: child.description,
    mainCategory: child.mainCategory,
    secondaryCategory: child.secondaryCategory || undefined,
    isPublic: child.public,
    resumeCriterion: child.criteriaSummary,
    startDate: child.releaseStartDate,
    endDate: child.releaseEndDate,
    deliveranceCode: child.releaseCode,
    image: (uploadResponses[i + 1] as SuccessResponse<string>).data,
    criterion: criterionResponse[i + 1].map((response) => (response as SuccessResponse<Criteria>).data._id),
    tags: child.tags,
  }));

  const parent = {
    title: data.parent.title,
    description: data.parent.description,
    mainCategory: data.parent.mainCategory,
    secondaryCategory: data.parent.secondaryCategory || undefined,
    isPublic: data.parent.public,
    resumeCriterion: data.parent.criteriaSummary,
    startDate: data.parent.releaseStartDate,
    endDate: data.parent.releaseEndDate,
    deliveranceCode: data.parent.releaseCode,
    image: (uploadResponses[0] as SuccessResponse<string>).data,
    criterion: criterionResponse[0].map((response) => (response as SuccessResponse<Criteria>).data._id),
    tags: data.parent.tags,
  };
  const result = await addBadgeMulti({ data: { parent, child: children } });
  return { ...result, data: 'success' };
};

export interface DonatedBadge extends Badge {
  status: string;
  deliveranceCode: string;

}
export interface AwardBadgeParams {
  recepientName: string;
  recepientEmail: string;
  idBadge: string;
}

export const getBadge = (id: string): Promise<Response<DonatedBadge>> => axiosGet(`/badge/${id}`);

export const getUserBadges = (params?: FilterBadge): Promise<Response<Badge[]>> => axiosGet('/badge/user', { params });

interface awardResponseType {
  _id: string;
  recepientName: string;
  recepientEmail: string;
  linkJson: string;
  linkImg: string;
  createdAt: string;
}

export const awardBadge = (data: AwardBadgeParams): Promise<Response<awardResponseType>> =>
  axiosPost('/awardBadge', { data });
interface Details {
  totalBadge: string;
  competenceBadge: string;
  evenementBadge: string;
  communauteBadge: string;
  profiles: string;
}
export const getDetailed = (): Promise<Response<Details>> => axiosGet('/badge/all');

export const getBadgeChilds = (id: string): Promise<Response<any>> => axiosGet(`badge/parent/${id}`);

export const getIssuerBadges = (id: string): Promise<Response<any>> => axiosGet(`/badge/issuer/${id}`);