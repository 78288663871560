import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  HomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '55px 150px 90px 150px',
    paddingTop: 5,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
  },
  textInfo: {
    marginTop: 98,
    fontSize: 20,
    marginBottom: 65,
    background: theme.palette.secondary.main,
    padding: '45px 100px 29px 100px',
    lineHeight: '160%',
    textAlign: 'center',
  },
  titleInfo: {
    display: 'flex',
    alignSelf: 'center',
  },
  infoImage: {
    display: 'flex',
  },

  infoImageBadge: {
    width: '100%',
  },

  getBadgeContainer: {
    background: theme.palette.secondary.main,
    padding: '75px 180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '50px',
    },
  },
  createBadgeContainer: {
    padding: '80px 180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '50px',
    },
  },
  getBadgeText: {
    fontWeight: 'bold',
    fontSize: 20,
    color: theme.palette.grey.A400,
    margin: 0,
    marginBottom: 30,
  },
  getBadgeImageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 60,
    marginTop: 40,
  },

  getBadgeImageMediaContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  getBadgeContainerWidth: {
    width: 700,
  },

  getBadgeImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  createBadgeTitle: {
    color: theme.palette.error.main,
    margin: '43px 0px',
  },
  labelImage: {
    width: 200,
    marginTop: 30,
    textAlign: 'center',
  },
  imageGetBagde: {
    height: 200,
    [theme.breakpoints.down('md')]: {
      height: 150,
    },
  },
  createBadge: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  createBadgeParagraph: {
    width: 830,
    fontSize: 20,
    textAlign: 'center',
  },
  helpText: {
    color: theme.palette.error.main,
    marginTop: 20,
    fontWeight: 'bold',
  },
  searchBadgeText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  getBadgeTextColor: {
    color: theme.palette.primary.main,
  },
  badgeTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridRow: '1/1',
  },
  titleBadgeType: {
    fontFamily: 'Rakkas',
    fontWeight: 'normal',
    fontSize: 35,
  },
  badgeTypeRoot: {
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 300px) 1fr minmax(auto, 300px)',
    gridTemplateRows: '1fr 81px 1fr',
    gridGap: 0,
    position: 'relative',
    padding: '0px 40px',
  },
  schemaImageContainer: {
    gridColumn: '2/2',
    gridRow: '1/4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  personel: {
    gridColumn: '1/1',
  },
  verifie: {
    gridColumn: '3/3',
  },
  gratuit: {
    gridRow: '3/3',
    gridColumn: '1/1',
  },
  diffusable: {
    gridRow: '3/3',
    gridColumn: '3/3',
  },
  description: {
    textAlign: 'center',
    fontSize: 16,
    marginTop: 5,
  },
  schemaImage: {
    [theme.breakpoints.down('sm')]: {
      height: 180,
      width: 350,
    },
  },
  textPersonel: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  textVerifie: {
    color: theme.palette.success.dark,
    fontWeight: 'bold',
  },
  textGratuit: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  textDiffusable: {
    color: theme.palette.grey.A400,
    fontWeight: 'bold',
  },
}));
