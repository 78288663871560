import React, { FunctionComponent } from 'react';
import AddLogo from '../../assets/svg/defaultUser_v3.svg';
import { User } from '../../requests/auth';
import useStyles from './style';
import pictoPdf from '../../assets/svg/pdf.svg';
import { Critere } from '../../requests/candidature';

interface IProps {
  user: User;
  evidence?: Critere;
  onChangeImgUrl?: (url: string) => void;
}

const EvidenceCard: FunctionComponent<IProps> = ({ user, evidence, onChangeImgUrl }) => {
  const classes = useStyles();

  if (!user || !evidence) return null;

  return (
    <div>
      <div>
        <div className={classes.candidatureItem}>
          {
            <div className={classes.imageUserCandidateur}>
              <img alt="Avatar" src={user.image || AddLogo} className={classes.img} />
            </div>
          }
          <div style={{ flex: 1 }}>
            <p className={classes.tagTitle}>
              Preuve apportée par {user.firstName} {user.lastName}
            </p>
            <div className={classes.text_candidature}>
              {evidence.type === 'text' && <p className={classes.descriptionText}>{evidence.description}</p>}
              {evidence.type === 'image' && <img src={evidence.file} alt="Preuve" className={classes.previewImg} onClick={() => onChangeImgUrl?.call(null, evidence.file)} />}
              {evidence.type === 'pdf' && (
                <a href={evidence.file} target="_blank" rel="noopener noreferrer" className={classes.linkPdf}>
                  <img
                    style={{ verticalAlign: 'middle', marginRight: '0.3em' }}
                    src={pictoPdf}
                    alt={'Voir le fichier PDF'}
                  />
                  <span style={{ verticalAlign: 'middle', marginLeft: '0.3em' }}>Voir le fichier PDF</span>
                </a>
              )}
              {evidence.type === 'questionnaire' && (
                <div>
                  {evidence.questions.map((qs: any, index: any) => (
                    <div>
                      <p>{`Question ${index + 1}: ${qs.question}`}</p>
                      <p>
                        Réponse:{' '}
                        {typeof qs.answer === 'string'
                          ? qs.answer
                          : qs.answer.map((r: any, i: any) => (
                              <span>
                                {r}
                                {`${qs.answer.length === i + 1 ? '' : ' - '}`}
                              </span>
                            ))}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvidenceCard;
