import React, { useState, useRef, useEffect } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import classNames from 'utils/classNames';

import useStyles from './styles';

interface NavigationItems {
  element: React.ReactChild | null;
  name: any;
  disabled?: boolean;
}

interface Props {
  tabs: NavigationItems[];
  value?: number;
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
  keepMounted?: boolean;
  fixed?: boolean;
  isCreate?: boolean;
  className?: string;
  selectedTabClassName?: string;
}

const TabNavigation = ({
  tabs,
  value,
  onChange,
  keepMounted,
  fixed: fixedProp,
  isCreate,
  className,
  selectedTabClassName,
}: Props) => {
  const [localValue, setLocalValue] = useState(0);
  const [fixed, setFixed] = useState(false);

  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const topRef = useRef(0);

  function handleChange(event: React.ChangeEvent<{}>, nextValue: number) {
    setLocalValue(nextValue);
  }

  const selected = value ?? localValue;
  const handleScroll = () => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();
      if (top <= 91 && !fixed) setFixed(true);
      else if (window.scrollY < topRef.current && fixed) setFixed(false);
    }
  };
  useEffect(() => {
    if (ref.current) {
      topRef.current = ref.current.getBoundingClientRect().top - 91;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, [fixed]);

  return (
    <>
      <Tabs
        classes={{
          flexContainer: classes.flexContainer,
          indicator: classes.indicator,
          root: classNames(
            classes.relativeTab,
            fixed && fixedProp && classes.fixedTab,
            isCreate && classes.isCreatingTab,
          ),
        }}
        value={selected}
        onChange={onChange ?? handleChange}
        innerRef={ref}
      >
        {tabs.map((item, i) => (
          <Tab
            disabled={item.disabled}
            key={i}
            classes={{
              root: classNames(
                classes.tabRoot,
                className,
                selected === i && classes.selectedTab,
                selected === i && selectedTabClassName,
              ),
            }}
            label={<div className={classes.headerItems}>{item.name}</div>}
          />
        ))}
      </Tabs>
      {fixed && fixedProp && (
        <div style={{ height: ref.current?.getBoundingClientRect().height }} className={classes.placeHolder}></div>
      )}{' '}
      {keepMounted
        ? tabs.map(({ name, element }, index) => (
            <div
              key={index}
              className={classNames(classes.tabNavigationContainer, selected !== index && classes.tabNavigationHide)}
            >
              {element}
            </div>
          ))
        : tabs[selected]?.element}
    </>
  );
};
TabNavigation.defaultProps = {
  fixed: true,
};
export default TabNavigation;
