import React from 'react';
import Chart from 'react-apexcharts';

interface Props {
  data: number;
}

const PieChart = ({ data }: Props) => {
  const c = data;
  const r = 100 - data;
  const options = {
    options: {
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ['#FF5C97', '#FFD1E2'],
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
    },

    series: [c, r],
  };
  return <Chart options={options.options} series={options.series} type="donut" width="150" />;
};

export default PieChart;
