import React from 'react';
import Modal from '@material-ui/core/Modal';
import picto from 'assets/svg/picto-poubelle.svg';
import close from 'assets/svg/picto-close.svg';

import classNames from 'utils/classNames';

import useStyles from './styles';

interface IProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactElement | React.ReactChild[];
  iconClassName?: string;
  validateHeaderClassName?: string;
  validation?: boolean;
  isClosed?: boolean;
  modalClassNames?: string;
}

const ModalContainer = ({
  open,
  handleClose,
  children,
  iconClassName,
  validateHeaderClassName,
  validation,
  isClosed,
  modalClassNames,
}: IProps) => {
  const classes = useStyles();
  return (
    <Modal
      BackdropProps={{ className: classes.backdrop }}
      open={open}
      onClose={handleClose}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
    >
      <div onClick={handleClose} className={classes.modalContainer}>
        <div onClick={(e) => e.stopPropagation()} className={classNames(classes.modal, modalClassNames)}>
          {!validation && <img src={close} alt="" onClick={handleClose} className={classes.close} />}

          {!validation && isClosed && (
            <img src={picto} alt="" onClick={handleClose} className={classNames(classes.picto, iconClassName)} />
          )}
          <div className={classNames(classes.header, validateHeaderClassName)}>{children}</div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalContainer;

ModalContainer.defaultProps = {
  isClosed: true,
};
