import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/ui/Text';
import MinCard from 'components/cards/CardBadge/MiniCard';
import { Badge } from 'requests/badges';
import logo from 'assets/svg/addBadge_.svg';
import useStyles from './style';

interface Props {
  label: string;
  data?: Badge[];
  targetPath: string;
  type?: string;
  notLimit?: boolean;
  withoutDisplay?: boolean;
}

const BadgeContainer = ({ label, data, targetPath, type, notLimit, withoutDisplay }: Props) => {
  const classes = useStyles();
  let dataTOShow: Badge[] = [];
  if (data && !notLimit) {
    dataTOShow = data?.slice(0, 3);
  } else {
    dataTOShow = data || []
  }
  return (
    <div className={classes.badge_component_container}>
      <Text label={label} className={classes.sub_title_component} />
      <div className={classes.badge_card_container}>
        {dataTOShow?.map((el: Badge) => (
          <div key={el._id} className={classes.badge_card_content}>
            <Link to={{ pathname: `/badge/${el._id}`, state: { badge: el } }}>
              <MinCard data={el} />
            </Link>
          </div>
        ))}
        <Link to={targetPath}>
          <div className={classes.add_badgeContainer}>
            <div className={classes.add_badge}>
              <img className={classes.add_img} src={logo} alt="add" />
            </div>
            <p className={classes.text_add}>Ajouter un</p>
            <p className={classes.text_add}>Open Badge</p>
          </div>
        </Link>
      </div>
      {data && data.length > 0 && !withoutDisplay && (
        <div className={classes.linkToBadges}>
          <span className={classes.textLink}>
            {/* <Link to={`/badges/${type}`}>Afficher tous mes Open Badges</Link> */}
            <Link to={`/badges?=${type}`}>Afficher tous mes Open Badges</Link>
          </span>
        </div>
      )}
    </div>
  );
};
export default BadgeContainer;
