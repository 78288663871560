import React, { useRef, useEffect, useState, useContext } from 'react';
import { useLocation, Link, matchPath, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import classNames from 'utils/classNames';

import { setAuthorizationBearer } from 'requests/http';

import diva from 'assets/svg/divapublic.svg';
import useStyles from './styles';
import CreateBadgeContainer from 'containers/CreateBadgeContainer';
import BadgesContainer from 'containers/BadgesContainer/BadgesContainer';
import MarketplaceContainer from 'containers/MarketplaceContainer/Marketplace';

import userContext from 'contexts/userContext';
import ProfileContainer from 'containers/ProfileContainer';
import NotifPanel from 'containers/NotificationContainer/NotifPanel';
import localforage from 'localforage';
import useCreateUri from 'hooks/useCreateUri';
import { mainRoutes } from 'layout/MainHeader/MainHeaderV2';
import MobileHeader from 'layout/MobileHeader/MobileHeader';

interface Props {
  children?: React.ReactChild;
  homePage?: boolean;
  mobile?: boolean;
}

const PrivateHeader = ({ children, homePage, mobile }: Props) => {
  const redirectRef = useRef(0);
  const classes = useStyles({ homePage });
  const location = useLocation();

  const { user, setUser } = useContext(userContext);
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/') redirectRef.current = redirectRef.current + 1;
  }, [location]);

  return (
    <>
      <AppBar position={homePage ? 'static' : 'fixed'} className={classNames(classes.appBar, classes.appBarHomePage)}>
        <Toolbar className={classNames(classes.toolbarContainer, homePage && classes.toolbarHomePage)}>
          <div className={classes.headerContainer}>
            <Link className={classes.headerImageContainer} to="/">
              <img src={diva} alt="" />
            </Link>
            <div className={classes.linksContainer}>{children}</div>
          </div>
        </Toolbar>
      </AppBar>
      <MobileHeader mobile={mobile} />
    </>
  );
};

export default PrivateHeader;
