import React, { forwardRef, Ref } from 'react';
import classNames from 'utils/classNames';
import checkedIcon from 'assets/svg/checked.svg';
import useStyles from './styles';

interface Props {
  label?: React.ReactChild | React.ReactChild[];
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
  color?: string;
  border?: string;
  img?: string;
  error?: string;
}
const CheckBox = forwardRef(
  ({ checked, onChange, name, color, border, img, className, label, error }: Props, ref: Ref<HTMLInputElement>) => {
    const classes = useStyles({ color, checked, border, error });
    return (
      <label className={classNames(classes.container, className)}>
        <input type="checkbox" checked={checked} onChange={onChange} name={name} ref={ref} />
        <div className={classes.checkmark} />
        {checked && <img src={img || checkedIcon} alt="checked" className={classes.icon} />}
        <div className={classes.label}>{label}</div>
        <div className={classes.errorContainer}>
          <span className={classNames(classes.errorMessage)}>{error}</span>
        </div>
      </label>
    );
  },
);
export default CheckBox;
