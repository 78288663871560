import React, { forwardRef, Ref } from 'react';
import classNames from 'utils/classNames';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useStyles from './styles';

export interface Option {
  value: string;
  label: string;
}
interface Props {
  options: Option[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value?: string;
  name?: string;
  row?: boolean;
}
const RadioButton = forwardRef(
  ({ options, onChange, className, value, name, row, ...rest }: Props, ref: Ref<HTMLInputElement>) => {
    const classes = useStyles();
    return (
      <div className={classNames(classes.radio_button_container, className)}>
        <RadioGroup row={row} name={name} value={value} onChange={onChange}>
          {options.map((option) => (
            <FormControlLabel
              {...rest}
              className={classes.form_container}
              value={option.value}
              control={
                <Radio
                  className={classes.root}
                  color="default"
                  checkedIcon={<span className={classes.checkedIcon} />}
                  icon={<span className={classes.icon} />}
                />
              }
              label={<span className={classes.option_label}>{option.label}</span>}
            />
          ))}
        </RadioGroup>
      </div>
    );
  },
);
export default RadioButton;
