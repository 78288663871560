import React, { useContext, useState } from 'react';

import { RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { useForm } from 'hooks/useInputs';
import { useDidUpdate } from 'hooks/useLifeCycle';
import CustomizedSnackbars from 'components/ui/SnackBar';
import { decodeUri } from 'utils/url';
import userContext from 'contexts/userContext';
import { validateEmail } from 'utils/validation';
import Input from 'components/common/Input/Input';
import { forgotPassword } from 'requests/auth';
import useApiState from 'hooks/useApiState';
import Title from 'components/common/Title/Title';
import Button from 'components/buttons/Button';
import checked from 'assets/svg/checked.svg';
import classNames from 'utils/classNames';
import useStyles from './styles';

type IVariant = 'success' | 'warning' | 'error' | 'info';
interface Props extends RouteComponentProps {
  forgetPasswordClassName?: string;
}
const ForgotPassword = ({ location, history, forgetPasswordClassName }: Props) => {
  const { user } = useContext(userContext);
  const [forgotState, forgotCall] = useApiState(forgotPassword);
  const classes = useStyles();

  const [formState, formActions] = useForm({
    initialValues: {
      email: '',
    },
    validation: {
      email: validateEmail,
    },
  });

  const { values, errors, touched } = formState;
  const { handleChange } = formActions;
  const { from } = decodeUri(location.search);
  const [openModal, setOpenModal] = useState(false);
  const [variantModal, setVariantModal] = React.useState<IVariant>('success');
  const [textModal, setTextModal] = useState('');
  const [textSucces, setTextSucces] = useState(false);

  useDidUpdate(() => {
    if (!forgotState.fetching) {
      if (!forgotState.errorCode) {
        /* eslint-disable max-len */
        setTextSucces(true);
      } else {
        setOpenModal(true);
        setVariantModal('warning');
        setTextModal(
          'Cette adresse email n’est pas connue dans notre base de données, veuillez réessayer avec une adresse email valide ou créerun compte.',
        );
        /* eslint-disable max-len */
      }
    }
  }, [forgotState.fetching]);
  if (user) {
    return <Redirect to={from || '/'} />;
  }

  const onSubmit = () => {
    if (formActions.validateForm()) {
      forgotCall(formState.values);
    } else {
      formActions.setTouched({ email: true });
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <div className={classNames(classes.forgetPasswordContainer, forgetPasswordClassName)}>
      <div className={classes.connexionTitle}>
        <Title
          row
          title={['Récupérer', <br />, 'mon mot de passe']}
          className={classes.titleForgetPassword}
          imageContainerClassName={classes.imageContainer}
        />
      </div>
      {textSucces ? (
        <div className={classes.forgetPasswordRoot}>
          <img src={checked} alt="" width={73} height={66} />
          <p className={classes.textSuccesContainer}>
            Un e-mail a bien été envoyé à l’adresse{' '}
            <span className={classes.emailContainer}>{formState.values.email}</span>.<br /> Veuillez suivre la procédure
            pour réinitialiser votre mot de passe.
          </p>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              history.push('/');
            }}
            className={classes.submitButton}
          >
            Retour à l’accueil
          </Button>
        </div>
      ) : (
        <form className={classes.forgetPasswordRoot}>
          <div className="inputWrapper">
            <Input
              id="outlined-basic"
              label="Votre e-mail"
              required
              name="email"
              onChange={handleChange}
              value={values.email}
              error={touched.email && errors.email}
            />
          </div>
          <p className={classes.forgetPasswordText}>Un lien vous sera envoyé pour réinitialiser votre mot de passe.</p>
          <div className={classes.textRequired}>
            <span className={classes.requiredMark}>* </span>
            <span> champ obligatoire </span>
          </div>
          <Button size="large" variant="contained" onClick={onSubmit} className={classes.submitButton}>
            Envoyer
          </Button>
          <div className={classes.forgetPasswordLink}>
            <Link to="/login" className="forgotPswd">
              Annuler et revenir à la page de connexion
            </Link>
          </div>
        </form>
      )}
      <CustomizedSnackbars variant={variantModal} open={openModal} handleClose={handleCloseModal} message={textModal} />
    </div>
  );
};

export default ForgotPassword;
