import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CategorieLogoConatiner from 'components/badge/CategorieLogo';
import classNames from 'utils/classNames';
import useApiState from 'hooks/useApiState';
import userContext from 'contexts/userContext';
import { Badge } from 'requests/badges';
import { getBadgeCandidature, PatchCandidature } from 'requests/candidature';
import { getDetentteur } from 'requests/user';
import moment from 'moment';
import Button from 'components/buttons/Button';
import AddLogo from 'assets/svg/defaultUser_v3.svg';
import ImagesCritere from 'assets/svg/imagesCritere.svg';
import TextCritere from 'assets/svg/textCritere.svg';
import QuestionnaireCritere from 'assets/svg/questionnaire.svg';
import useStyles from './style';

interface Props {
  badge: Badge;
  className?: string;
  id?: string;
}

const BigCard = ({ badge, className, id }: Props) => {
  const { user } = useContext(userContext);
  const history = useHistory();
  const [badgeCandidatureState, badgeCandidatureCall] = useApiState(getBadgeCandidature);
  const [badgeDetentteurState, badgeDetentteurCall] = useApiState(getDetentteur);
  const [delivranceState, delivrancePatch] = useApiState(PatchCandidature);

  useEffect(() => {
    if (delivranceState.data && !delivranceState.errorCode && !delivranceState.fetching) {
      badgeCandidatureCall(badge._id);
    }
  }, [delivranceState.data, badge._id, badgeCandidatureCall, delivranceState.errorCode, delivranceState.fetching]);

  const classes = useStyles();
  const [view, setView] = useState(false);
  const showView = () => {
    if (!view) {
      badgeCandidatureCall(badge._id);
      badgeDetentteurCall(badge._id);
    }
    setView(!view);
  };
  const accept = (id: string) => {
    delivrancePatch({ id, status: 'accepted' });
  };
  const refuse = (id: string) => {
    delivrancePatch({ id, status: 'refused' });
  };
  const tags = badge.tags.map((el: string) => `#${el}`);
  const renderImg = (type: string) => {
    const t: { img: string; text: string } = { img: '', text: '' };
    switch (type) {
      case 'text': {
        t.img = TextCritere;
        t.text = 'Texte';
        break;
      }
      case 'pdf': {
        t.img = ImagesCritere;
        t.text = 'PDF';
        break;
      }
      case 'image': {
        t.img = ImagesCritere;
        t.text = 'Image';
        break;
      }
      default: {
        t.img = QuestionnaireCritere;
        t.text = 'Questionnaire';
        break;
      }
    }
    return t;
  };
  const isOwner = badge.issuerId._id === user?._id;

  const goToBadge = (idBadge: string) => {
    history.push('/candidature/' + id + '/' + idBadge);
  };

  return (
    <div className={classNames(classes.card_large_wrapper, className)}>
      <div className={classNames(classes.wrapper_card, view && classes.border)} onClick={showView}>
        <div className={classes.card_large_container}>
          <img src={badge.image} alt="badge model" className={classes.image_container_large} />
          <div className={classes.badge_content}>
            <div className={classes.badge_title}>{badge.title}</div>
            <div className={classes.badge_description}>{badge.description}</div>
            <div className={classes.badge_content_footer}>
              {tags.map((el, i) => (
                <div className={classes.tagContainer} key={el + i.toString()}>
                  <Link to={'/marketplace?search=' + el.replace('#', '')}>
                    <p className={classes.tagTitle}>{el}</p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.categories_container}>
          <div className={classes.logo_categories}>
            {badge.mainCategory && (
              <CategorieLogoConatiner
                className={classes.logo_categorie_header}
                label={badge.mainCategory.name}
                bgColor={badge.mainCategory.background}
              />
            )}
            {badge.secondaryCategory && (
              <CategorieLogoConatiner
                className={classes.logo_categorie_header}
                label={badge.secondaryCategory.name}
                bgColor={badge.secondaryCategory.background}
              />
            )}
          </div>

          <div className={classes.badge_issuer}>
            <span className={classes.title_emetteur}>Émetteur :</span>
            <span className={classes.subtitle}>{` ${badge.issuerId.fullName}`}</span>
          </div>
          {badge.deliveranceCode && isOwner && (
            <div className={classes.badge_code}>
              <span className={classes.title_emetteur}>Code :</span>
              <span className={classes.subtitle}>{` ${badge.deliveranceCode}`}</span>
            </div>
          )}
          <div className={classes.badge_date}>
            {badge.startDate && (
              <div>
                <span className={classes.title_emetteur}>Date :</span>
                <span className={classes.subtitle}>
                  {` ${[
                    moment(badge.startDate).format('DD.MM.YYYY'),
                    badge.endDate && moment(badge.endDate).format('DD.MM.YYYY'),
                  ]
                    .filter((d) => d)
                    .join(' > ')}`}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classNames(!view && classes.display_none)}>
        <div>
          <div className={classes.detenteurContainer}>
            <div className={classes.children_container}>
              <div>
                <p className={classes.title_critere}>{`Critères (${badge.criterion.length})`}</p>
              </div>
              <div className={classes.listCritere}>
                {badge.criterion.map((el, i) => (
                  <div
                    key={el._id}
                    className={classNames(
                      badge.criterion.length > 1 && i < badge.criterion.length - 1 && classes.borderCritere,
                      classes.critere_title_container,
                    )}
                  >
                    <div className={classes.criterContainer}>
                      <div>
                        <div className={classes.circleCritere}>
                          <span className={classes.index_critere}>{i + 1}</span>
                        </div>
                      </div>
                      <div className={classes.critereWrapper}>
                        <span className={classes.critere_title}>{el.title}</span>
                        <div className={classes.critere_description}>
                          <p className={classes.descriptionText}>{el.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.prevCritere}>
                      <p className={classes.prevCritereText}>Preuve attendue:</p>
                      <div className={classes.prevCritereImgContainer}>
                        <img src={renderImg(el.type).img} alt="" />
                        <span className={classNames(classes.tagTitle, classes.smallWeight, classes.marginImg)}>
                          {renderImg(el.type).text}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={classes.detenteurContainer}>
            <div className={classes.children_container}>
              <div>
                <p className={classes.title_critere}>
                  {`Demandes de délivrance (${badgeCandidatureState.data?.candidatures.filter(
                    (e) => e.status === 'pending',
                  ).length || 0})`}
                </p>
              </div>
              <div className={classes.demandeList}>
                <div className={classes.flexCandidature}>
                  {badgeCandidatureState.data?.candidatures
                    .filter((e) => e.status === 'pending')
                    .map((el) => {
                      return (
                        <div className={classes.candidatureItem} key={el._id}>
                          <div className={classes.imageUserCandidateur}>
                            <img alt="" src={el.idUser.image || AddLogo} className={classes.img} />
                          </div>
                          <div>
                            <p className={classes.tagTitle}>
                              {el.idUser.firstName} {el.idUser.lastName}
                            </p>
                          </div>
                          <div className={classes.btnContainer}>
                            <Button
                              className={classes.create_badges_button}
                              size="medium"
                              onClick={() => goToBadge(el._id)}
                            >
                              <span className={classes.textBtn}>Voir</span>
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.children_container}>
              <div>
                <p className={classes.title_critere}>{`Détenteurs (${badgeDetentteurState?.data &&
                  badgeDetentteurState.data?.length})`}</p>
              </div>
              <div className={classNames(classes.listDetentteur)}>
                {badgeDetentteurState.data?.map((el, i) => (
                  <div key={el?.firstName + i} className={classes.itemDetentteur}>
                    <p className={classNames(classes.tagTitle, classes.smallWeight)}>
                      {el.firstName && el.lastName ? `${el.firstName} ${el.lastName}` : el.name}
                    </p>
                    <p className={classNames(classes.descriptionText, classes.userAge)}>
                      {el?.age
                        ? moment()
                            .diff(el?.age, 'years')
                            .toString()
                        : ''}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              height: 80,
              background: '#d3fefe',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              <Button
                variant="contained"
                onClick={() => history.push(`/awardBadge/${badge._id}`)}
                className={classes.awardBadge}
              >
                Attribuer ce badge
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BigCard;
