import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({

    errorContainer: {
        position: 'absolute',
        display: 'grid',
        gridTemplateColumns: '1fr 384px 1fr',
        top: 'calc(100% + 5px)',
        height: 20,
        width: '100%',
        left: 0,
    },

    errorMessage: {
        color: theme.palette.info.main,
        gridColumn: '2/2',
        width: '100%',
    },

}))