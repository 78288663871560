import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from 'components/buttons/Button';
import BigCard from 'components/cards/BigCard/BigCard';
import useApiState from 'hooks/useApiState';
import { getUserBadges } from 'requests/badges';
import { getMyCandidature } from 'requests/candidature';
import Title from 'components/common/Title/Title';
import className from 'utils/classNames';
import Frame from 'assets/svg/Frame.svg';
import useStyles from './styles';
import Spinner from '../../components/ui/Spinner';
import BadgeContainer from 'components/profile/BadgeContainer/BadgeContainer';
import { getMyAwards } from 'requests/award';

const BadgesContainer = () => {
  const classes = useStyles();
  const [badgeState, badgeCall] = useApiState(getUserBadges);
  const [getMyAwardsState, getMyAwardsCall] = useApiState(getMyAwards);
  const location = useLocation();
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    switch (location.search) {
      case '?=candidature': {
        getMyAwardsCall();
        break;
      }
      case '?=user': {
        badgeCall();
        break;
      }
      default: {
        badgeCall();
      }
    }
  }, [location.search]);

  useEffect(() => {
    setFilteredData(getMyAwardsState.data?.map((b: any) => b.idBadge) || []);
  }, [getMyAwardsState.data]);

  useEffect(() => {
    setFilteredData(badgeState.data || []);
  }, [badgeState.data]);

  return (
    <div className={className(classes.badges_container, 'flex_column')}>
      <div className={classes.connexionTitle}>
        <Title row title="Mes Open Badges" />
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.content}>
          <div className={classes.badges_container_header}>
            <div className={classes.badges_title}>
              {filteredData?.length || badgeState.data?.length ? (
                <Title
                  title={`Mes Open Badges ${location.search === '?=candidature' ? '' : 'émis'} (${
                    location.search === '?=candidature' ? filteredData?.length : badgeState.data?.length
                  })`}
                  className={classes.subTitle}
                />
              ) : (
                <Title
                  title={`Mes Open Badges ${location.search === '?=candidature' ? '' : 'émis'}`}
                  className={classes.subTitle}
                />
              )}
            </div>
            <div className={classes.rightItems}>
              <img src={Frame} alt="" className={classes.frame} />
              <Link to="/create-badge">
                <Button className={classes.create_badges_button} size="large">
                  <span className={classes.textBtn}>Créer un nouvel Open Badge</span>
                </Button>
              </Link>
            </div>
          </div>
          {badgeState.fetching && <Spinner />}
          {badgeState.data && (
            <div>
              {badgeState.data.map((item) => (
                <BigCard className={classes.badge_card} id={item._id} badge={item} />
              ))}
            </div>
          )}
          {filteredData && location.search === "?=candidature" && (
            <>
              <div>
                <BadgeContainer withoutDisplay notLimit targetPath="/marketplace" label={``} data={filteredData} type="candidature" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default BadgesContainer;
