import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card_user_wrapper: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
  card_user_container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: "space-between",
    position: 'relative',
  },
  card_image_container: {
    width: 220,
    height: 220,
    borderRadius: 6,
    position: 'relative',
  },
  preview_image_container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 217,
    height: 217,
    border: 'none',
    zIndex: 1,
  },
  preview_image: {
    width: '100%',
    height: '100%',
  },
  btn_preview: {
    position: 'absolute',
    bottom: 0,
    height: 30,
    width: '100%',
    zIndex: 2,
    backgroundColor: 'rgba(204, 204, 204, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'white',
  },
  card_image_wrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  add_image: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  img: {
    width: 20,
    height: 20,
  },
  imgUser: {
    width: 217,
    height: 217,
  },
  card_info_container: {
    marginLeft: 50,
  },
  text_info_name: {
    margin: '0px 0px 11px 0px',
    display: 'flex',
  },
  text_info_age: {
    marginBottom: 10,
    fontSize: 20,
    lineHeight: '30px',
    color: theme.palette.grey.A400,
  },
  text_info_bannier: {
    background: theme.palette.error.main,
    borderRadius: 5,
    fontWeight: 'bold',
    fontSize: 16,
    color: '#FFFFFF',
    padding: "5px 10px",
    display: "inline-block"
  },
  network_container: {
    marginTop: 22,
    flexWrap: 'wrap',
    display: 'flex',
  },
  network_item: {
    display: 'flex',
    margin: '3px 0px',
    alignItems: 'center',
  },
  imgNetwork: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  capitalizeText: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
  },
  capitalizeTextRight: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    marginRight: 5,
    wordBreak: "break-all",
    maxWidth: 381
  },
}));
