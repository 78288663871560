import React, { useRef, useState, useEffect, useContext } from 'react';
import Modal from '@material-ui/core/Modal/Modal';
import useOnclickOutside from 'hooks/useOnclickOutside';
import { decodeUri } from 'utils/url';

// components
import { Link } from 'react-router-dom';
import Title from 'components/common/Title/Title';
import Button from 'components/buttons/Button';
import userContext from 'contexts/userContext';
import ModalBlock from 'components/modals/ModalBlock';
// svg
import Row from 'assets/svg/Row';
import getBadge from 'assets/svg/getBadge.svg';
import shareBadge from 'assets/svg/shareBadge.svg';
import createBadge from 'assets/svg/createBadge.svg';
import determineBadge from 'assets/svg/determineBadge.svg';
import spreadBadge from 'assets/svg/spreadBadge.svg';
import personnel from 'assets/svg/picto-personnel.svg';
import diffusable from 'assets/svg/picto-diffusable.svg';
import gratuit from 'assets/svg/picto-gratuit.svg';
import verifie from 'assets/svg/picto-verifie.svg';
import schema from 'assets/svg/schema-home.svg';

// utils
import classNames from 'utils/classNames';

// css
import useStyles from './styles';

// data to map
const getInfoArray = [
  {
    img: getBadge,
    label: 'Obtenir des Open Badges',
  },
  { img: shareBadge, label: 'Partager mes Open Badges ' },
];
const createInfoArray = [
  {
    img: createBadge,
    label: (
      <span>
        <b> Créez et customisez</b> vos Open Badges à partir de votre charte graphique
      </span>
    ),
  },
  {
    img: determineBadge,
    label: (
      <span>
        <b>Déterminez </b>les <b>critères</b> de délivrance
      </span>
    ),
  },
  {
    img: spreadBadge,
    label: (
      <span>
        <b>Diffusez et partagez</b> vos Open Badges auprès de vos publics
      </span>
    ),
  },
];

const HomeContainer = ({ location }: any) => {
  const { user } = useContext(userContext);
  const [modalState, setModal] = useState(false);
  const classes = useStyles();

  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  useEffect(() => {
    if (user?.active === false) {
      openModal();
    } else {
      closeModal();
    }
  }, [user]);

  useEffect(() => {
    const selectedTab = decodeUri(location.search).url as any;
    if (selectedTab) {
      window.scrollTo({
        top: document.getElementById(selectedTab)?.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [location.search]);
  const divRefSetting = useRef<HTMLDivElement>(null);
  const divRefNotif = useRef<HTMLDivElement>(null);

  const [openSetting, setOpenSetting] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const toggleSetting = () => setOpenSetting(!openSetting);
  const toggleNotif = () => setOpenNotif(!openNotif);
  useOnclickOutside(divRefSetting, toggleSetting);
  useOnclickOutside(divRefNotif, toggleNotif);
  const textPersonel = [
    'Un Open Badge est personnel et sert à valoriser les ',
    <span key="personel" className={classes.textPersonel}>
      talents, expériences, compétences, engagements...
    </span>,
    'd’une personne',
  ];
  const textVerifie = [
    'Un Open Badge contient des ',
    <span key="verifie " className={classes.textVerifie}>
      informations vérifiables, infalsifiables et sécurisées{' '}
    </span>,
    ' sous forme de métadonnées encodées dans l’image',
  ];
  const textGratuit = [
    'Un Open Badge repose sur une technologie ouverte : ',
    <span className={classes.textGratuit}>logiciel libre et standard ouvert</span>,
  ];
  const textDiffusable = [
    'Un Open Badge peut être ',
    <span className={classes.textDiffusable}>partagé</span>,
    ' par mail et sur les réseaux sociaux, ',
    <span className={classes.textDiffusable}>affiché</span>,
    ' sur un CV ou encore ',
    <span className={classes.textDiffusable}>stocké</span>,
    ' sur un portfolio',
  ];
  const badgeType = (
    src: string,
    title: string,
    description: React.ReactChild | React.ReactChild[],
    color: string,
    className?: any,
  ) => {
    return (
      <div className={classNames(classes.badgeTypeContainer, className)}>
        <img src={src} alt="" />
        <span style={{ color: color }} className={classes.titleBadgeType}>
          {title}
        </span>
        <p className={classes.description}>{description}</p>
      </div>
    );
  };
  return (
    <div className={classes.HomeContainer}>
      {/* <div className="tab_bar_container">
        <div className="tab_bar_user_logo">
          <NotifPanel toggleNotif={toggleNotif} stateNotif={openNotif} reference={divRefNotif} />
          <Settings toggleSetting={toggleSetting} stateSetting={openSetting} reference={divRefSetting} />
        </div>
      </div> */}
      <div className={classes.infoContainer} id="0">
        <Title className={classes.titleInfo} title="Qu’est ce qu’un Open Badge ?" />
        <div className={classes.textInfo}>
          <p>
            Un Open Badge se présente sous la forme <b> d’une image qui contient des données.</b>
            <br /> Il permet de mettre en avant les <b>compétences</b> acquises quel qu’en soit le contexte,
            <br />
            <b>personnel ou professionnel.</b>
          </p>
        </div>

        <div className={classes.badgeTypeRoot}>
          {badgeType(personnel, 'Personnel', textPersonel, '#F14E7F', classes.personel)}
          {badgeType(verifie, 'Vérifié', textVerifie, '#008EAD', classes.verifie)}
          {badgeType(gratuit, 'Gratuit & ouvert', textGratuit, '#5B78C2', classes.gratuit)}
          {badgeType(diffusable, 'Diffusable', textDiffusable, '#434343', classes.diffusable)}
          <div className={classes.schemaImageContainer}>
            <img src={schema} alt="" className={classes.schemaImage} />
          </div>
        </div>
      </div>

      <div className={classes.getBadgeContainer} id="1">
        <div>
          <p className={classes.getBadgeText}>Vous êtes lycéen.ne, étudiant.e, en formation, en recherche d’emploi</p>
          <Row color="#0C3D9C" className={classNames(classes.infoImage, classes.infoImageBadge)} />
        </div>
        <Title
          className={classNames(classes.createBadgeTitle, classes.getBadgeTextColor)}
          title="Récupérez ici vos Open Badges "
        />
        <div className={classes.createBadgeParagraph}>
          <p>
            Les Open Badges sont des supports pour valoriser une diversité d'activités : des expériences, des
            compétences nouvelles, un engagement, l'appartenance à une communauté ou encore un hobby qui nous tient à
            cœur.
          </p>
          <p className={classNames(classes.helpText, classes.searchBadgeText)}>
            Cherchez les Open Badges qui vous correspondent <br />
            et mettez en avant vos atouts pour faire avancer vos projets !
          </p>
        </div>
        <div className={classNames(classes.getBadgeImageContainer, classes.getBadgeContainerWidth)}>
          {getInfoArray.map((el, i) => (
            <div key={i} className={classes.getBadgeImage}>
              <img src={el.img} alt="" />
              <span>{el.label}</span>
            </div>
          ))}
        </div>
        <Link to="/marketplace">
          <Button className="home_button" size="large" variant="contained">
            Obtenir des Open Badges
          </Button>
        </Link>
      </div>

      <div className={classes.createBadgeContainer} id="2">
        <div>
          <p className={classes.getBadgeText}>
            Vous êtes un établissement scolaire, une entreprise, une association, un club de sports
          </p>
          <Row color="#FF5C97" className={classNames(classes.infoImage, classes.infoImageBadge)} />
        </div>
        <Title className={classes.createBadgeTitle} title="Emettez des Open Badges" />
        <div className={classes.createBadgeParagraph}>
          <p>
            Les Open Badges mettent de la cohérence dans les parcours et facilitent la lecture de profils éclectiques en
            termes d'informations (une passion, une expérience pro, un engagement, une formation, etc.).
          </p>
          <p className={classes.helpText}>
            Aidez vos publics à mettre en avant ce qu'ils font et ce qu'ils savent faire grâce à vous !{' '}
          </p>
        </div>
        <div className={classNames(classes.getBadgeImageContainer, classes.getBadgeImageMediaContainer)}>
          {createInfoArray.map((el, i) => (
            <div key={i} className={classes.getBadgeImage}>
              <img src={el.img} className={classes.imageGetBagde} alt="" />
              {React.cloneElement(el.label, { className: classes.labelImage })}
            </div>
          ))}
        </div>
        <Link to="/create-badge">
          <Button className={classes.createBadge} size="large" variant="contained">
            Emettre des Open Badges
          </Button>
        </Link>
      </div>

      <Modal open={modalState}>
        <ModalBlock />
      </Modal>
    </div>
  );
};
export default HomeContainer;
