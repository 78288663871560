import React from 'react';
import classNames from 'utils/classNames';
import './scss/categorieLogo.scss';

interface Props {
  label?: string;
  image?: string;
  className?: string;
  withText?: boolean;
  bgColor?: string;
}
const CategorieLogo = ({ label, image, className, withText, bgColor }: Props) => {
  const lettre = label && label.charAt(0);
  return (
    <div className={classNames(className, 'catergorie_logo_component')}>
      <div style={{ backgroundColor: bgColor }} className="categorie_cercle" />
      <div className={image ? 'img_logo' : 'lettre'}>
        {!image ? <div>{lettre}</div> : <img className="categorie_logo_card" src={image} alt="categorieLogo" />}
      </div>
      {withText && <div className="label">{label}</div>}
    </div>
  );
};
export default CategorieLogo;
