function getTransformPart(transform: string, part: string) {
  const partStart = transform.slice(transform.indexOf(part));
  const partString = partStart.slice(0, partStart.indexOf(')'));

  return partString
    .replace(part, '')
    .replace('(', '')
    .replace(')', '');
}

export function getTranslate(transform: string | null | undefined) {
  if (!transform) return null;
  const coordinatesString = getTransformPart(transform, 'translate');
  const splitChar = coordinatesString.indexOf(',') !== -1 ? ',' : ' ';
  const coordinatesArray = coordinatesString.split(splitChar);
  if (coordinatesArray.length === 1 && Number(coordinatesArray[0])) {
    return { x: Number(coordinatesArray[0]), y: Number(coordinatesArray[0]) };
  }
  if (coordinatesArray.length !== 2) return null;
  return { x: Number(coordinatesArray[0]), y: Number(coordinatesArray[1]) };
}

export function getScale(transform: string | null | undefined) {
  if (!transform) return null;
  const scaleString = getTransformPart(transform, 'scale');
  const splitChar = scaleString.indexOf(',') !== -1 ? ',' : ' ';
  const scaleArray = scaleString.split(splitChar);
  if (scaleArray.length === 1 && Number(scaleArray[0])) {
    return { scaleX: Number(scaleArray[0]), scaleY: Number(scaleArray[0]) };
  }
  if (scaleArray.length !== 2) return null;
  return { scaleX: Number(scaleArray[0]), scaleY: Number(scaleArray[1]) };
}

export function getSvg(id: string) {
  const svgContainer = document.getElementById(id);
  if (svgContainer) {
    return svgContainer.querySelector('svg');
  }
  return null;
}

export function getText(id: string) {
  const svg = getSvg(id);
  if (svg) {
    const text = svg.querySelector<SVGTextElement>('text');
    return text;
  }

  return null;
}

export function getIcon(id: string) {
  const svg = getSvg(id);
  if (svg) {
    const paths = svg.querySelectorAll<SVGPathElement>('path');

    if (paths.length) return paths[paths.length - 1];
  }

  return null;
}

export function getElementTransform(element: SVGPathElement | SVGTextElement) {
  const transform = element.getAttribute('transform');
  const translate = getTranslate(transform);
  const box = element.getBBox();
  const { width, height } = box;
  let { x, y } = box;

  let viewBoxX = 300;
  let viewBoxY = 300;
  const viewBox = (element.parentElement as Element).getAttribute('viewBox');
  if (viewBox) {
    const viewBoxArray = viewBox.split(' ');
    viewBoxX = Number(viewBoxArray[2]);
    viewBoxY = Number(viewBoxArray[3]);
  }

  x += width;
  y += height;
  if (translate) {
    x += translate.x;
    y += translate.y;
  }
  x = Number(((x * 100) / (viewBoxX + width)).toFixed(2));
  y = Number(((y * 100) / (viewBoxY + height)).toFixed(2));
  return { x, y };
}

export function setElementTransform(element: SVGPathElement | SVGTextElement, translateX: number, translateY: number) {
  const {
 width, height, x, y,
} = element.getBBox();
  let viewBoxX = 300;
  let viewBoxY = 300;
  const viewBox = (element.parentElement as Element).getAttribute('viewBox');
  if (viewBox) {
    const viewBoxArray = viewBox.split(' ');
    viewBoxX = Number(viewBoxArray[2]);
    viewBoxY = Number(viewBoxArray[3]);
  }

  return {
    x: (translateX * (viewBoxX + width)) / 100 - x - width,
    y: (translateY * (viewBoxY + height)) / 100 - y - height,
  };
}
