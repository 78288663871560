import React, { useState, useEffect } from 'react';
import { useForm } from 'hooks/useInputs';
import classNames from 'utils/classNames';
import { codeValidation } from 'utils/validation';
import { Link } from 'react-router-dom';
import useKeys from 'hooks/useKeys';

import Button from 'components/buttons/Button';
import TextInput from 'components/inputs/TextInput';
import DatePicker from 'components/pickers/DatePicker';
import CheckBox from 'components/common/Checkbox/Checkbox';
import Label from 'components/inputs/Label';
import Title from 'components/common/Title/Title';
import Modal from 'components/common/Popup/Popup';
import TabNavigation from 'components/ui/TabNavigation/TabNavigation';
import EchelonRow from 'components/common/EchelonRow/EchelonRow';

import iconEchelon from 'assets/svg/badge-echelons.svg';
import criteriaImage from 'assets/svg/pictocriteria.svg';
import add from 'assets/svg/add.svg';
import logoCheck from 'assets/svg/checkLogo.svg';
import note from 'assets/svg/pictonote.svg';
import pictoCritere from 'assets/svg/picto-critere.svg';

import 'components/forms/scss/createBadgeCriteria.scss';
import 'components/forms/scss/createBadgeFeatures.scss';

import EchelonCriteriaInput from 'components/common/EchelonCriteriaInput/EchelonCriteriaInput';
import useStyles from './styles';
import CriteriaForm, { CriteriaValues } from '../CriteriaForm';

export interface CriteriaFormValues {
  criteriaSummary: string;
  releaseStartDate: string;
  releaseEndDate: string;
  releaseAuto: boolean;
  expectedCriteria: CriteriaValues[];
  echelonCriteria: CriteriaValues[][];
  releaseCode: string;
  extendCriteria: boolean;
  extendEndorsers: boolean;
}

interface Props {
  className?: string;
  onSubmit: (values: CriteriaFormValues) => void;
  echelons: string[];
  initialValues: Partial<CriteriaFormValues>;
  simple?: boolean;
  onCloseModal?: () => void;
  openModal?: boolean;
  fetching?: boolean;
}

function localCodeValidation(code: string, values: CriteriaFormValues) {
  if (!values.releaseAuto) return '';
  return codeValidation(code);
}

const CreateBadgeCriteria = ({
  onSubmit,
  className,
  echelons,
  initialValues,
  simple,
  onCloseModal,
  openModal,
  fetching,
}: Props) => {
  const echelonValidation = (values: CriteriaValues[][]) => {
    if (simple) return '';
    if (step === 0) return '';
    if (values.length === 0) return '';
    // TODO: fix Echelon validation when extendCriteria is true
    const errors: string[] = [];
    /* const errors = values.map((value) => {
      if (value.length) return '';
      return 'Champ vide';
    }); */

    if (!errors.filter((e) => e).length) return '';
    return errors;
  };
  const [state, actions] = useForm({
    initialValues: {
      criteriaSummary: '',
      releaseStartDate: '',
      releaseEndDate: '',
      releaseAuto: false,
      extendCriteria: false,
      extendEndorsers: false,
      expectedCriteria: [] as CriteriaValues[],
      echelonCriteria: echelons.map(() => []) as CriteriaValues[][],
      releaseCode: generateCode(),
      ...initialValues,
    },
    validation: {
      releaseCode: localCodeValidation,
      echelonCriteria: echelonValidation,
    },
    required: ['criteriaSummary', 'expectedCriteria', 'releaseCode'],
  });
  const [submitTouched, setSubmitTouched] = useState(false);

  const { values, errors, touched } = state;
  const {
 handleChange, setValues, validateForm, setAllTouched, setErrors,
} = actions;
  const [showCriteria, setShowCriteria] = useState(true);

  const [step, setStep] = useState(0);
  const [showEchelon, setShowEchelon] = useState(false);
  const [criteriaKeys, addCriteriaKey, deleteCriteriaKey] = useKeys(values.expectedCriteria);

  const classes = useStyles();
  useEffect(() => {
    if (values.expectedCriteria.length >= 1) setShowCriteria(false);
    else if (values.expectedCriteria.length === 0) setShowCriteria(true);
  }, [values.expectedCriteria.length]);

  useEffect(() => {
    setValues({
      echelonCriteria: echelons.map(() => []),
    });
    // eslint-disable-next-line
  }, [echelons]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [step]);

  useEffect(() => {
    if (step === 0) setErrors({ echelonCriteria: '' });
  }, [step]);

  function addCriteria(newCriteria: CriteriaValues) {
    setValues({ expectedCriteria: [...values.expectedCriteria, newCriteria] });
  }
  function addEchelonCriteria(nextValues: CriteriaValues, index: number) {
    const nextEchelonCriteria = [...values.echelonCriteria];
    nextEchelonCriteria[index] = [...nextEchelonCriteria[index], nextValues];
    setValues({ echelonCriteria: nextEchelonCriteria });
  }

  function generateCode() {
    let releaseCode = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i += 1) {
      releaseCode += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return releaseCode;
  }

  function generateCodeAndSet() {
    setValues({ releaseCode: generateCode() });
  }

  function modifyCriteria(nextValues: CriteriaValues, index: number) {
    const expectedCriteria = [...values.expectedCriteria];
    expectedCriteria[index] = nextValues;
    setValues({ expectedCriteria });
  }

  function modifyEchelonCriteria(nextValues: CriteriaValues, index: number, i: number) {
    const echelonCriteria = [...values.echelonCriteria];
    const criterion = [...echelonCriteria[index]];
    criterion[i] = nextValues;
    echelonCriteria[index] = criterion;
    setValues({ echelonCriteria });
  }

  function checkForSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (validateForm()) {
      if (showCriteria) {
        setErrors({ expectedCriteria: 'Veuillez valider le critère!' });
        setAllTouched(true);
      } else if (step === 0 && !simple) {
        setStep(1);
        setAllTouched(false);
        setSubmitTouched(false);
        // setErrors({ echelonCriteria: echelons.map(() => 'Champ vide') });
      } else {
        onSubmit(values);
      }
    } else {
      setAllTouched(true);
      setSubmitTouched(true);
      if (showCriteria && step === 0) {
        setErrors({ expectedCriteria: 'Veuillez valider le critère!' });
      }
    }
  }

  function renderEchelon(echelon: string, index: number) {
    return (
      <div key={index} className={classes.collapseRoot}>
        <EchelonCriteriaInput
          onDelete={(i) => {
            setValues({
              echelonCriteria: values.echelonCriteria.map((cri, echelonIndex) =>
                (echelonIndex === index ? cri.filter((c, deleteIndex) => i !== deleteIndex) : cri)),
            });
          }}
          echelon={echelon}
          index={index}
          values={values.echelonCriteria[index]}
          errorText={submitTouched && errors.echelonCriteria[index]}
          onModify={(nextValues, i) => modifyEchelonCriteria(nextValues, index, i)}
          onAdd={(nextValues) => {
            addEchelonCriteria(nextValues, index);
          }}
        />
      </div>
    );
  }
  const simpleBadge = (
    <>
      {!simple ? null : <Title title="Critères de délivrance de l'Open Badge" className="title_criteria" />}
      <TextInput
        name="criteriaSummary"
        onChange={handleChange}
        value={values.criteriaSummary}
        multiline
        rows={6}
        label="Critères d'obtention de votre Open Badge"
        errorText={touched.criteriaSummary && errors.criteriaSummary}
        placeholder="Décrivez les critères attendus (exemples)"
        labelClassName="label_className"
        required
      />
      <div className="create_badge_criteria_dates_container">
        <DatePicker
          name="releaseStartDate"
          value={values.releaseStartDate}
          onChange={handleChange}
          classes={{ wrapper: 'create_badge_criteria_date_input', label: 'label_date_picker' }}
          label="Date de début de délivrance"
          dateMax={values.releaseEndDate}
          isChecked={false}
          error={touched.releaseStartDate ? errors.releaseStartDate : ''}
          dataPickerClassName="date_picker_className"
        />
        <DatePicker
          name="releaseEndDate"
          value={values.releaseEndDate}
          onChange={handleChange}
          classes={{ wrapper: 'create_badge_criteria_date_input', label: 'label_date_picker' }}
          label="Date de fin de délivrance"
          dateMin={values.releaseStartDate}
          isChecked={false}
          error={touched.releaseEndDate ? errors.releaseEndDate : ''}
          dataPickerClassName="date_picker_className"
        />
      </div>
      <CheckBox
        name="releaseAuto"
        onChange={handleChange}
        checked={values.releaseAuto}
        className="create_badge_criteria_checkbox"
        label="Créer un code de délivrance automatique"
      />
      {values.releaseAuto && (
        <>
          <div className="create_badge_generate_code">
            <Button size="medium" onClick={generateCodeAndSet} className="generate_code_button" type="button">
              Générer un code
            </Button>
            <TextInput
              name="releaseCode"
              value={values.releaseCode}
              onChange={handleChange}
              errorText={errors.releaseCode}
              placeholder="Votre code"
              className="generate_code_input"
            />
          </div>
        </>
      )}
      <Label className="release_label">Les critères :</Label>
      {values.expectedCriteria.map((criteria, index) => (
        <CriteriaForm
          borderForm
          onDelete={() => {
            deleteCriteriaKey(index);
            setValues({ expectedCriteria: values.expectedCriteria.filter((c, i) => i !== index) });
          }}
          onSubmit={(nextValues) => modifyCriteria(nextValues, index)}
          {...criteria}
          done
          resetOnClose
          // eslint-disable-next-line
          key={criteriaKeys[index]}
          titleForm={(
            <div className="title_form_image_container">
              <img alt="" src={criteriaImage} className="criteria_form_image" />
              {' '}
              <span>
                Critère
                {index + 1}
              </span>
            </div>
          )}
        />
      ))}
      {showCriteria && (
        <div className={classes.CriteriaFormContainer}>
          <CriteriaForm
            errorText={touched.expectedCriteria && errors.expectedCriteria}
            resetOnSubmit
            initialOpen
            onSubmit={(nextValues) => {
              addCriteriaKey();
              addCriteria(nextValues);
            }}
            deleteCriteria={() => {
              setShowCriteria(false);
              setErrors({ expectedCriteria: '' });
            }}
            showDelete={values.expectedCriteria.length >= 1}
            borderForm
            addFormPropComponentClassName={classes.addFormPropComponentClassName}
          />
          <div className={classes.textRequired}>
            <span className={classes.requiredMark}>* </span>
            <span> champs obligatoires </span>
          </div>
        </div>
      )}
      {values.expectedCriteria.length >= 1 && (
        <div onClick={() => setShowCriteria(true)} className="add_container">
          <img className="add_container_image" src={add} alt="" />
          <span>Nouveau critère</span>
        </div>
      )}
    </>
  );
  const BadgeEchelon = (
    <div className={classes.badgeEchelonContainer}>
      <img alt="" src={iconEchelon} />
      <span className={classes.badgeEchelonText}>Critères des échelons</span>
    </div>
  );

  const VisualEchelon = (
    <div className={classes.badgeEchelonContainer}>
      <span className={classes.badgeEchelonText}>Visuel</span>
      <span style={{ textTransform: 'lowercase', marginLeft: '5px' }}> de l'</span>
      <span>Open Badge mère</span>
    </div>
  );

  const renderEchelonCriteria = () => {
    if (echelons.length) {
      return <div className="echelon_inputs">{echelons.map(renderEchelon)}</div>;
    }
    return null;
  };

  return (
    <form onSubmit={checkForSubmit} className={classNames('components_form_create_badge_criteria', className)}>
      {simple ? (
        simpleBadge
      ) : (
        <TabNavigation
          isCreate
          fixed={false}
          value={step}
          className={classes.tabNavigation}
          selectedTabClassName={classes.selectedTabClassName}
          keepMounted
          onChange={(e, nextStep) => setStep(nextStep)}
          tabs={[
            {
              name: VisualEchelon,
              element: simpleBadge,
            },
            {
              name: BadgeEchelon,
              disabled: !validateForm() && step !== 1,
              element: (
                <div className={classes.echelonInputContainer}>
                  {showEchelon ? (
                    renderEchelonCriteria()
                  ) : (
                    <>
                      <EchelonRow
                        echelonSrc={note}
                        echelonText="Vous pouvez utiliser les critères de l'Open Badge mère pour tous les échelons :"
                        onClick={() => {
                          setShowEchelon(true);
                          setValues({ extendCriteria: true });
                        }}
                        buttonClassName={classes.useBadgeVisualButton}
                        children="Utiliser les critères de l'Open Badge mère"
                        className={classes.echelonClassName}
                        size={70}
                      />
                      <EchelonRow
                        echelonSrc={pictoCritere}
                        echelonText="Ou bien créer des critères personnalisés pour chaque échelon :"
                        onClick={() => {
                          setShowEchelon(true);
                        }}
                        buttonClassName={classes.createBadgeVisualButton}
                        size={92}
                        children="Créer des critères pour chaque échelon"
                        className={classes.echelonClassName}
                      />
                    </>
                  )}
                </div>
              ),
            },
          ]}
        />
      )}

      {/* TOD0: no button shown when no echelons have criterias OR extendedCriteria is false */}
      {!echelons.length && simple ? (
        <Button
          disabled={fetching}
          type="submit"
          className={classNames('continue_button', classes.submitButton)}
          max
          size="large"
        >
          Finaliser mon Open Badge
        </Button>
      ) : (
        <Button
          disabled={fetching}
          type="submit"
          className="continue_button"
          max
          size="large"
          onClick={checkForSubmit as any}
        >
          {step === 0 ? 'Continuer' : 'Finaliser mon Open Badge'}
        </Button>
      )}
      <Modal
        modalClassNames="modal_classNames"
        isClosed={false}
        open={!!openModal}
        handleClose={() => onCloseModal && onCloseModal()}
      >
        <div className="create_modal_container">
          <img src={logoCheck} alt="" />
          <Title title="Bravo !" className="title_modal" />
          <p className="text_description_modal">
            {!echelons.length
              ? "Votre nouvel Open Badge a bien été créé. Vous pouvez le retrouver dans votre profil ou dans le catalogue d'Open Badges."
              : "Votre nouvel Open Badge à échelons a bien été créé. Vous pouvez le retrouver dans votre profil ou dans le catalogue d'Open Badges."}
          </p>
          <Link to="/profile" className="link_submit_button">
            <Button size="large" type="submit">
              Aller à mon profil
            </Button>
          </Link>
        </div>
      </Modal>
    </form>
  );
};

export default CreateBadgeCriteria;
