import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    badgeVisualInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    button: {
      width: 330,
      fontWeight: 'bold',
      fontSize: 20,
      background: theme.palette.success.dark,
      color: '#fff',

      '&:disabled': {
        backgroundColor: theme.palette.success.main,
        color: '#fff',
      },
      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    buttonAddVisual: {
      marginRight: 25,
      width: 342,
    },
    buttonContainer: {
      display: 'flex',
      width: '100%',
      marginTop: 80,
      justifyContent: 'center',
      marginBottom: 95,
    },
    visualText: {
      width: 409,
      lineHeight: '147.5%',
      color: theme.palette.grey.A400,
      fontSize: 16,
      margin: 0,
    },
    importVisualContainer: {
      marginLeft: 25,
    },
    visualImage: {
      marginLeft: 20,
    },
    errorText: {
      bottom: 'auto',
      top: 'calc(100% + 10px)',
      left: '50%',
    },
    fileDeleteContainer: {
      display: 'flex',
      width: '100%',
      marginTop: 17,
      marginBottom: 30,
    },
    fileDeleteRoot: {
      border: '1px solid #00C4D1',
      padding: '11px 20px',
      paddingLeft: 33,
      borderRadius: 10,
      display: 'flex',
      minWidth: 342,
    },
    fileContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      fontSize: 20,
      color: theme.palette.primary.main,
    },
    deleteButton: {
      color: theme.palette.error.main,
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    deleteText: {
      marginRight: 10,
    },
    fileText: {
      margin: 0,

      marginLeft: 18,
      color: theme.palette.grey.A400,
      fontSize: 20,
    },
    createVisual: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    badgeValueSrc: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #00C4D1',
      padding: '5px 15px',
      borderRadius: 10,
      minWidth: 342,
    },
    imageVisual: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 16,
    },
  }),
);
