import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    featureFormSelectRoot: {
      display: 'grid',
      gridTemplateAreas: '"text empty" "title empty" "description description" "key buttonEchelon" ',
      gridGap: '31px 84px',
      gridTemplateColumns: '1fr 1fr',
      width: '100%',
    },
    featureFormSelectPadding: {
      padding: '10px 0px',
    },
    title: {
      gridArea: 'title',
    },

    description: {
      gridArea: 'description',
    },

    key: {
      gridArea: 'key',
      marginBottom: 20,
    },

    buttonEchelon: {
      gridArea: 'buttonEchelon',
      alignSelf: 'flex-end',
      marginBottom: 20,
      width: 232,
      background: theme.palette.success.dark,
      padding: '0px !important',
      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    imageNewEchelon: {
      marginRight: 41,
    },

    titleNewEchelonContainer: {
      gridArea: 'text',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 17,
      marginTop: 55,
    },
    newEchelonTitle: {
      fontWeight: 'normal',
    },
  }),
);
