import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    componentsInputsBadgeModelInput: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      height: '100%',
      gridGap: '10px 60px',
      alignItems: 'stretch',
      maxWidth: 980,
    },
    componentSvgContainer: {
      display: 'flex',
      flexDirection: 'column',
      gridColumn: '1/1',
    },
    badgeModelInputSvgContainer: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
      border: '1px solid #E3E5FF',
      borderRadius: '10px',
      padding: '20px',
      marginTop: 15,
      marginBottom: 10,
    },
    badgeModelInputSvgText: {
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: 14,
      cursor: 'pointer',
    },
    badgeModelInputChangeContainer: {
      gridColumn: '2/2',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
      overflow: 'hidden',
    },
    badgeModelColor: {
      height: 54,
      width: 54,
      marginLeft: 3,
      borderRadius: '50%',
      position: 'relative',
      cursor: 'pointer',
      '&:first-child': {
        marginLeft: 0,
        marginBottom: 12,

      },
    },
    badgeModelColors: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '15px 23px',
      background: theme.palette.secondary.light,
      justifyContent: 'space-between',
      borderRadius: 5,
    },

    colorPaddingContainer: {
      padding: '29px 36px',
    },

    changeItem: {
      marginTop: 56,
      flex: 1,
    },
    badgeModelText: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    iconsContainer: {
      flex: '1 1 auto',
      marginTop: 40,
    },
    textBadgeModel: {
      margin: '15px 0px 30px 0',
      fontSize: 16,
      fontWeight: 'normal',
    },
  }),
);
