import { useReducer, useCallback } from 'react';
import createRedux, { AnyAction } from 'utils/createRedux';
import { Response } from 'requests/http';

export type ReturnPromiseType<T> = T extends (...args: any[]) => Promise<Response<infer ReturnType>> ? ReturnType : any;

// / TODO UPDATE RESPONSE TO PROJECT API
interface IApiState<T> {
  fetching: boolean;
  errorCode: string;
  data: T | null;
  errors: null | { [key: string]: string };
}

export default function useApiState<Fn extends(...args: any[]) => Promise<Response<T>>, T = ReturnPromiseType<Fn>>(
  /* eslint-disable max-len */
  fn: Fn): [IApiState<T>, (...params: Parameters<Fn>) => Promise<void>, () => void, (p?: Partial<IApiState<T>>) => void] {
  /* eslint-disable max-len */
  let canceled = false;

  const INITIAL_STATE: IApiState<T> = {
    fetching: false,
    errorCode: '',
    data: null,
    errors: null,
  };

  const fetching = (state: IApiState<T>) => ({
    ...state,
    fetching: true,
    errorCode: '',
    errors: null,
  });
  const success = (state: IApiState<T>, { data }: AnyAction) => ({
    ...state,
    data,
    fetching: false,
  });
  const failure = (state: IApiState<T>, { errorCode, errors }: AnyAction) => ({
    ...state,
    errorCode: errorCode || '',
    errors: errors || null,
    fetching: false,
  });

  const resetAction = (state: IApiState<T>, { type, ...rest }: AnyAction) => ({
    ...INITIAL_STATE,
    ...rest,
  });

  const { actions, reducer } = createRedux(INITIAL_STATE, {
    fetching,
    success,
    failure,
    resetAction,
  });

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  function cancel() {
    canceled = true;
  }

  function* callGen(...params: Parameters<Fn>) {
    try {
      yield dispatch(actions.fetching());

      const response = yield fn(...params);

      if (response.status === 'OK') {
        yield dispatch(actions.success(response));
      } else {
        yield dispatch(actions.failure(response));
      }
    } catch (e) {
      const errorCode = "Erreur inconnue, vérifiez votre connexion Internet ou essayez d'actualiser la page.";

      yield dispatch(
        actions.failure({
          errorCode,
        }),
      );
    }
  }

  function reset(p: Partial<IApiState<T>> = {}) {
    dispatch(actions.resetAction(p));
  }

  async function call(...params: Parameters<Fn>) {
    const iterator = callGen(...params);
    let r = iterator.next();
    try {
      while (!r.done && !canceled) {
        // eslint-disable-next-line
        r = iterator.next(await r.value);
      }
      if (canceled) {
        canceled = false;
      }
    } catch (e) {
      if (iterator.throw) iterator.throw(e);
    }
  }

  return [state, useCallback(call, [fn]), useCallback(cancel, [fn]), useCallback(reset, [fn])];
}
