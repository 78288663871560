import React from 'react';
import classNames from 'utils/classNames';

import useStyles from './styles';

interface Props {
  title?: React.ReactChild | React.ReactChild[];
  color?: string;
  size?: number;
  font?: string;
  className?: string;
  imageContainerClassName?: string;
  row?: boolean;
  rowColor?: string;
}

const Title = ({ title, color, size, font, className, imageContainerClassName, row, rowColor }: Props) => {
  const classes = useStyles({
    color,
    size,
    font,
  });
  return (
    <div className={classes.titleContainer}>
      {row && (
        <div className={classNames(classes.imageContainer, imageContainerClassName)}>
          <div className={classNames(classes.row1, rowColor)} />
          <div className={classNames(classes.row2, rowColor)} />
          <div className={classNames(classes.row3, rowColor)} />
          <div className={classNames(classes.row4, rowColor)} />
          <div className={classNames(classes.row5, rowColor)} />
          <div className={classNames(classes.row6, rowColor)} />
        </div>
      )}
      <h1 className={classNames(classes.title, className)}>{title}</h1>
    </div>
  );
};
export default Title;
