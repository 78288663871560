import React, { useRef, useState, useEffect } from 'react';
import isNan from 'lodash/isNaN';
import moment from 'moment';
import Transition from 'react-transition-group/Transition';
import Card from '@material-ui/core/Card/Card';
import CalendarIcon from 'assets/svg/blue_calendar.svg';
import classNames from 'utils/classNames';
import useOnClickOutside from 'hooks/useOnclickOutside';
import Calendar from '../ui/Calendar';
import cancel from 'assets/images/cancel.png';
import cheked from 'assets/svg/checked.svg';
import './scss/datePicker.scss';
import useStyles from './styles';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  placeholder: string;
  defaultValue?: string;
  dateMax?: string;
  dateMin?: string;
  required?: boolean;
  error?: string;
  isChecked?: boolean;
  dataPickerClassName?: string;
  classes: {
    wrapper?: string;
    label?: string;
    select?: string;
    selectError?: string;
    placeholder?: string;
    input?: string;
  };
  optionnalText?: boolean;
}

const dateParts = [
  {
    name: 'day',
    placeholder: 'JJ',
    maxLength: 2,
    className: 'day_part',
    mask: (v: string, selection: number) => {
      let value = v;
      if (value.length > 2 && selection < 3) {
        value = value.slice(0, 2);
      }

      if (Number(value) > 31 || value.length > 2 || (value.length === 2 && !Number(value))) return '';
      if (value.length === 1 && Number(value) < 10 && Number(value) > 3) return `0${value}`;
      return value;
    },
  },
  {
    name: 'month',
    placeholder: 'MM',
    className: 'month_part',
    maxLength: 2,
    mask: (v: string, selection: number) => {
      let value = v;
      if (value.length > 2 && selection < 3) {
        value = value.slice(0, 2);
      }
      if (Number(value) > 12 || value.length > 2 || (value.length === 2 && !Number(value))) return '';
      if (value.length === 1 && Number(value) < 10 && Number(value) > 1) return `0${value}`;
      return value;
    },
  },
  {
    name: 'year',
    placeholder: 'YYYY',
    maxLength: 4,
    className: 'year_part',
    mask: (v: string, selection: number) => {
      let value = v;
      if (value.length > 4 && selection < 4) {
        value = value.slice(0, 4);
      }
      if (value.length > 4 || Number(value[0]) > 2 || (value.length === 4 && !Number(value))) return '';
      return value;
    },
  },
];

const InputDate = ({
  label,
  classes,
  onFocus,
  value,
  defaultValue,
  onChange,
  dateMax,
  dateMin,
  required,
  error,
  isChecked,
  dataPickerClassName,
  optionnalText,
  ...other
}: Props) => {
  const initialValue = ['', '', ''];
  if (value || defaultValue) {
    const dateSplit = moment(value || defaultValue)
      .format('DD/MM/YYYY')
      .split('/');
    for (let i = 0; i < 3; i += 1) {
      if (dateSplit[i]) {
        initialValue[i] = dateSplit[i];
      }
    }
  }

  const [open, setOpen] = useState(false);
  const [dateConstructor, setDateConstructor] = useState(initialValue);
  const inputRefs = useRef([] as HTMLInputElement[]);
  const [unControlledValue, setUnControlledValue] = useState(defaultValue || '');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const inputValue = onChange ? value : unControlledValue;

  function isValidParts(parts: string[]) {
    return parts[0].length === 2 && parts[1].length === 2 && parts[2].length === 4;
  }

  function onDateChange(nextValue: string) {
    const input = inputRef.current;
    if (input) {
      const event = new Event('input', { bubbles: true });
      const lastValue = input.value;
      input.value = nextValue;
      const tracker: any = (input as any)._valueTracker;
      if (tracker) {
        tracker.setValue(lastValue);
      }
      input.dispatchEvent(event);
    }
  }

  useEffect(() => {
    if (isValidParts(dateConstructor)) {
      const date = moment(dateConstructor.join('/'), 'DD/MM/YYYY');
      if (date.isValid()) {
        const nextDate = date.format('YYYY-MM-DD');
        if (nextDate !== inputValue) onDateChange(nextDate);
      }
    } else {
      onDateChange('');
    }
    // eslint-disable-next-line
  }, [dateConstructor]);

  useEffect(() => {
    const current = value || unControlledValue;
    if (current) {
      const nextParts = moment(current).format('DD/MM/YYYY').split('/');
      const isDiffParts = nextParts.find((p, i) => p !== dateConstructor[i]);
      if (isDiffParts) {
        setDateConstructor(nextParts);
      }
    }
    // eslint-disable-next-line
  }, [value, unControlledValue]);

  function changeDateConstructor(parts: string[]) {
    if (isValidParts(parts)) {
      const date = moment(parts.join('/'), 'DD/MM/YYYY').format('YYYY-MM-DD');
      if (
        (!dateMax || moment(date).diff(moment(dateMax)) <= 0) &&
        (!dateMin || moment(date).diff(moment(dateMin)) >= 0)
      ) {
        setDateConstructor(parts);
      }
    } else {
      setDateConstructor(parts);
    }
  }

  function focus(e: React.FocusEvent<HTMLInputElement>) {
    setOpen(true);
    if (onFocus) onFocus(e);
  }

  function onDateFocus() {
    inputRefs.current[0]?.focus();
    setOpen(true);
  }

  function handlePartChange(index: number, callback?: any) {
    return function (e: React.ChangeEvent<HTMLInputElement>) {
      e.preventDefault();
      const section = inputRefs.current[index].selectionEnd;

      let nextValue = e.target.value.trim();

      if (nextValue && callback) {
        nextValue = callback(nextValue, section);
      }

      if (nextValue || !e.target.value) {
        changeDateConstructor(dateConstructor.map((d, i) => (i === index ? nextValue : d)));
      }
    };
  }

  function handleKeyDown(index: number) {
    return function (e: React.KeyboardEvent<HTMLInputElement>) {
      const input = inputRefs.current[index];

      if (
        (!isNan(Number(e.key)) &&
          dateConstructor[index] &&
          dateConstructor[index].length === 2 &&
          input.selectionEnd === 2) ||
        (e.key === 'ArrowRight' && input && input.selectionEnd === dateConstructor[index].length)
      ) {
        // eslint-disable-next-line
        inputRefs.current[index + 1]?.focus();
      } else if ((e.key === 'Backspace' || e.key === 'ArrowLeft') && input && input.selectionEnd === 0) {
        // eslint-disable-next-line
        inputRefs.current[index - 1]?.focus();
      } else if (isNan(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') {
        e.preventDefault();
      }
    };
  }

  function handleBlur(index: number) {
    return function () {
      if (Number(dateConstructor[index]) && dateConstructor[index].length < 2) {
        changeDateConstructor(dateConstructor.map((d, i) => (i === index ? `0${d}` : d)));
      }
    };
  }

  function handleInputRef(index: number) {
    return function (ref: HTMLInputElement) {
      inputRefs.current[index] = ref;
    };
  }

  useOnClickOutside(containerRef, () => {
    setOpen(false);
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(e);
    } else {
      setUnControlledValue(e.target.value);
    }
  }

  const datePartsInputs = dateParts.map(({ name, mask, className, maxLength, ...rest }, index) => (
    <input
      value={dateConstructor[index]}
      onChange={handlePartChange(index, mask)}
      onKeyDown={handleKeyDown(index)}
      ref={handleInputRef(index)}
      onFocus={focus}
      key={name}
      onBlur={maxLength === 2 ? handleBlur(index) : undefined}
      {...rest}
      className={classNames('date_picker_input_part', className)}
      size={maxLength}
    />
  ));

  const styles = useStyles();

  return (
    <div
      style={{ marginBottom: optionnalText ? 50 : '' }}
      className={classNames('components_inputs_date_picker flex_column', classes.wrapper)}
    >
      <div className={classes.label}>
        {label}
        {required ? <span>*</span> : null}
      </div>
      <div>
        <div
          className={classNames(
            'date_picker_input_container',
            classes.select,
            !!error && 'date_picker_input_container_error',
            dataPickerClassName,
          )}
          ref={containerRef}
        >
          {datePartsInputs[0]}
          <span>/</span>
          {datePartsInputs[1]}
          <span>/</span>
          {datePartsInputs[2]}
          <input
            className={classNames('date_picker_hidden_input')}
            onFocus={focus}
            value={value}
            onChange={handleChange}
            ref={inputRef}
            type="date"
            {...other}
          />

          <Transition timeout={300} in={open} mountOnEnter unmountOnExit>
            {(state) => (
              <Card
                className={classNames(
                  'date_picker_options_container flex_column',
                  state === 'entered' && 'date_picker_open_options_container',
                )}
              >
                <Calendar dateMax={dateMax} dateMin={dateMin} onValueChange={onDateChange} value={inputValue} />
              </Card>
            )}
          </Transition>
          <div className={classNames('date_picker_icon')}>
            <img src={CalendarIcon} alt="calendar" onClick={onDateFocus} height="60%" />
          </div>
        </div>
        {optionnalText && (
          <div className={styles.errorContainer}>
            <span className={styles.errorMessage} style={{ color: 'rgb(150,150,150)', fontSize: 14 }}>
              Optionnel
            </span>
          </div>
        )}
      </div>
      <div>
        {error && isChecked && <img src={cancel} alt="" />}
        {value && !error && isChecked && <img src={cheked} alt="" />}
      </div>
    </div>
  );
};

InputDate.defaultProps = {
  classes: {},
  placeholder: '',
  isChecked: true,
};

export default InputDate;
