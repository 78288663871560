import React from 'react';
import SelectBase, { SelectProps } from '@material-ui/core/Select/Select';

import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';

import ErrorText from 'components/inputs/ErrorText';
import Label from 'components/inputs/Label';
import classNames from 'utils/classNames';
import arrow from 'assets/svg/arrowSelect.svg';

import './scss/select.scss';

export interface Option {
  value: string;
  label: string;
  icon?: any;
}

interface Props extends Omit<SelectProps, 'variant'> {
  label?: string;
  options: Option[];
  errorText?: string;
  required?: boolean;
  selectInputClassName?: string;
  inputClassName?: string;
  requiredClassName?: string;
  itemClassName?:string;
}
const Select = ({
  label,
  className,
  classes: c,
  options,
  errorText,
  required,
  selectInputClassName,
  inputClassName,
  requiredClassName,
  itemClassName,
  ...rest
}: Props) => {
  const classes = c || {};

  const renderOption = (option: Option) => {
    if (option.icon)
      return (
        <MenuItem className={classNames('select_menu_item_container',itemClassName)}  key={option.value} value={option.value}>
          <ListItemIcon>{option.icon}</ListItemIcon>
          {option.label}
        </MenuItem>
      );
    return (
      <MenuItem className={classNames('select_menu_item_container',itemClassName)}  key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  };
  return (
    <div
      className={classNames(
        'components_inputs_select',
        errorText !== undefined && 'components_inputs_input_with_error',
        className,
      )}
    >
      <Label requiredClassName={requiredClassName} required={required}>
        {label}
      </Label>
      <SelectBase
        placeholder="Sélectionner"
        {...rest}
        MenuProps={{ PaperProps: { style: { border: '1px solid #5B78C2', fontSize: '20px', color: '#434343' } } }}
        classes={{
          selectMenu: classNames('select_input_menu', classes.selectMenu, inputClassName),
          icon: classNames('select_input_icon', classes.icon),
          disabled: 'select_background_disabled',
          ...classes,
        }}
        className={classNames(
          'select_input',
          !!errorText && 'components_inputs_select_with_error',
          selectInputClassName,
        )}
        variant="outlined"
        error={!!errorText}
        IconComponent={() => <img src={arrow} className={'image_container'} alt="" />}
      >
        <MenuItem style={{ fontSize: '20px' }} disabled value="">
          Choisir...
        </MenuItem>
        {options.map(renderOption)}
      </SelectBase>
      {errorText !== undefined && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
};

export default Select;
