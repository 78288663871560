import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card_large_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
  },
  wrapper_card: {
    display: 'flex',
    position: 'relative',
    minHeight: 200,
    cursor: 'pointer',
  },
  card_large_container: {
    padding: '25px 19px 25px 30px',
    display: 'flex',
    width: '75%',
  },
  image_container_large: {
    marginRight: '24px',
    maxWidth: '100%',
    width: 140,
    height: 'auto',
    maxHeight: '100%',
    borderRadius: '5px',
    alignSelf: 'center',
  },
  badge_content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    fontSize: 12,
    lineHeight: '0.8px',
  },
  badge_title: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    color: theme.palette.grey.A400,
  },
  badge_description: {
    marginTop: '10px',
    marginRight: 187,
    fontSize: 14,
    lineHeight: 1,
  },
  descriptionText: {
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.grey.A400,
  },
  badge_issuer: {
    alignSelf: 'flex-end',
    marginTop: 30,
    fontSize: 12,
    paddingLeft: 15,
  },
  badge_code: {
    alignSelf: 'flex-end',
    marginTop: 20,
    fontSize: 12,
    paddingLeft: 15,
  },
  badge_date: {
    marginTop: 20,
    paddingLeft: 15,
  },
  badge_content_footer: {
    marginTop: 7,
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagContainer: {
    background: '#FFD1E2',
    borderRadius: '10px',
    height: 31,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    padding: '0px 10px',
    marginRight: 15,
    marginBottom: 8,
  },
  tagTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FF5C97',
    marginTop: 0,
    marginBottom: 2,
  },

  categories_container: {
    background: '#eefbff',
    borderRadius: '0px 10px 10px 0px',
    paddingTop: '0px',
    paddingLeft: '15px',
    height: 'auto',
    width: '25%',
  },
  logo_categories: {
    display: 'flex',
    height: 35,
    paddingTop: 15,
    paddingLeft: 15,
  },
  logo_categorie_header: {
    marginRight: 6,
  },
  title_emetteur: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '23px',
    color: theme.palette.grey.A400,
    marginTop: 10,
    marginBottom: 0,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '22px',
    color: '#FF5C97',
    marginTop: 0,
    marginBottom: 0,
  },

  children_container: {
    padding: '0 25px',
  },
  display_none: {
    display: 'none',
  },
  title_critere: {
    fontFamily: 'Rakkas',
    fontSize: '25px',
    lineHeight: '24px',
    color: theme.palette.primary.main,
  },
  listCritere: {
    paddingBottom: 20,
  },
  borderCritere: {
    borderBottom: '1px solid #DDF7FF',
  },
  circleCritere: {
    background: '#DDF7FF',
    width: 27,
    height: 27,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 17,
  },
  critere_title_container: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 0px',
  },
  critere_title: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px ',
    color: theme.palette.primary.main,
    marginTop: 0,
    marginBottom: 0,
  },
  critere_description: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.grey.A400,
    width: '90%',
  },
  criterContainer: {
    flex: 1,
    display: 'flex',
  },
  critereWrapper: { width: '100%' },
  index_critere: {
    fontSize: 25,
    lineHeight: '24px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: 'Rakkas',
  },
  prevCritere: {
    height: '100%',
    width: 140,
  },
  prevCritereText: {
    color: theme.palette.grey.A400,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '28px',
    marginTop: 0,
    marginBottom: 3,
  },
  prevCritereImgContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  marginImg: {
    marginLeft: 4,
  },
  border: {
    borderBottom: '2px solid #DDF7FF',
  },
  imageUserCandidateur: {
    width: 60,
    height: 60,
    display: "inline-block"
  },
  img: {
    width: '100%',
    height: '100%',
  },
  demandeList: {
    padding: '0px 0px 30px 0px',
  },
  flexCandidature: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
    gap: "15px"
  },
  candidatureItem: {
    justifyContent: 'center',
    padding: '20px 0px',
    textAlign: "center"
  },
  text_candidature: {
    borderRadius: 10,
    background: '#eefbff',
    padding: '13px 10px',
    marginTop: 5,
    minHeight: 99,
  },
  detenteurContainer: {
    borderBottom: '2px solid #DDF7FF',
  },
  listDetentteur: {
    paddingBottom: 50,
  },
  itemDetentteur: {
    display: ' flex',
    padding: '5px 0px',
  },
  userAge: {
    marginLeft: 30,
  },
  modifContainer: {
    background: '#d1fdf1',
    borderRadius: '0px 0px 10px 10px',
    position: 'relative',
    height: 134,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  create_badges_button: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginTop: 15
  },
  refuser_badges_button: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  textBtn: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  textBtnRefuser: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#fff',
  },
  smallWeight: {
    fontWeight: 200,
  },
  awardBadge: {
    backgroundColor: '#00C4D1',
    height: '50px',
  },
}));
