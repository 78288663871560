import React, { useEffect } from 'react';
import { useForm } from 'hooks/useInputs';
import classNames from 'utils/classNames';

import TextInput from 'components/inputs/TextInput';
import OptionForm from './OptionForm/OptionForm';

import Select from 'components/inputs/Select';
import TextError from 'components/inputs/ErrorText';
import Title from 'components/common/Title/Title';
import questionCriteria from 'assets/svg/picto-critere-question.svg';
import { useDidUpdate } from 'hooks/useLifeCycle';
import useKeys from 'hooks/useKeys';

import './scss/criteriaForm.scss';

export interface QuestionnaireValues {
  answer?: string | string[];
  question: string;
  type: string;
  options?: string[];
}

function getInitialOption(options?: string[]) {
  if (!options) return [''];
  if (options[options.length - 1]) return [...options, ''];
  return options;
}

interface Props extends Partial<QuestionnaireValues> {
  onSubmit: (values: QuestionnaireValues) => void;
  addNewQuestion?: () => void;
  done?: boolean;
  resetOnSubmit?: boolean;
  resetOnClose?: boolean;
  titleNumber?: number;
  onToggle?: () => void;
  isSubmit?: boolean;
  addQuestion?: () => void;
  onSubmitDone?: () => void;
}

const BadgeQuestionnaire = ({
  onSubmit,
  onSubmitDone,
  answer,
  question,
  type,
  options,
  isSubmit,
  titleNumber,
}: Props) => {
  const [state, actions] = useForm({
    initialValues: {
      question: question || '',
      answer: answer || '',
      type: type || '',
      options: getInitialOption(options),
    },
    required: ['question', 'type', 'answer'],
    validation: {
      options: (o, values) => {
        if (values.type === 'text') return '';
        else if (!o.join('').trim()) return 'Il faut ajouter au moins une option';
      },
    },
  });

  const { values, errors, touched } = state;
  const { handleChange, validateForm, setAllTouched, setValues, setErrors, setTouched } = actions;
  const [optionKeys, addOptionsKey, deleteOptionsKey] = useKeys(values.options);

  useDidUpdate(() => {
    if (values.type !== 'multiple') {
      setValues({ answer: '' });
    } else {
      setValues({ answer: [] });
    }

    if (values.type !== 'text') {
      setErrors({
        options: !values.options.join('').trim() ? 'Il faut ajouter au moins une option' : '',
      });
    } else {
      setErrors({ options: '' });
    }

    // eslint-disable-next-line
  }, [values.type]);

  useDidUpdate(() => {
    if (typeof values.answer !== 'string') {
      setValues({
        answer: values.answer.filter((ans) => values.options.find((option) => option === ans)),
      });
    } else if (!values.options.find((option) => option === values.answer)) {
      setValues({
        answer: '',
      });
    }
    // eslint-disable-next-line
  }, [values.options]);

  function handleSubmit() {
    if (validateForm()) {
      const questionsValues = { ...values };
      if (questionsValues.type === 'text') {
        delete questionsValues.options;
      } else {
        questionsValues.options = questionsValues.options.filter((q) => q);
      }

      if (!questionsValues.answer) {
        delete questionsValues.answer;
      }

      onSubmit(questionsValues);
    } else {
      setAllTouched(true);
    }
  }

  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
      onSubmitDone && onSubmitDone();
    }
    // eslint-disable-next-line
  }, [isSubmit]);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const nextOptionTab = [...state.values.options];
    nextOptionTab[index] = e.target.value;
    if (!touched.options) {
      setTouched({ options: true });
    }
    setValues({ options: nextOptionTab });
  };

  const addOption = () => {
    setValues({ options: [...state.values.options, ''] });
    addOptionsKey();
    if (!state.values.options) {
      setTouched({ options: true });
    } else {
      setTouched({ options: false });
    }
  };

  const deleteOption = (index: number) => {
    const nextOptions = values.options.filter((c, i) => i !== index);
    deleteOptionsKey(index);
    if (!nextOptions.find((e) => e === values.answer)) {
      setValues({ options: nextOptions, answer: '' });
    } else {
      setValues({ options: nextOptions });
    }
  };

  const renderAnswer = () => {
    if (values.type === 'multiple') {
      return (
        <Select
          required
          name="answer"
          value={typeof values.answer === 'string' ? [] : values.answer}
          onChange={handleChange}
          className="row_input"
          label="Quelle est la bonne réponse ?"
          multiple
          errorText={touched.answer && errors.answer}
          options={values.options.filter((o) => o).map((option) => ({ value: option, label: option }))}
          disabled={state.values.options.length <= 1}
        />
      );
    }
    if (values.type === 'select') {
      return (
        <Select
          required
          name="answer"
          value={typeof values.answer === 'string' ? values.answer : ''}
          onChange={handleChange}
          className="row_input"
          label="Quelle est la bonne réponse ?"
          errorText={touched.answer && errors.answer}
          options={values.options.filter((o) => o).map((option) => ({ value: option, label: option }))}
          disabled={state.values.options.length <= 1}
          requiredClassName="required_className"
        />
      );
    }
    return (
      <TextInput
        required
        name="answer"
        value={typeof values.answer === 'string' ? values.answer : ''}
        onChange={handleChange}
        className="row_input"
        classes={{ root: 'background_white' }}
        label="Quelle est la bonne réponse ?"
        errorText={touched.answer && errors.answer}
        requiredClassName="required_className"
      />
    );
  };

  return (
    <div className={classNames('components_criteria_form', 'components_question_form')}>
      <div className="criteria_form_root">
        <div className="title_criteria_form_container">
          <img src={questionCriteria} alt="" />
          <Title title={`Question ${titleNumber}`} className={'title_criteria_form'} />
        </div>
        <div className="content_container flex_column question_content_container">
          <div className="flex_column">
            <TextInput
              required
              name="question"
              value={values.question}
              onChange={handleChange}
              label="Quelle est la question ?"
              errorText={touched.question && errors.question}
              requiredClassName="required_className"
              classes={{ root: 'height_input' }}
              placeholder="Tapez l’énoncé de la question..."
              textInputClassName="text_input_className"
            />
            <div className="first_row flex_row select_answer_container">
              <Select
                required
                name="type"
                className="row_input"
                value={values.type}
                onChange={(e) => {
                  setValues({ answer: '', type: e.target.value as any });
                }}
                label="Quel est le type de question ?"
                errorText={touched.type && errors.type}
                requiredClassName="required_className"
                options={[
                  { value: 'text', label: 'Texte (réponse libre)' },
                  { value: 'select', label: 'Select (une seule réponse possible)' },
                  { value: 'multiple', label: 'QCM (choix multiple)' },
                ]}
              />
            </div>

            {(values.type === 'select' || values.type === 'multiple') && (
              <div className=" first_row">
                {state.values.options.map((val, index) => (
                  // eslint-disable-next-line
                  <OptionForm
                    key={optionKeys[index]}
                    index={index}
                    val={val}
                    handleOptionChange={handleOptionChange}
                    addOption={addOption}
                    deleteOption={deleteOption}
                    optionValues={state.values.options}
                    onOptionAddFailed={() => {
                      setTouched({ options: true });
                      if (values.options.length >= 2) setErrors({ options: 'Champ vide' });
                    }}
                  />
                ))}
                <div className="flex_column error_option">
                  {touched.options && errors.options && <TextError className="error_text">{errors.options}</TextError>}
                </div>
              </div>
            )}
            <div className="render_answer_container">{renderAnswer()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgeQuestionnaire;
