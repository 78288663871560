import React, { useContext } from 'react';
import { mainRoutes, mainRoutesMobile } from 'layout/MainHeader/MainHeaderV2';
import classNames from 'utils/classNames';
import useStyles from './styles';
import useCreateUri from 'hooks/useCreateUri';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import userContext from 'contexts/userContext';
import localforage from 'localforage';
import { setAuthorizationBearer } from 'requests/http';

interface Props {
  mobile?: boolean;
  homePage?: boolean;
}

const MobileHeader = ({ mobile, homePage }: Props) => {
  const classes = useStyles();
  const { user, setUser } = useContext(userContext);
  const history = useHistory();
  const location = useLocation();

  const links = [
    {
      label: 'QU’EST CE QUE C’EST ?',
      path: { pathname: '/', search: useCreateUri(0) },
    },
    {
      label: 'OBTENIR DES OPEN BADGES',
      path: { pathname: '/', search: useCreateUri(1) },
    },
    {
      label: 'ÉMETTRE DES OPEN BADGES ',
      path: { pathname: '/', search: useCreateUri(2) },
    },
    /*     { label: 'INSCRIPTION / CONNEXION', path: '/login' },
     */
  ];

  return (
    <>
      {mobile &&
        (homePage ? (
          <div></div>
        ) : (
          <div className={classes.listBarMobile}>
            <div>
              {!user &&
                links.map((route, i) => {
                  return (
                    <Link style={{ height: '100%' }} to={route.path}>
                      <div key={i} className={classNames(classes.mobileLink)}>
                        {route.label}
                      </div>
                    </Link>
                  );
                })}
              {!user && (
                <Link style={{ height: '100%' }} to={'/register'}>
                  <div style={{ color: 'rgb(164, 252, 252)' }} className={classNames(classes.mobileLink)}>
                    INSCRIPTION
                  </div>
                </Link>
              )}
              {user &&
                mainRoutesMobile.map((route, i) => {
                  let selected = location.pathname === route.path;
                  if (!selected && route.selectedRoutes) {
                    selected = !!route.selectedRoutes.find((r) => matchPath(location.pathname, r));
                  }

                  return (
                    <Link style={{ height: '100%' }} to={route.path}>
                      <div key={i} className={classNames(classes.mobileLink, selected && classes.mobileLinkActive)}>
                        {route.label}
                      </div>
                    </Link>
                  );
                })}
              {user && (
                <div
                  onClick={() => {
                    localforage.clear();
                    setAuthorizationBearer(null);
                    setUser(null);
                    history.push('/');
                  }}
                  style={{ marginBottom: 15, cursor: 'pointer' }}
                  className={classes.mobileLink}
                >
                  DÉCONNEXION
                </div>
              )}
            </div>
          </div>
        ))}
    </>
  );
};

export default MobileHeader;
