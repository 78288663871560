import React, { useRef, useState, useEffect } from 'react';
import Text from 'components/ui/Text';
import Button from 'components/buttons/Button';
import Input from 'components/inputs/TextInput';
import QuestionCard from 'components/cards/QuestionCard/QuestionCard';
import CheckBox from 'components/inputs/CheckBox';

import { Question } from 'requests/badges';
import typeText from 'assets/svg/typeText.svg';
import typeImage from 'assets/svg/typeImage.svg';
import typeQuestion from 'assets/svg/typeQuestion.svg';
import typePDF from 'assets/svg/typePDF.svg';
import uploadImage from 'assets/svg/upload.svg';
import Close from 'assets/svg/Picto_Supprimer.svg';
import Modal from '@material-ui/core/Modal';

import useStyles from './styles';
import critereTypeToText from "../../../utils/critereTypeToText";

export type CriteriaResult =
  | {
      type: 'pdf';
      id: string;
      result: File;
    }
  | {
      type: 'image';
      id: string;
      result: File;
    }
  | {
      type: 'text';
      id: string;
      result: string;
    }
  | {
      type: 'code';
      id: string;
      result: string;
    }
  | {
      type: 'questionnaire';
      id: string;
      result: { answer?: string | string[]; type: string }[];
    };
interface Props {
  title: string;
  type: 'pdf' | 'text' | 'image' | 'questionnaire';
  description: string;
  candidature: boolean;
  question: Question[];
  id: string;
  index?: number;
  codeExist?: string;
  onchangeChecked: (i: string) => void;
  checkedCandi?: string;
  handleResult?: (result: {
    id: string;
    code?: string;
    text?: string;
    file?: File;
    fileImage?: File;
    question?: { answer: string | undefined | string[]; type: string; question: string }[];
  }) => void;
}

export const CritereCard = ({
  title,
  type,
  description,
  candidature,
  question,
  handleResult,
  id,
  index,
  codeExist,
  onchangeChecked,
  checkedCandi,
}: Props) => {
  const [code, setCode] = useState('');
  const [file, setFile] = useState({} as File);
  const [fileImage, setFileImage] = useState({} as File);
  const [text, setText] = useState('');
  const [questionsState, setQuestions] = useState<
    { answer: string | undefined | string[]; type: string; question: string }[]
  >([]);

  const handlechangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCode(value);
  };
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInput.current?.click();
  };
  const onchangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadToUpload = event.target.files && event.target.files[0];
    const test = uploadToUpload as File;
    setFile(test);
  };

  const fileInputImage = useRef<HTMLInputElement>(null);

  const handleClickImage = () => {
    fileInputImage.current?.click();
  };
  const onchangeInputImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadToUpload = event.target.files && event.target.files[0];
    const test = uploadToUpload as File;
    setFileImage(test);
  };

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  function handleQuestions(data: any) {
    const questionResult: any = [];
    question.map((el, i) => {
      questionResult.push({ answer: data[i], type: el.type, question: el.question });
      return questionResult;
    });
    setQuestions(questionResult);
  }

  const [openPopup, setOpenPopUp] = useState(false);
  const [isFormValidated, setFormValidated] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [displayErrorText, setDisplayErrorText] = useState(false);
  const [isFirstPush, setFirstPush] = useState(false);
  const handleCloseModal = () => {
    setOpenPopUp(false);
    setDisplayErrorText(false);
    setFirstPush(false);
    setQuestions([]);
  };

  const validateFormModal = () => {
    let isValidText = true;
    let isValidMultiple = true;
    let isValidSelect = true;
    const arrayValidation = [];
    for (let i = 0; i < questionsState.length; i += 1) {
      switch (questionsState[i].type) {
        case 'text':
          if (questionsState[i].answer !== '') {
            isValidText = true;
            arrayValidation.push(true);
          } else {
            isValidText = false;
            arrayValidation.push(false);
          }
          break;
        case 'select':
          if (questionsState[i].answer !== '') {
            isValidMultiple = true;
            arrayValidation.push(true);
          } else {
            isValidMultiple = false;
            arrayValidation.push(false);
          }
          break;
        case 'multiple':
          if (questionsState[i].answer?.length !== 0) {
            isValidSelect = true;
            arrayValidation.push(true);
          } else {
            isValidSelect = false;
            arrayValidation.push(false);
          }
          break;
        default:
      }
    }
    const test = isValidText && isValidMultiple && isValidSelect;
    if (!test) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
    // ;
  };

  useEffect(() => {
    validateFormModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsState]);

  useEffect(() => {
    setFormValidated(isEmpty);
  }, [isEmpty]);

  useEffect(() => {
    setDisplayErrorText(!isFormValidated);
  }, [isFormValidated]);
  useEffect(() => {
    handleResult?.call(null, {
      id,
      code,
      text,
      file,
      fileImage,
      question: questionsState,
    });
    // eslint-disable-next-line
  }, [file, fileImage, text, code, questionsState, id]);

  const handleResponsSubmit = () => {
    setFirstPush(true);
    if (isFormValidated) {
      setOpenPopUp(false);
      setFirstPush(false);
    } else {
      setOpenPopUp(true);
    }
  };
  const classes = useStyles();
  return (
    <div className={classes.critere_container}>
      <div className={classes.critere_details_container}>
        <div style={{ marginRight: 15 }} className={classes.critere_infos_container}>
          <div className={classes.critere_name_container}>

            {index && <div className={classes.critere_index}>{index}</div>}
            <Text label={title} className={classes.critere_name} />
          </div>
          <div className={classes.critere_description_container}>
            <Text label={description} className={classes.critere_description} />
          </div>
        </div>
        <div className={classes.critere_preuve_container}>
          <Text label="Preuve attendue :" className={classes.preuve_type_label} />
          <div className={classes.preuve_type_container}>
            {type === 'text' ? (
              <img src={typeText} alt="textLogo" />
            ) : type === 'image' ? (
              <img src={typeImage} alt="imgLogo" />
            ) : type === 'questionnaire' ? (
              <img src={typeQuestion} alt="imgLogo" />
            ) : (
              <img src={typePDF} alt="imgLogo" />
            )}
            <Text label={critereTypeToText(type)} className={classes.preuve_type} />
          </div>
          {candidature && type === 'questionnaire' && (
            <div>
              <Button
                className={classes.btn_response}
                disabled={checkedCandi !== 'critere'}
                onClick={() => {
                  setOpenPopUp(true);
                  window.scrollTo(0, 0);
                }}
              >
                <span className={classes.btn_response_label}>Répondre</span>
              </Button>
            </div>
          )}
        </div>
      </div>
      {candidature && (<div className={classes.add_critere_container}>
        {candidature && type === 'pdf' && (
          <div className={classes.critere_input_container}>
            <input
              type="file"
              className={classes.input_file}
              ref={fileInput}
              onChange={onchangeInput}
              accept="application/pdf"
              disabled={checkedCandi !== 'critere'}
            />
            <div className={classes.upload_image_container} onClick={handleClick}>
              <img src={uploadImage} alt="uploadImg" className={classes.upload_image_icon} />
              <div className={classes.upload_image_label}>Charger un fichier</div>
            </div>
            <div className={classes.accepted_img_format_container}>
              <div className={classes.accepted_img_format_label}>Format accepté : PDF</div>
            </div>
          </div>
        )}
        {candidature && type === 'image' && (
          <div className={classes.critere_input_container}>
            <input
              type="file"
              className={classes.input_file}
              ref={fileInputImage}
              onChange={onchangeInputImage}
              accept="image/jpeg, image/png"
              disabled={checkedCandi !== 'critere'}
            />
            <div className={classes.upload_image_container} onClick={handleClickImage}>
              <img src={uploadImage} alt="uploadImg" className={classes.upload_image_icon} />
              <div className={classes.upload_image_label}>Charger une image</div>
            </div>
            <div className={classes.accepted_img_format_container}>
              <div className={classes.accepted_img_format_label}>Format acceptés : Jpeg, PDF</div>
            </div>
          </div>
        )}

        {candidature && type === 'text' && (
          <div className={classes.critere_input_text}>
            <div className={classes.input_text_label}>Ecrivez votre réponse pour obtenir ce badge :</div>
            <Input
              label=""
              className={classes.input_text}
              rows={3}
              onChange={handleChangeText}
              multiline
              disabled={checkedCandi !== 'critere'}
            />
          </div>
        )}
      </div>)}
      <Modal open={openPopup}>
        <div className={classes.modalContainer}>
          <div className={classes.question_modal_content}>
            <div className={classes.close_container}>
              <img
                width={20}
                src={Close}
                onClick={handleCloseModal}
                className={classes.question_popup_close}
                alt="Delete"
              />
            </div>
            <div className={classes.wrapperInfo}>
              <div className={classes.criteria_title_container}>
                <Text label={title} className={classes.criteria_title} />
              </div>
              <div className={classes.critere_input_questionnaire}>
                <QuestionCard question={question} handleQuestions={handleQuestions} />
              </div>
              {isFirstPush && displayErrorText && question.length > 1 && (
                <div className={classes.errorMessage}>Veuillez répondre à toutes les questions</div>
              )}
              {isFirstPush && displayErrorText && question.length === 1 && (
                <div className={classes.errorMessage}>Veuillez répondre à la question</div>
              )}
              <div className={classes.textRequired}>
                <span className={classes.requiredMark}>* </span>
                {question.length === 1 ? <span>champ obligatoire </span> : <span>champs obligatoires </span>}
              </div>
              <div className={classes.validation_btn_container}>
                <Button size="large" onClick={handleResponsSubmit} type="submit">
                  Valider mes réponses
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CritereCard;
