import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  rating_component_container: {
    display: 'flex',
    backgroundColor: 'white',
    width: 489,
    height: 231,
    borderRadius: 10,
    padding: 25,
  },
  graphContainer: {
    width: 145,
    marginRight: 44,
  },
  rating_card_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  sub_title_component: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: '#FF5C97',
    textAlign: 'center',
  },
  font: {
    fontFamily: 'Rakkas',
  },
}));
