import React, { useContext, useState, useCallback } from 'react';

import { Redirect, Route as BaseRoute, RouteProps } from 'react-router-dom';

import navigationContext from 'contexts/navigationContext';

import userContext from 'contexts/userContext';
import Header from '../PrivateHeader/PrivateHeader';
import PublicHeader from '../PublicHeader/PublicHeader';

import SideBar from '../SideBar';
import Footer from '../Footer/Footer';
import SecondFooter from '../SecondFooter/SecondFooter';

import classNames from '../../utils/classNames';
import { encodeUri } from '../../utils/url';
import useStyles from './styles';

interface Props extends RouteProps {
  header?: boolean;
  protected?: boolean;
  addFooter?: boolean;
  addSideBar?: boolean;
  homePage?: boolean;
  headerProps?: any;
  headerType?: 'public' | 'private';
  showMobile?: boolean;
}

const Route = ({
  protected: protectedProp,
  addFooter,
  headerProps,
  addSideBar,
  path,
  header,
  homePage,
  headerType,
  showMobile,
  ...props
}: Props) => {
  const { user } = useContext(userContext);

  const [lastContextPath, setLastContextPath] = useState(path);
  const [contextHeaderProps, setContextHeaderProps] = useState(null);
  const classes = useStyles({ protectedProp, homePage });

  const contextChange = useCallback(
    (newContext: any) => {
      setContextHeaderProps(newContext);
      setLastContextPath(path);
    },
    [path],
  );

  if (!user && protectedProp) {
    return <Redirect to={`/login${encodeUri({ from: window.location.pathname + window.location.search })}`} />;
  }
  if (!user?.active && protectedProp) {
    return <Redirect to="/" />;
  }

  const component = (
    <div className={classNames(classes.container, (addFooter || headerProps || addSideBar) && classes.layout)}>
      <BaseRoute path={path} {...props} />
    </div>
  );

  function renderHeader(components: { public: JSX.Element; private: JSX.Element }) {
    if (!header) return null;
    if (headerType) {
      return headerType === 'public' ? components.public : components.private;
    }
    return protectedProp && user ? components.private : components.public;
  }
  return (
    <navigationContext.Provider value={{ setHeaderProps: contextChange }}>
      {renderHeader({
        private: (
          <Header
            mobile={showMobile}
            context={lastContextPath === path ? contextHeaderProps : null}
            {...headerProps}
            protected={protectedProp}
          />
        ),
        public: <PublicHeader homePage={homePage} {...headerProps} />,
      })}

      {addSideBar && <SideBar />}
      <div className={classNames(classes.page, classes.column)}>{component}</div>
      <SecondFooter />

      {addFooter && <Footer />}
    </navigationContext.Provider>
  );
};

Route.defaultProps = {
  headerProps: {},
  header: true,
  addFooter: true,
};

export default Route;
