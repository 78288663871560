import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const HEADER_HEIGHT = 91;

export default makeStyles<Theme>((theme: Theme) =>
  createStyles({
    MenuRoot: {
      position: 'relative',
      cursor: 'pointer',
    },

    headerFirstName: {
      position: 'relative',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '50%',
      width: 42,
      height: 42,
      fontSize: 30,
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      fontFamily: 'Rakkas',
      display: 'flex',
      alignItems: 'center !important',
      justifyContent: 'center',
    },
    backgroundMenu: {
      background: '#5b78c2',
      borderRadius: '50%',
      width: 50,
      height: 50,
      position: 'absolute',
      left: -4,
      top: -4,
      zIndex: -1,
    },
    userNameContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px solid rgba(123, 91, 194,0.75)',
      padding: '20px 30px',
    },
    userName: {
      color: theme.palette.primary.main,
      fontSize: 25,
      fontFamily: 'Rakkas',
      marginLeft: 23,
    },
    menuProfil: {
      padding: '20px 30px',
      color: theme.palette.grey.A400,
      cursor: 'pointer',
      fontSize: 20,
    },
    notifPanel: {
      marginTop: 31,
      display: 'flex',
    },
    logout: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 31,
    },
    logoutText: {
      marginLeft: 19,
      color: theme.palette.grey.A100,
    },
    badgeNotif: {
      width: 28,
      height: 28,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#FF5C97',
      fontSize: 20,
      fontWeight: 'bold',
      marginLeft: 20,
      position: 'absolute',
      top: -10,
      right: -15,
    },
    badgeNotifs: {
      width: 28,
      height: 28,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#FF5C97',
      fontSize: 20,
      fontWeight: 'bold',
      marginLeft: 20,
      color: 'white',
    },
  }),
);
