import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const HEADER_HEIGHT = 91;

export default makeStyles<Theme, { homePage?: boolean }>((theme: Theme) =>
  createStyles({
    appBar: {
      height: (props) => (props.homePage ? 'auto' : HEADER_HEIGHT),
      background: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      overflow: 'auto',
    },

    toolbarContainer: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 80,
      paddingRight: 80,
      minHeight: 'auto !important',
      height: '100%',

      [theme.breakpoints.down('md')]: {
        paddingRight: 40,
        paddingLeft: 40,
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: 20,
        paddingLeft: 20,
      },
    },
    appBarHomePage: {
      justifyContent: 'center',
    },
    toolbarHomePage: {
      justifyContent: 'flex-start',
      paddingTop: 20,
    },
    headerContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      width: '100%',
      alignItems: 'center',
      height: '100%',
    },
    headerImageContainer: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 5px',
      },
    },
    image: {
      gridColumn: '1/1',
      width: 237,
    },
    imageText: {
      fontWeight: 'bold',
      marginTop: 14,
      fontSize: 12,
    },
    linksContainer: {
      gridColumn: '2/2',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100%',
    },
    link: {
      color: '#ffff',
      marginLeft: 32,
      fontSize: '14px !important',
      fontWeight: 'bold',

      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    }
  }),
);
