import React, { useState, useEffect } from 'react';
import RadioButton from 'components/common/RadioButton/RadioButton';
import Input from 'components/inputs/TextInput';
import { Question } from 'requests/badges';
import CheckBox from 'components/inputs/CheckBox';
import iconQuestion from 'assets/svg/typeQuestionBlue.svg';
import { useDidMount } from 'hooks/useLifeCycle';
import useStyles from './styles';

interface QuestionProps {
  question: Question[];
  handleQuestions: (text?: string, select?: string, checkbox?: string[]) => void;
}

const QuestionCard = ({ question, handleQuestions }: QuestionProps) => {
  const [checked, setChecked] = useState(false);
  const [globalState, setGlobalState] = useState<any>({});
  const generateState = () => question.map((item) => (item.type === 'multiple' ? [] : ''));
  const onChangeInput = (data: any, index: number) => {
    const old = [...globalState];
    old[index] = data;
    setGlobalState(old);
  };
  useDidMount(() => {
    const values = generateState();
    setGlobalState(values);
  });
  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
    const valueRB = e.target.value;
    if (typeof valueRB === 'string') {
      onChangeInput(valueRB, index);
    }
  };

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const checkboxClone = [...globalState[index]];
    setChecked(!checked);
    if (checkboxClone.find((el) => el === e.target.value)) {
      checkboxClone.splice(checkboxClone.indexOf(e.target.value), 1);
    } else {
      checkboxClone.push(e.target.value);
    }
    onChangeInput(checkboxClone, index);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    onChangeInput(e.target.value, index);
  };

  useEffect(() => {
    handleQuestions(globalState);
    // eslint-disable-next-line
  }, [globalState]);
  const classes = useStyles();
  //
  return (
    <div className={classes.question_card_container}>
      <div className={classes.item_container}>
        <div className={classes.answer_container}>
          {question.map((item: Question, i) => (
            <>
              {item.type === 'text' && (
                <div className={classes.text_input_wrapper} key={item._id}>
                  <div className={classes.icon_question_container}>
                    <img src={iconQuestion} alt="icon" className={classes.icon_question} />
                  </div>
                  <div className={classes.multiple_wrapper}>
                    <label className={classes.question_label}>
                      {item.question}
                      <span> ? </span>
                      <span style={{ color: '#F14E7F' }}> * </span>
                    </label>
                    <Input
                      value={globalState[i]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, i)}
                      placeholder="Votre réponse"
                      style={{ width: 788, height: 97 }}
                    />
                  </div>
                </div>
              )}
              {item.type === 'select' && (
                <div className={classes.text_input_wrapper} key={item._id}>
                  <div className={classes.icon_question_container}>
                    <img src={iconQuestion} alt="icon" className={classes.icon_question} />
                  </div>
                  <div className={classes.multiple_wrapper}>
                    <label className={classes.question_label}>
                      {item.question}
                      <span> ? </span>
                      <span style={{ color: '#F14E7F' }}> * </span>
                    </label>

                    <RadioButton
                      options={item.options.map((element) => ({ label: element, value: element }))}
                      value={globalState[i]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRadioButtonChange(e, i)}
                    />
                  </div>
                </div>
              )}
              {item.type === 'multiple' && (
                <div className={classes.text_input_wrapper} key={item._id}>
                  <div className={classes.icon_question_container}>
                    <img src={iconQuestion} alt="icon" className={classes.icon_question} />
                  </div>
                  <div className={classes.multiple_wrapper}>
                    <label className={classes.question_label}>
                      {item.question}
                      <span> ? </span>
                      <span style={{ color: '#F14E7F' }}> * </span>
                    </label>
                    {item.options.map((el, index) => (
                      <div className={classes.radio} key={el}>
                        <CheckBox
                          label={el}
                          value={el}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e, i)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
export default QuestionCard;
