import React, { useState, useEffect } from 'react';

import { useForm } from 'hooks/useInputs';
import classNames from 'utils/classNames';
import AddPropForm from 'components/forms/AddPropForm/AddPropForm';
import TextInput from 'components/inputs/TextInput';
import TagsInput from 'components/inputs/TagsInput';
import Button from 'components/buttons/Button';
import Title from 'components/common/Title/Title';

import badgeEchelon from 'assets/svg/badge-echelons-visual.svg';

import useStyles from './styles';

export interface EchelonFormValues {
  title: string;
  description: string;
  tags: string[];
}

interface Props extends Partial<EchelonFormValues> {
  initialOpen?: boolean;
  done?: boolean;
  onDelete?: () => void;
  errorText?: string;
  onSubmit: (values: EchelonFormValues) => void;
  resetOnSubmit?: boolean;
  className?: string;
  resetOnClose?: boolean;
  borderForm?: boolean;
  titleForm?: React.ReactChild | React.ReactChild[];
  addFormPropBorder?: string;
  showTextNewEchelon?: boolean;
  addFormPropComponentClassName?: string;
}

const EchelonForm = ({
  initialOpen,
  errorText,
  done,
  onDelete,
  title,
  resetOnSubmit,
  description,
  tags,
  onSubmit,
  className,
  resetOnClose,
  borderForm,
  addFormPropBorder,
  titleForm,
  showTextNewEchelon,
  addFormPropComponentClassName,
}: Props) => {
  const [open, setOpen] = useState(!!initialOpen);
  const [state, actions] = useForm({
    initialValues: {
      title: title || '',
      description: description || '',
      tags: tags || [],
    },
    required: ['title', 'description', 'tags'],
  });

  const { values, touched, errors } = state;
  const { handleChange, setValues, validateForm, setAllTouched } = actions;
  const classes = useStyles();

  useEffect(() => {
    if (resetOnClose) {
      setValues({
        title: title || '',
        description: description || '',
        tags: tags || [],
      });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (errorText) {
      setAllTouched(true);
    }
    // eslint-disable-next-line
  }, [errorText]);

  function handleSubmit() {
    if (validateForm()) {
      onSubmit(values);
      if (done) {
        setOpen(false);
      }
      if (resetOnSubmit) {
        setValues({ title: '', tags: [], description: '' });
        setAllTouched(false);
      }
    } else {
      setAllTouched(true);
    }
  }

  return (
    <AddPropForm
      popUpTitle="Supprimer l'echelon"
      popUpDescription="Êtes vous certain.e de vouloir supprimer cet echelon ?"
      popUptext="et conserver l'echelon"
      errorText={errorText}
      title={title}
      onDelete={onDelete}
      done={done}
      open={open}
      onToggle={() => setOpen(!open)}
      className={className}
      borderForm={borderForm}
      titleForm={titleForm}
      addFormPropBorder={addFormPropBorder}
      addFormPropComponentClassName={addFormPropComponentClassName}
    >
      <div className={classNames(classes.featureFormSelectRoot, done && classes.featureFormSelectPadding)}>
        {showTextNewEchelon && (
          <div className={classes.titleNewEchelonContainer}>
            <img alt="" className={classes.imageNewEchelon} src={badgeEchelon} />
            <Title color="#008EAD" size={30} title="Nouvel échelon" className={classes.newEchelonTitle} />
          </div>
        )}

        <TextInput
          name="title"
          value={values.title}
          onChange={handleChange}
          required
          label="Intitulé de l’échelon"
          errorText={touched.title && errors.title}
          className={classes.title}
        />
        <TextInput
          name="description"
          value={values.description}
          onChange={handleChange}
          required
          label="Description de l’échelon"
          multiline
          rows={6}
          className={classes.description}
          errorText={touched.description && errors.description}
        />

        <TagsInput
          onTagsChange={(nextTags) => setValues({ tags: nextTags })}
          tags={values.tags}
          required
          label="Mots-clés"
          className={classes.key}
          errorText={touched.tags && errors.tags}
          keywords
        />

        <Button size="large" onClick={handleSubmit} className={classes.buttonEchelon}>
          {`${done ? 'Modifier' : 'Ajouter'} l’échelon`}
        </Button>
      </div>
    </AddPropForm>
  );
};

export default EchelonForm;
