import { Candidature } from 'requests/candidature';
import {
 axiosGet, axiosPost, axiosPatch, Response,
} from './http';

export interface Notif {
  _id: string;
  idEndo?: string;
  idCandidature: Candidature;
  type: string;
  to: string;
  from: string;
  text: string;
  date: string;
  isRead: boolean;
  detail: 'demande' | 'answer';
}
export interface ParamPatch {
  isRead: boolean;
}

export const getNotification = (): Promise<Response<Notif[]>> => axiosGet('/notification');

export const getAllNotification = (): Promise<Response<Notif[]>> => axiosGet('/notification/all');

export const sendNotification = (data: Notif): Promise<Response<Notif>> => axiosPost('/notification', { data });

export const patchNotif = (isRead: boolean, id: string): Promise<Response<Notif>> =>
  axiosPatch(`/notification/${id}`, { data: { isRead } });
