import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import classNames from 'utils/classNames';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import './scss/calendar.scss';
import { useDidUpdate } from 'hooks/useLifeCycle';

require('moment/locale/fr');

interface Props {
  value?: string;
  format: string;
  onValueChange(date: string): void;
  dateMin?: string;
  dateMax?: string;
}

const Calendar = ({
 value, format, onValueChange, dateMin, dateMax,
}: Props): JSX.Element => {
  let initialValue = value || moment().format(format);

  if (dateMax && moment(dateMax, format).diff(moment(initialValue, format)) < 0) {
    initialValue = dateMax;
  } else if (dateMin && moment(dateMin, format).diff(moment(initialValue, format)) > 0) {
    initialValue = dateMin;
  }
  const [showYears, setShowYears] = useState(false);
  const [dateContext, setDateContext] = useState(initialValue);
  const weekdaysShort = moment.weekdaysShort(true).map((day) => day.slice(0, -1));

  const rows: Moment[][] = [];

  const start = moment(dateContext, format)
    .startOf('month')
    .startOf('week');
  const end = moment(dateContext, format)
    .endOf('month')
    .endOf('week');
  const numberOfWeeks = moment(end).diff(moment(start), 'weeks') + 1;
  const currentMonth = moment(dateContext).format('MM');

  for (let i = 0; i < numberOfWeeks; i += 1) {
    rows[i] = [];
    const week = moment(start).add(i, 'weeks');

    for (let j = 0; j < 7; j += 1) {
      const day = moment(week).add(j, 'days');

      rows[i].push(day);
    }
  }

  useDidUpdate(() => {
    setDateContext(value || moment().format(format));
  }, [value]);

  const nextMonthDate = moment(dateContext)
    .add(1, 'month')
    .startOf('month');
  const prevMonthDate = moment(dateContext)
    .subtract(1, 'month')
    .endOf('month');

  const prevMonth = (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    setDateContext(prevMonthDate.format(format));
  };

  const nextMonth = (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    setDateContext(nextMonthDate.format(format));
  };

  const showDate = (e: React.MouseEvent<any>, date: string) => {
    e.stopPropagation();
    onValueChange(date);
  };

  function renderWeekDay(day: string) {
    return (
      <div className="dayOfWeek flex_center" key={day}>
        {day}
      </div>
    );
  }

  function renderDate(date: Moment) {
    if (date.format('MM') !== currentMonth) return <div className="day" key={date.format()} />;
    const today = moment(date).format() === moment().format();

    const selected = moment(date).format('YYYY/MM/DD') === moment(value).format('YYYY/MM/DD');

    const disabled = !(
      (!dateMax || moment(date, format).diff(moment(dateMax, format)) <= 0)
      && (!dateMin || moment(date, format).diff(moment(dateMin, format)) >= 0)
    );

    return (
      <div key={date.format()} className="day flex_center">
        <button
          disabled={disabled}
          type="button"
          className={classNames('date flex_center', today && 'today', selected && 'selected')}
          onClick={(e) => showDate(e, date.format('YYYY-MM-DD'))}
        >
          {date.format('D')}
        </button>
      </div>
    );
  }
  function renderYears(): JSX.Element {
    const startDate = moment('1950-01-01').year()
    const endDate = moment().year()
    const result = [];
    for (let i = startDate; i <= endDate ;i++) result.push(i);
    return (
      <div className="years_container">
        {result
          .sort((a, b) => b - a)
          .map((item) => (
            <span onClick={() => setYearDate(item)} className="year" key={item}>
              {item}
            </span>
          ))}
      </div>
    );
  }
  const setYearDate = (year: number) => {
    const actualDate = dateContext;
    const selectedDate = moment(actualDate)
      .year(year)
      .format('YYYY-MM-DD');
    setDateContext(selectedDate);
    setShowYears(false);
  };

  return (
    <div className="calendar_container">
      <div className="header">
        <span className="span" onClick={() => setShowYears(!showYears)}>
          {moment(dateContext)
            .format('MMMM YYYY')
            .charAt(0)
            .toUpperCase()
            + moment(dateContext)
              .format('MMMM YYYY')
              .slice(1)}
        </span>
        <div className="flex_row">
          <button
            disabled={!!(dateMin && prevMonthDate.diff(moment(dateMin, format)) < 0)}
            className="arrow right_arrow flex_center"
            onClick={prevMonth}
            type="button"
          >
            <KeyboardArrowLeft />
          </button>

          <button
            disabled={!!(dateMax && nextMonthDate.diff(moment(dateMax, format)) > 0)}
            className="arrow flex_center"
            onClick={nextMonth}
            type="button"
          >
            <KeyboardArrowRight />
          </button>
        </div>
      </div>
      {showYears && renderYears()}
      <div className="row">{weekdaysShort.map(renderWeekDay)}</div>

      {rows.map((row, index) => (
        // eslint-disable-next-line
        <div key={index} className="row">
          {row.map(renderDate)}
        </div>
      ))}
    </div>
  );
};

Calendar.defaultProps = {
  format: 'YYYY-MM-DD',
};

export default Calendar;
