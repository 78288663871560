import React, { useState, useEffect, useRef, useContext, FunctionComponent } from 'react';
import { RouteComponentProps, useParams, Redirect, Link, useHistory } from 'react-router-dom';
import { encodeUri } from 'utils/url';
import Text from 'components/ui/Text';
import Modal from '@material-ui/core/Modal';

import userContext from 'contexts/userContext';
import Button from 'components/buttons/Button';
import LogoCompetence from 'components/badge/CategorieLogo';
import CritereCard from 'components/cards/CritereCard/CritereCard';
import CheckBox from 'components/inputs/CheckBox';
import moment from 'moment';
import { getBadge, getBadgeChilds, Criteria, Badge } from 'requests/badges';
import { getDetentteur } from 'requests/user';

import useApiState from 'hooks/useApiState';
import { useDidMount, useDidUpdate } from 'hooks/useLifeCycle';
import TextInput from 'components/inputs/TextInput';
import Select from 'components/inputs/Select';
import { useForm } from 'hooks/useInputs';
import AddLogo from 'assets/svg/defaultUser_v3.svg';
import LeftArrow from 'assets/svg/left-arrow.svg';
import Checkmark from 'assets/svg/checked.svg';

import { validateEmail } from 'utils/validation';
import {
  submitTextCritere,
  addCandidature,
  submitFileCriter,
  AddCandidatureData,
  submitQuestionCritere,
  getCandidatureBadge,
  addAgrement,
  getBadgeCandidature,
  PatchCandidature,
  getAllEndossementById,
} from 'requests/candidature';
import { ErrorResponse } from 'requests/http';
import CustomizedSnackbars from 'components/ui/SnackBar';
import Loader from 'components/ui/Loader';
import useStyles from './style';

import {
  LinkedinIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

require('moment/locale/fr');

interface Props {
  ParentRoute?: string;
}

type IVariant = 'success' | 'warning' | 'error' | 'info';

const BadgeDetail: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(userContext);
  const [criteriaResults, setCriteriaResults] = useState<any[]>([]);
  const [delivranceState, delivrancePatch] = useApiState(PatchCandidature);
  const [getAllEndossement, getAllEndossementCall] = useApiState(getAllEndossementById);
  const [addCandidatureState, addCandidatureCall] = useApiState(addCandidature);
  const [endossementState, endossementCall] = useApiState(addAgrement);
  const [detentteurState, detentteurCall] = useApiState(getDetentteur);
  const [candidatureBadgeState, candidatureBadgeCall] = useApiState(getCandidatureBadge);
  const [badgeCandidatureState, badgeCandidatureCall] = useApiState(getBadgeCandidature);
  const [badgeState, BadgeCall] = useApiState(getBadge);
  const [childs, getchilds] = useApiState(getBadgeChilds);

  const [obtainBadge, setGetBadge] = useState(false);
  const [endossement, setEndosseBadge] = useState(false);
  const [checkedCandi, setCheckedCandi] = useState('critere');

  const [openModal, setOpenModal] = useState(false);
  const [endossementArray, setEndossementArray] = useState([]);
  const [fetchingAll, changeFetchingAll] = useState(false);
  const [variantModal, setVariantModal] = useState<IVariant>('success');
  const [textModal, setTextModal] = useState('');
  const [validationBtnState, setValidationBtnState] = useState(true);
  const [imgUrl, setImgUrl] = useState('');
  const [deliveranceCode, setDeliveranceCode] = useState('');
  const [modalImg, setModalImg] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(-1);

  const { id } = useParams<{ id: string }>();
  const [formState, formActions] = useForm({
    initialValues: {
      email: '',
    },
    required: ['email'],
    validation: {
      email: validateEmail,
    },
  });
  const { handleChange } = formActions;
  const { values, errors, touched } = formState;

  const setBadge = () => {
    /* if (!user) {
      return <Redirect to={`/login${encodeUri({ from: window.location.pathname + window.location.search })}`} />;
    } */
    setGetBadge(true);
    history.push(`/badge/${id}`);
  };

  useEffect(() => {
    if (id) {
      BadgeCall(id);
      getchilds(id);
      detentteurCall(id);
      getAllEndossementCall(id);
      if (user) {
        candidatureBadgeCall(id);
      }
    }
  }, [id, user]);

  useEffect(() => {
    if (
      badgeState.fetching ||
      childs.fetching ||
      detentteurState.fetching ||
      candidatureBadgeState.fetching ||
      getAllEndossement.fetching ||
      badgeCandidatureState.fetching
    )
      changeFetchingAll(true);
    else {
      changeFetchingAll(false);
    }
  }, [
    badgeState.fetching,
    childs.fetching,
    detentteurState.fetching,
    candidatureBadgeState.fetching,
    getAllEndossement.fetching,
    badgeCandidatureState.fetching,
  ]);

  useEffect(() => {
    if (
      badgeState.errorCode ||
      childs.errorCode ||
      detentteurState.errorCode ||
      candidatureBadgeState.errorCode ||
      getAllEndossement.errorCode ||
      badgeCandidatureState.errorCode
    )
      history.push("/404");
  }, [
    badgeState.errorCode,
    childs.errorCode,
    detentteurState.errorCode,
    candidatureBadgeState.errorCode,
    getAllEndossement.errorCode,
    badgeCandidatureState.errorCode,
  ]);

  useEffect(() => {
    if (getAllEndossement.data) {
      const array = getAllEndossement.data.filter((f: any) => f.status === 'accepted');
      setEndossementArray(array);
    }
  }, [getAllEndossement.data]);

  useDidUpdate(() => {
    if (obtainBadge && !addCandidatureState.fetching) {
      changeFetchingAll(false);
      setOpenModal(true);
      if (addCandidatureState.data) {
        setVariantModal('success');
        setTextModal('Demande envoyée !');
        // history.push('/profile');
        if (id) {
          BadgeCall(id);
        }
        setTimeout(() => {
          history.push('/profile');
        }, 1500);
      } else {
        setVariantModal('error');
        let err = '';
        const errType = addCandidatureState?.errors?.deliveranceCode || addCandidatureState.errorCode;
        switch (errType) {
          case 'BADGE_WRONG_CODE':
            err = 'Mauvais code de délivrance';
            break;
          case 'STRING_LENGTH':
            err = 'Le code doit comporter 6 caractères';
            break;
          default:
            err = 'Problème de candidature';
        }
        setTextModal(err);
      }
    }
    if (endossement && !endossementState.fetching) {
      setOpenModal(true);
      if (!endossementState.errors && !endossementState.errorCode && endossementState.data) {
        setVariantModal('success');
        setTextModal('Demande envoyée avec succès !');
        setEndosseBadge(false);
      } else {
        setVariantModal('error');
        setTextModal("Problème d'endossement. Veuillez réessayer.");
      }
    }
  }, [addCandidatureState.fetching, endossementState.fetching]);

  useEffect(() => setCriteriaResults(badgeState.data?.criterion.map(() => null) || []), [badgeState.data]);
  useEffect(() => {
    if (delivranceState.data) {
      badgeCandidatureCall(id);
    }
  }, [delivranceState.data, badgeCandidatureCall, id]);

  useEffect(() => {
    if (obtainBadge) {
      if (deliveranceCode) {
        if (deliveranceCode !== '') {
          setValidationBtnState(false);
        } else {
          setValidationBtnState(true);
        }
      } else if (
        criteriaResults.includes(null) ||
        criteriaResults.includes('') ||
        (questionIndex !== -1 && criteriaResults[questionIndex].length === 0)
      ) {
        setValidationBtnState(true);
      } else {
        setValidationBtnState(false);
      }
    }
  }, [obtainBadge, criteriaResults, questionIndex, deliveranceCode]);

  const getEndossement = () => {
    setGetBadge(false);
    setEndosseBadge(true);
  };
  const handleSubmit = async () => {
    if (checkedCandi === 'critere') {
      const attachments = criteriaResults;
      const criterions = badgeState.data?.criterion || [];
      const condidaterRequests = criterions.map((criterion, i) => {
        const { title, type } = criterion;
        if (criterion.type === 'pdf' || criterion.type === 'image') {
          return submitFileCriter({ idCritere: criterion._id, title, type, file: attachments[i] });
        }
        if (criterion.type === 'questionnaire') {
          return submitQuestionCritere({
            title,
            type,
            questions: attachments[i],
            idCritere: criterion._id,
          });
        }
        return submitTextCritere({ idCritere: criterion._id, title, type, description: attachments[i] });
      });
      changeFetchingAll(true);
      const allCallResponses = await Promise.all(condidaterRequests);
      const error: ErrorResponse = allCallResponses.find((response) => response.status !== 'OK') as any;
      if (error) {
        changeFetchingAll(false);
      } else {
        const ids: string[] = allCallResponses
          .map((response: any) => response.data && response.data._id)
          .filter((d) => d);
        if (id) {
          const payload: AddCandidatureData = { criterions: ids, idBadge: id, endorsement: endossement };
          addCandidatureCall(payload);
        }
      }
    } else {
      const payload: AddCandidatureData = {
        criterions: [],
        idBadge: id,
        endorsement: endossement,
        deliveranceCode,
      };
      addCandidatureCall(payload);
    }
  };
  const handleResult = (
    result: {
      code?: string;
      text?: string;
      file?: File;
      fileImage?: File;
      question?: {
        answer: string | string[] | undefined;
        type: string;
        question: string;
      }[];
    },
    el: Criteria,
    index: number,
  ) => {
    let currentResult;

    switch (el.type) {
      case 'pdf': {
        currentResult = result.file;
        break;
      }
      case 'image': {
        currentResult = result.fileImage;
        break;
      }
      case 'questionnaire': {
        currentResult = result.question;
        setQuestionIndex(index);
        break;
      }
      default: {
        currentResult = result.text;
      }
    }
    const nextCriteriaResults = [...criteriaResults];
    nextCriteriaResults[index] = currentResult;
    setCriteriaResults(nextCriteriaResults);
  };
  // endossementCall
  const addEndossement = (idBadge: string) => {
    if (id) {
      if (badgeState.data?.issuerId._id === user?._id) {
        // add endorsement to badgeClass
        endossementCall(values.email, idBadge, 'user', 'badge');
      } else if (badgeState.data?.awardId) {
        // add endorsement to assertion
        endossementCall(values.email, idBadge, 'user', 'benif');
      }
    }
  };
  const accept = (idc: string) => {
    delivrancePatch({ id: idc, status: 'accepted' });
  };
  const refuse = (idc: string) => {
    delivrancePatch({ id: idc, status: 'refused' });
  };
  const changeImgUrl = (url: string) => {
    setImgUrl(url);
    setModalImg(true);
  };
  const clearImgUrl = () => {
    setImgUrl('');
    setModalImg(false);
  };
  const onChangeChecked = (type: string) => {
    setCheckedCandi(type);
  };
  const childsArray = [];
  childsArray.push(childs.data);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref && ref.current && ref.current.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  });
  const onNavigateBack = () => {
    history.goBack();
  };

  if (!user && obtainBadge) {
    return <Redirect to={`/login${encodeUri({ from: window.location.pathname + window.location.search })}`} />;
  }
  const isOwner = badgeState.data?.issuerId._id === user?._id;

  if (fetchingAll) {
    return <div className={classes.backdrop}>
      <Loader />
    </div>;
  }

  return (
    <div className={classes.badge_modal_conatiner}>
      {!obtainBadge && !endossement ? (
        <div className={classes.badge_details_header} />
      ) : (
        <div className={classes.badge_details_header_2} />
      )}
      <div className={classes.badge_empty_container} style={{ height: height - 113 }} />
      <div className={classes.blue_mask} style={{ height: height + 400 }} />
      <div className={classes.badge_modal_content} ref={ref}>
        <div className={classes.competence_logos}>
          <div className={classes.backContainer} onClick={onNavigateBack}>
            <img src={LeftArrow} alt="back" className={classes.logoBack} />
            <span className={classes.textBack}>Retour</span>
          </div>
          <div className={classes.categorieWrapper}>
            <div>
              {badgeState.data?.mainCategory && (
                <div className={classes.competence_logo_container}>
                  <LogoCompetence
                    label={badgeState.data?.mainCategory.name}
                    bgColor={badgeState.data?.mainCategory.background}
                    className={classes.competence_logo}
                  />
                  <span className={classes.competence_logo_name}>{badgeState.data?.mainCategory.name}</span>
                </div>
              )}
            </div>
            <div>
              {badgeState.data?.secondaryCategory && (
                <div className={classes.competence_logo_container}>
                  <LogoCompetence
                    label={badgeState.data?.secondaryCategory?.name}
                    bgColor={badgeState.data?.secondaryCategory?.background}
                    className={classes.competence_logo}
                  />
                  <span className={classes.competence_logo_name}>{badgeState.data?.secondaryCategory?.name}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.badge_modal_header}>
          <div className={classes.badge_image_container}>
            <img src={badgeState.data?.image} alt="logo" className={classes.badge_image} />
          </div>
          <div className={classes.badge_texts_container}>
            <div className={classes.badge_detail_title_container}>
              <Text label={badgeState.data?.title} className={classes.badge_detail_title} />
            </div>
            {badgeState.data?.status === 'accepted' && (
              <div>
                <div className={classes.badge_owned_pill}>
                  <img src={Checkmark} height={20} width={20} alt="Vous possédez ce badge" />
                  <span style={{ marginLeft: 5 }}>Vous possédez ce badge</span>
                </div>
              </div>
            )}
            <div className={classes.badge_detail_description_container}>
              <Text label={badgeState.data?.description} className={classes.badge_detail_description} />
            </div>
            <div className={classes.btn_tags_container}>
              {badgeState.data?.tags.map((el) => (
                <Button key={el} variant="contained" className={classes.btn_tags}>
                  <Link to={`/marketplace?search=${el.replace('#', '')}`}>
                    <span className={classes.btn_tags_label}>#{el}</span>
                  </Link>
                </Button>
              ))}
            </div>
          </div>
          <div className={classes.badge_detail_info_container}>
            <div style={{ display: 'block' }} className={classes.info_container}>
              <Text label="Émetteur : " className={classes.badge_detail_info_label} />
              <div>
                <Link
                  style={{ display: 'flex', alignItems: 'center' }}
                  to={'/public/issuer/' + badgeState.data?.issuerId?._id}
                >
                  <img style={{ height: 37, width: 37, marginRight: 7 }} src={badgeState.data?.issuerId?.image} />
                  <Text label={` ${badgeState.data?.issuerId.fullName}`} className={classes.badge_detail_info_value} />
                </Link>
              </div>
            </div>
            <div className={classes.info_container}>
              <Text
                label={`Détenteur${(detentteurState?.data?.length || 0) > 1 ? 's' : ''} : `}
                className={classes.badge_detail_info_label}
              />
              <Text label={` ${detentteurState.data?.length}`} className={classes.badge_detail_info_value} />
            </div>
            {badgeState.data?.deliveranceCode && isOwner && (
              <div className={classes.info_container}>
                <Text label="Code d'auto-délivrance : " className={classes.badge_detail_info_label} />
                <Text label={` ${badgeState.data?.deliveranceCode}`} className={classes.badge_detail_info_value} />
              </div>
            )}
            <div className={classes.info_container}>
              <Text label="Date de création : " className={classes.badge_detail_info_label} />
              <Text
                label={`${moment(badgeState.data?.createdAt).format('L')}`}
                className={classes.badge_detail_info_value}
              />
            </div>
            {candidatureBadgeState.data?.status === 'accepted' && (
              <div className={classes.info_container}>
                <Text label="Date d’obtention : " className={classes.badge_detail_info_label} />
                <Text
                  label={`${
                    candidatureBadgeState.data ? moment(candidatureBadgeState.data?.createdAt).format('L') : '-'
                  }`}
                  className={classes.badge_detail_info_value}
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.badge_detail_container}>
          <div className={classes.badge_details}>
            <>
              {badgeState.data?.parentId && (
                <div>
                  <div className={classes.badge_criteres_title_container}>
                    <Text label="Badge parent" className={classes.badge_criteres_title} />
                  </div>
                  <div className={classes.bar} />
                  <div className={classes.sub_badge_container}>
                    <div className={classes.sub_badge}>
                      <img
                        className={classes.sub_badge_image}
                        src={badgeState.data?.parentId?.image}
                        alt={badgeState.data?.parentId?.title}
                      />
                      <div className={classes.sub_badge_info}>
                        <strong className={classes.sub_badge_info_title}>{badgeState.data?.parentId?.title}</strong>
                        <div className={classes.sub_badge_info_description}>
                          {badgeState.data?.parentId?.description}
                        </div>
                      </div>
                      <div>
                        <Link to={`/badge/${badgeState.data?.parentId?._id}`}>
                          <Button className={classes.sub_badge_btn}>Voir le badge parent</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(childs.data?.length || 0) > 0 && (
                <div>
                  <div className={classes.badge_criteres_title_container}>
                    <Text label="Echelons" className={classes.badge_criteres_title} />
                    <div className={classes.badge_criteres_title}>({childs.data?.length})</div>
                  </div>
                  <div className={classes.sub_badge_container}>
                    {childs.data?.map((v: Badge) => (
                      <div key={v._id}>
                        <div className={classes.bar} />
                        <div className={classes.sub_badge}>
                          <img className={classes.sub_badge_image} src={v.image} alt={v.title} />
                          <div className={classes.sub_badge_info}>
                            <strong className={classes.sub_badge_info_title}>{v.title}</strong>
                            <div className={classes.sub_badge_info_description}>{v.description}</div>
                            {v.isOwnedByUser && (
                              <div className={classes.badge_owned_pill}>
                                <img src={Checkmark} height={20} width={20} alt="Vous possédez ce badge" />
                                <span style={{ marginLeft: 5 }}>Vous possédez ce badge</span>
                              </div>
                            )}
                          </div>
                          <div>
                            <Link to={`/badge/${v._id}`}>
                              <Button className={classes.sub_badge_btn}>Voir le badge</Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={classes.critere_badges}>
                <div className={classes.badge_criteres_title_container}>
                  <Text label="Critères" className={classes.badge_criteres_title} />
                  <div className={classes.badge_criteres_title}>({badgeState.data?.criterion.length})</div>
                </div>
                <div className={classes.bar} />
                <div>
                  {obtainBadge && badgeState.data?.deliveranceCode && (
                    <div style={{ display: 'flex' }}>
                      {obtainBadge && badgeState.data?.deliveranceCode && (
                        <div>
                          <CheckBox checked={checkedCandi === 'code'} onChange={() => onChangeChecked('code')} />
                        </div>
                      )}
                      <div className={classes.critere_input_url}>
                        <TextInput
                          label="Code de délivrance"
                          className={classes.input_url}
                          disabled={checkedCandi !== 'code'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeliveranceCode(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {obtainBadge && badgeState.data?.deliveranceCode && (
                    <CheckBox checked={checkedCandi === 'critere'} onChange={() => onChangeChecked('critere')} />
                  )}
                  {badgeState.data?.criterion.map((el, index) => (
                    <div className={classes.critere_item} key={el._id}>
                      <CritereCard
                        id={el._id}
                        question={el.questions}
                        candidature={obtainBadge}
                        title={el.title}
                        description={el.description}
                        type={el.type}
                        handleResult={(result) => handleResult(result, el, index)}
                        index={index + 1}
                        codeExist={badgeState.data?.deliveranceCode}
                        onchangeChecked={onChangeChecked}
                        checkedCandi={checkedCandi}
                      />
                      <div className={classes.bar} />
                    </div>
                  ))}
                </div>
                {endossementArray && endossementArray.length > 0 && (
                  <div>
                    <div style={{ display: 'flex', marginTop: '2em' }}>
                      <div className={classes.badge_criteres_title}>Endosseurs</div>
                      <div className={classes.badge_criteres_title}>
                        ({endossementArray.length ? endossementArray.length : 0})
                      </div>
                    </div>
                    <div style={{ marginTop: 18 }}>
                      {endossementArray
                        .filter((f: any) => f.status === 'accepted')
                        .map((e: any) => (
                        
                            <div
                              style={{
                                margin: '5px 0px',
                                backgroundColor: '#ddf7ff',
                                padding: 14,
                                borderRadius: 10,
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: 18 }}>
                                  <img style={{ height: 48, borderRadius: 5 }} src={e?.idEndosseur?.image} />
                                </div>
                                <div style={{ flexShrink: 1 }}>
                                  <div style={{ display: 'flex' }}>
                                    <span style={{}}>
                                      <strong>{e.idEndosseur?.fullName}</strong> • {e.email}{' '}
                                      {e.idEndosseur?.website && (
                                        <span>
                                          •{' '}
                                          <a
                                            style={{ color: '#F14E7F' }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={e.idEndosseur?.website}
                                          >
                                            {e.idEndosseur?.website}
                                          </a>
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div style={{ marginTop: 8 }}>
                                <span style={{ flexShrink: 1 }}>
                                {e.text}
                                </span>
                              </div>
                            </div>
                          
                        ))}
                    </div>
                  </div>
                )}
                {!obtainBadge && !endossement && (
                  <div className={classes.btn_recomandation_bottom}>
                    {badgeState.data?.awardId && (
                      <>
                        {/*<Button
                          variant="contained"
                          className={classes.btn_partager_badge}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${process.env.REACT_APP_FRONT_URL}/public/assertion/${badgeState.data?.awardId}`}
                        >
                          <span className={classes.btn_badge_label}>
                            <Share height={128} />
                          </span>
                        </Button>*/}
                        <Button
                          variant="contained"
                          className={classes.btn_badge}
                          href={`${process.env.REACT_APP_API_URL}/assertions/${badgeState.data?.awardId}.png`}
                          download
                        >
                          <span className={classes.btn_badge_label}>Télécharger mon badge</span>
                        </Button>
                      </>
                    )}
                    {badgeState.data && badgeState.data?.issuerId._id === user?._id && (
                      <Button
                        variant="contained"
                        className={classes.btn_badge}
                        onClick={() => history.push(`/awardBadge/${badgeState.data?._id}`)}
                      >
                        <span className={classes.btn_badge_label}>Attribuer ce badge</span>
                      </Button>
                    )}
                    {badgeState.data &&
                      !badgeState.data?.awardId &&
                      childs.data?.length <= 0 &&
                      badgeState.data?.issuerId._id !== user?._id && (
                        <div>
                          {user?.userType === 'user' && (
                            <Button
                              variant="contained"
                              className={classes.btn_badge}
                              onClick={setBadge}
                              disabled={
                                childs.data?.length > 0 ||
                                badgeState.data?.issuerId._id === user?._id ||
                                badgeState.data?.status === 'pending' ||
                                badgeState.data?.status === 'accepted'
                              }
                            >
                              {badgeState.data?.status === 'pending' ? (
                                <span className={classes.btn_badge_label}>Candidature en cours</span>
                              ) : (
                                <span className={classes.btn_badge_label}>Obtenir ce badge</span>
                              )}
                            </Button>
                          )}
                        </div>
                      )}
                    {badgeState.data &&
                      (badgeState.data?.issuerId._id === user?._id || badgeState.data?.status === 'accepted') && (
                        <Button
                          variant="contained"
                          className={classes.endosser_btn_badge}
                          onClick={getEndossement}
                          disabled={
                            badgeState.data?.issuerId._id !== user?._id && badgeState.data?.status !== 'accepted'
                          }
                        >
                          <span className={classes.btn_badge_label}>Demander l'endossement de mon Open Badge</span>
                        </Button>
                      )}
                  </div>
                )}
                {obtainBadge && (
                  <div className={classes.btn_recomandation_bottom}>
                    <Button
                      onClick={handleSubmit}
                      className={classes.btn_badge}
                      disabled={validationBtnState || addCandidatureState.fetching}
                      fetching={addCandidatureState.fetching}
                      style={{ height: 53, width: 382 }}
                    >
                      <span className={classes.btn_badge_label}>Valider ma demande d’obtention</span>
                    </Button>
                  </div>
                )}
              </div>
            </>
            {endossement && (
              <div className={classes.endossement_container}>
                <Text
                  label="Demander l'endossement de mon Open Badge"
                  type="sub-title"
                  className={classes.endossement_title}
                />
                <div className={classes.moyen_endossement_container}>
                  <div className={classes.select_email_container}>
                    <Text label="Moyen d’endossement" type="sub-title" className={classes.endossement_subtitle} />
                    <Select
                      options={[{ value: 'via adresse email', label: 'via adresse email' }]}
                      className={classes.email_select}
                      inputClassName={classes.email_select_input}
                      defaultValue="via adresse email"
                    />
                  </div>
                  <div className={classes.select_email_container}>
                    <Text
                      label="Adresse e-mail de l’endosseur"
                      type="sub-title"
                      className={classes.endossement_subtitle}
                    />
                    <TextInput
                      name="email"
                      placeholder="email@example.fr"
                      className={classes.email_endosseur}
                      value={values.email}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      errorText={touched.email ? errors.email : ''}
                      error={touched.email && errors.email !== ''}
                    />
                  </div>
                </div>
                <div className={classes.btn_recomandation_bottom}>
                  <Button
                    variant="contained"
                    className={classes.btn_badge}
                    onClick={() => addEndossement(id)}
                    style={{ height: 53, width: 413 }}
                  >
                    <span className={classes.btn_badge_label}>Envoyer ma demande d’endossement</span>
                  </Button>
                </div>
              </div>
            )}
            {badgeState.data && badgeState.data?.awardId && (
              <div style={{ paddingTop: '1.2em', textAlign: 'center' }}>
                <p>Partagez votre badge sur les réseaux sociaux :</p>
                <div style={{ paddingTop: '0.5em', display: 'flex', justifyContent: 'center' }}>
                  <div style={{ marginLeft: '0.25em', marginRight: '0.25em' }}>
                    <EmailShareButton
                      subject={`J'ai obtenu le badge ${badgeState.data?.title} via la plateforme DiVA !`}
                      body={`J'ai obtenu le badge ${badgeState.data?.title} via la plateforme DiVA !`}
                      url={`${process.env.REACT_APP_FRONT_URL}/public/assertion/${badgeState.data?.awardId}`}
                    >
                      <EmailIcon size={48} />
                    </EmailShareButton>
                  </div>
                  <div style={{ marginLeft: '0.25em', marginRight: '0.25em' }}>
                    <FacebookShareButton
                      quote={`J'ai obtenu le badge ${badgeState.data?.title} via la plateforme DiVA !`}
                      url={`${process.env.REACT_APP_FRONT_URL}/public/assertion/${badgeState.data?.awardId}`}
                    >
                      <FacebookIcon size={48} />
                    </FacebookShareButton>
                  </div>
                  <div style={{ marginLeft: '0.25em', marginRight: '0.25em' }}>
                    <TwitterShareButton
                      title={`J'ai obtenu le badge ${badgeState.data?.title} via la plateforme DiVA !`}
                      hashtags={['OpenBadges', 'DiVA']}
                      related={['id6tm']}
                      url={`${process.env.REACT_APP_FRONT_URL}/public/assertion/${badgeState.data?.awardId}`}
                    >
                      <TwitterIcon size={48} />
                    </TwitterShareButton>
                  </div>
                  <div style={{ marginLeft: '0.25em', marginRight: '0.25em' }}>
                    <LinkedinShareButton
                      title={`J'ai obtenu le badge ${badgeState.data?.title} via la plateforme DiVA !`}
                      url={`${process.env.REACT_APP_FRONT_URL}/public/assertion/${badgeState.data?.awardId}`}
                    >
                      <LinkedinIcon size={48} />
                    </LinkedinShareButton>
                  </div>
                </div>
                <div style={{ marginTop: '0.75em' }}>
                  <p>Lien direct :</p>
                  <a
                    target="_bank"
                    href={`${process.env.REACT_APP_FRONT_URL}/public/assertion/${badgeState.data?.awardId}`}
                  >
                    <TextInput
                      value={`${process.env.REACT_APP_FRONT_URL}/public/assertion/${badgeState.data?.awardId}`}
                      inputProps={{ readOnly: true }}
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal open={modalImg}>
        <div className={classes.ModalImgContainer}>
          <div className={classes.modalContent}>
            <div onClick={clearImgUrl} className={classes.closeBtn}>
              Close
            </div>
            <img src={imgUrl} alt="imgToZoom" className={classes.imgModal} />
          </div>
        </div>
      </Modal>
      <CustomizedSnackbars variant={variantModal} open={openModal} handleClose={handleCloseModal} message={textModal} />
    </div>
  );
};
export default BadgeDetail;
