import { makeStyles } from '@material-ui/core/styles';
import checkedRB from '../../../assets/svg/checkedRB.svg';

export default makeStyles((theme) => ({
  radio_button_container: {},
  label: {
    marginLeft: 35,
  },
  form_container: {
    marginBottom: 24,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    backgroundColor: theme.palette.secondary.main,
    border: '3px solid #5B78C2',
    boxSizing: 'border-box',
    opacity: '0.5',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundImage: `url(${checkedRB})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: 18,
    height: 18,
  },
  option_label: {
    fontFamily: 'Gangster Grotesk',
    fontSize: 20,
    lineHeight: '30px',
    color: '#434343',
    marginLeft: 36,
    '&:checked': {
      fontWeight: 'bold',
    },
  },
}));
