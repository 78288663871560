import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import RootContainer from 'containers/RootContainer/RootContainer';



const App = () => (
  <BrowserRouter>
      <RootContainer />
  </BrowserRouter>
);

export default App;
