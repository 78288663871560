import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Label from 'components/inputs/Label';
import ErrorText from 'components/inputs/ErrorText';

import { useFocus } from 'hooks/useEvent';

import classNames from 'utils/classNames';
import './scss/tagsInput.scss';

interface Props {
  tags: string[];
  className?: string;
  label: string;
  onTagsChange: (tags: string[]) => void;
  placeholder?: string;
  classes?: {
    inputContainer?: string;
    label?: string;
  };
  errorText?: string;
  required?: boolean;
  keywords?: boolean;
}

const TagsInput = ({
  tags,
  className,
  label: inputLabel,
  onTagsChange,
  classes: c,
  errorText,
  placeholder,
  required,
  keywords,
}: Props) => {
  const [isFocused, focus, blur] = useFocus(false);
  const [currentTag, setCurrentTag] = useState('');

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && currentTag) {
      e.preventDefault();
      onTagsChange([...tags, currentTag]);
      setCurrentTag('');
    }

    if (e.key === 'Backspace' && tags.length && !currentTag) {
      e.preventDefault();
      onTagsChange(tags.filter((tag, index) => index !== tags.length - 1));
    }
  };

  const classes = c || {};

  return (
    <div
      className={classNames(
        'components_inputs_tags_input',
        errorText !== undefined && 'components_inputs_input_with_error',
        className,
      )}
    >
      <Label required={required} className={classes.label}>
        {inputLabel}
      </Label>
      {keywords && <div className={'tags_input_text'}>Appuyez sur la touche "Entrée" pour valider chaque mot-clé</div>}

      <div className={classNames('tags_input_container', classes.inputContainer)}>
        {tags.map((tag, i) => (
          <Chip
            classes={{ deleteIcon: 'tags_input_delete', label: 'tags_input_tag_label' }}
            className="tags_input_tag"
            // eslint-disable-next-line
            key={i}
            label={tag}
            onDelete={() => {
              onTagsChange(tags.filter((t, index) => index !== i));
            }}
          />
        ))}
        <div className="tags_input_input">
          <input
            onKeyDown={onKeyDown}
            value={currentTag}
            onChange={(e) => setCurrentTag(e.target.value)}
            placeholder={placeholder}
            onFocus={focus}
            onBlur={blur}
            maxLength={25}
          />
        </div>
        <fieldset
          className={classNames(
            'tags_input_border',
            isFocused && 'tags_input_border_focused',
            errorText && 'tags_input_border_error',
          )}
        />
      </div>
      {errorText !== undefined && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
};

export default TagsInput;
