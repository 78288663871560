import React from 'react';

import Title from 'components/common/Title/Title';
import classNames from 'utils/classNames';

import rg from 'assets/images/rg-haut-fr.png';
import fd from 'assets/svg/fr-fd.svg';
import tc from 'assets/images/logo-telecom.png';

import useStyles from './styles';

interface IProps {
  className?: string;
}

const SecondFooter = ({ className }: IProps) => {
  const classes = useStyles();
  return (
    <footer className={classNames(classes.footerContainer, className)}>
      <div className={classes.rowContainer}>
        <Title title="Financement Innovation" className={classes.title} />
        <img src={rg} alt="" className={classes.inovationImage} />
      </div>
      <div className={classes.rowContainer}>
        <Title title="Prix Innovation numérique" className={classes.title} />
        <img src={fd} alt="" className={classes.inovationPriceImage} />
      </div>
      <div className={classes.rowContainer}>
        <Title title="Prix Solidarités Numériques" className={classes.title} />
        <img src={tc} alt="" className={classes.inovationImageFr} />
      </div>
    </footer>
  );
};

export default SecondFooter;
