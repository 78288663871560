import { axiosGet, Response } from './http';

export interface Model {
  _id: string;
  file: string;
  name: string;
  type?: string;
}

export const ListModel = (): Promise<Response<Model[]>> => axiosGet('/model');

export const ListIcons = (id: string): Promise<Response<string[]>> => axiosGet(`/icons/model/${id}`);
