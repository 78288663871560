import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import bgSvg from 'assets/svg/header-bg.svg';

export const HEADER_HEIGHT = 91;

export default makeStyles<Theme, { homePage?: boolean }>((theme: Theme) =>
  createStyles({
    appBar: {
      height: (props) => (props.homePage ? 'auto' : HEADER_HEIGHT),
      background: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      overflow: 'auto',
      backgroundImage: (props) => (props.homePage ? `url(${bgSvg})` : ''),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('md')]: {
        backgroundSize: 'auto',
      },
    },

    toolbarContainer: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 80,
      paddingRight: 80,
      minHeight: 'auto !important',

      [theme.breakpoints.down('md')]: {
        paddingRight: 20,
        paddingLeft: 20,
      },
      
    },
    appBarHomePage: {
      justifyContent: 'flex-start',
    },
    toolbarHomePage: {
      justifyContent: 'flex-start',
      paddingTop: 20,
    },
    headerContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      width: '100%',
    },
    headerImageContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      gridColumn: '1/1',
      width: 237,
      [theme.breakpoints.down('sm')]: {
        width: 180,
      },
    },
    imageText: {
      fontWeight: 'bold',
      marginTop: 14,
      fontSize: 12,
      color: '#9beeee',
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
    linksContainer: {
      gridColumn: '2/2',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    link: {
      color: '#ffff',
      marginLeft: 32,
      fontSize: '14px !important',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    menuContainer: {
      marginLeft: 32,
    },
  }),
);
