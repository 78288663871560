import React from 'react';

import classNames from 'utils/classNames';
import './scss/errorText.scss';

interface Props {
  children?: React.ReactChild;
  className?: string;
}

const ErrorText = ({ children, className }: Props) => (
  <span className={classNames('components_inputs_error_text', className)}>{children}</span>
);

export default ErrorText;
