import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  echelonHeaderContainer: {
    display: 'flex',
    padding: '20px 25px 20px 60px',
    alignItems: 'center',
    width: '100%',
  },
  echelonHeaderRoot: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  titleEchelonVisual: {
    color: theme.palette.success.dark,
    fontSize: 30,
    marginLeft: 50,
    marginRight: 46,
    fontWeight: 'normal',
  },
  textEchelonVisual: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  badgeEchelonValueSrc: {
    width: 41,
  },
}));
