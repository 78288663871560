import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const Row = ({ color, fill, ...rest }: Props) => (
  <svg {...rest} width="130" height="9" viewBox="0 0 130 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="130" height="9" rx="4.5" fill={color || fill} />
  </svg>
);

export default Row;
