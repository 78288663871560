import { makeStyles } from '@material-ui/core/styles';
import createBadgeHeader from 'assets/svg/create-badge-header.svg';

export default makeStyles((theme) => ({
  imageContainer: {
    backgroundImage: `url(${createBadgeHeader})`,
    height: 360,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  titleContainer: {
    textShadow: '0px 4px 4px #3D1A8A',
  },
  numberCircleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberCircle: {
    borderRadius: '50%',
    width: 40,
    height: 40,
    fontFamily: 'Rakkas',
    color: theme.palette.primary.main,
    fontSize: 30,
    background: theme.palette.success.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  disabledCircle: {
    background: theme.palette.success.light,
  },
  imageBackground: {
    background: '#FFD1E2',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#000',
    opacity: '0.2',
  },
}));
