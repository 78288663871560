import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'hooks/useInputs';
import { isStringEmpty } from 'utils/validation';
import classNames from 'utils/classNames';
import useKeys from 'hooks/useKeys';

import AddPropForm from 'components/forms/AddPropForm/AddPropForm';
import TextInput from 'components/inputs/TextInput';
import Select from 'components/inputs/Select';
import ErrorText from 'components/inputs/ErrorText';
import Questionnaire, { QuestionnaireValues } from 'components/forms/QuestionForm';
import Modal from 'components/common/Modal/Modal';

import questionCriteria from 'assets/svg/picto-critere-question.svg';
import checked from 'assets/svg/checked.svg';
import image from 'assets/svg/image.svg';
import pdf from 'assets/svg/pdf.svg';
import questionnaire from 'assets/svg/questionnaire.svg';
import text from 'assets/svg/text.svg';
import cancel from 'assets/svg/cancel.svg';

import './scss/criteriaForm.scss';

export interface CriteriaValues {
  title: string;
  type: string;
  description: string;
  questions?: QuestionnaireValues[];
}

interface Props extends Partial<CriteriaValues> {
  onSubmit: (values: CriteriaValues) => void;
  initialOpen?: boolean;
  onDelete?: () => void;
  done?: boolean;
  resetOnSubmit?: boolean;
  resetOnClose?: boolean;
  errorText?: string;
  titleForm?: React.ReactChild | React.ReactChild[];
  borderForm?: boolean;
  addFormPropBorder?: string;
  TextTitleRowClassName?: string;
  addFormPropComponentClassName?: string;
  deleteCriteria?: () => void;
  showDelete?: boolean;
}

const CriteriaForm = ({
  onSubmit,
  title,
  type,
  description,
  initialOpen,
  done,
  onDelete,
  resetOnSubmit,
  resetOnClose,
  deleteCriteria,
  showDelete,
  questions,
  errorText,
  titleForm,
  borderForm,
  addFormPropBorder,
  TextTitleRowClassName,
  addFormPropComponentClassName,
}: Props) => {
  const [open, setOpen] = useState(!!initialOpen);
  const [state, actions] = useForm({
    initialValues: {
      title: title || '',
      type: type || '',
      description: description || '',
      questions: questions || [
        {
          answer: '' || [''],
          question: '',
          type: '',
          options: [''],
        },
      ],
    },
    // required: ['title', 'type'],
    validation: {
      // description: isStringEmpty,
      questions: (q, values) =>
        (values.type === 'questionnaire' && q.length === 0 ? 'Il faut ajouter au moins une question' : ''),
    },
  });

  const { values, errors, touched } = state;
  const {
 handleChange, validateForm, setAllTouched, setValues,
} = actions;
  const [openQuestion, setOpenQuestion] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const questionCounter = useRef([] as QuestionnaireValues[]);
  const [keys, addKey, deleteKey] = useKeys(values.questions);

  useEffect(() => {
    if (resetOnClose) {
      setValues({ title: title || '', type: type || '', description: description || '' });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (errorText) {
      setAllTouched(true);
    }
    // eslint-disable-next-line
  }, [errorText]);

  useEffect(() => {
    if (values.type === 'questionnaire') {
      actions.setErrors({
        description: '',
        questions: values.questions.length === 0 ? 'Il faut ajouter au moins une question' : '',
      });
      setOpenQuestion(true);
    } else {
      actions.setErrors({
        questions: ''
      });
    }
    // eslint-disable-next-line
  }, [values.type]);

  useEffect(() => {
    if (openQuestion && !values.questions.length) {
      setValues({
        questions: [
          {
            answer: '' || [''],
            question: '',
            type: '',
            options: [''],
          },
        ],
      });
    }
    // eslint-disable-next-line
  }, [openQuestion, values.questions.length]);

  function handleSubmit() {
    if (validateForm()) {
      const formValue = { ...values };
      if (values.type !== 'questionnaire') {
        delete formValue.questions;
      }
      onSubmit(formValue);
      if (done) {
        setOpen(false);
      }
      if (resetOnSubmit) {
        setValues({ title: '', type: '', description: '' });
        setAllTouched(false);
      }
    } else {
      setAllTouched(true);
    }
  }

  function addNewQuestion() {
    addKey();
    setValues({
      questions: [
        ...values.questions,
        {
          answer: '',
          question: '',
          type: '',
          options: [''],
        },
      ],
    });
  }
  function modifyQuestion(nextValues: QuestionnaireValues, index: number) {
    questionCounter.current[index] = nextValues;

    if (values.questions.length === questionCounter.current.filter(Boolean).length) {
      setValues({ questions: [...questionCounter.current] });
      setOpenQuestion(false);
    }
  }

  const onToggle = () => {
    setOpenQuestion(true);
  };
  const handleClose = () => {
    setOpenQuestion(false);
    setValues({ questions: values.questions.filter((question) => question.question !== '') });
  };

  function renderQuestion() {
    return (
      <div className="flex_column margin_top_8">
        {values.questions.map((question, index) => (
          <AddPropForm
            popUpTitle="Supprimer la question"
            popUpDescription="Êtes vous certain.e de vouloir supprimer cette question"
            popUptext="et conserver la question"
            done
            onToggle={onToggle}
            onDelete={() => {
              deleteKey(index);
              const nextQuestions = values.questions.filter((c, i) => i !== index);
              setValues({
                questions: nextQuestions,
              });
            }}
            title={question.question}
            titleForm={(
              <div className="title_question_container">
                <img alt="" height={30} src={questionCriteria} className="question_form_image" />
                {' '}
                <span>
                  Question
                  {index + 1}
                </span>
              </div>
            )}
            borderForm
            // eslint-disable-next-line
            key={keys[index]}
          />
        ))}
      </div>
    );
  }

  return (
    <AddPropForm
      done={done}
      onToggle={() => setOpen(true)}
      onDelete={onDelete}
      initialOpen={initialOpen}
      title={title}
      open={open}
      errorText={errorText}
      titleForm={titleForm}
      borderForm={borderForm}
      popUpTitle="Supprimer le critère"
      popUpDescription="Êtes vous certain.e de vouloir supprimer ce critère?"
      popUptext="et conserver le critère"
      addFormPropBorder={addFormPropBorder}
      TextTitleRowClassName={TextTitleRowClassName}
      addFormPropComponentClassName={addFormPropComponentClassName}
    >
      <div className={classNames('components_criteria_form')}>
        <div className="content_container flex_column">
          <div className="first_row flex_row">
            <TextInput
              required
              name="title"
              value={values.title}
              onChange={handleChange}
              className="row_input"
              label="Intitulé du critère"
              errorText={touched.title && errors.title}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onFocus={(e) => {
                e.stopPropagation();
              }}
            />

            <Select
              required
              name="type"
              className="row_input"
              value={values.type}
              onChange={handleChange}
              label="Type de critère"
              errorText={touched.type && errors.type}
              options={[
                {
                  value: 'text',
                  label: 'Texte (pour le nom des activités)',
                  icon: <img src={text} alt="" />,
                },
                {
                  value: 'image',
                  label: 'Image (png/jpeg)',
                  icon: <img src={image} alt="" />,
                },
                { value: 'pdf', label: 'Fichiers (pdf)', icon: <img src={pdf} alt="" /> },
                {
                  value: 'questionnaire',
                  label: 'Questionnaire',
                  icon: <img src={questionnaire} alt="" />,
                },
              ]}
            />
          </div>

          <TextInput
            onClick={(e) => {
              e.stopPropagation();
            }}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            required
            label="Description du critère "
            multiline
            rows={4}
            name="description"
            value={values.description}
            onChange={handleChange}
            errorText={touched.description && errors.description}
            className="margin_top_8"
          />
          {values.type === 'questionnaire' && renderQuestion()}

          <Modal
            title="Questionnaire"
            open={openQuestion}
            handleClose={handleClose}
            description={`Créez votre questionaire pour le critère ${values.title}`}
            done={done}
            addNewQuestion={addNewQuestion}
            onSubmit={() => {
              questionCounter.current = [];
              setIsSubmit(true);
            }}
          >
            {values.questions.map((question, index) => (
              <Questionnaire
                onSubmitDone={() => setIsSubmit(false)}
                titleNumber={index + 1}
                onSubmit={(nextValues) => modifyQuestion(nextValues, index)}
                resetOnSubmit
                // initialOpen
                {...question}
                key={index}
                isSubmit={isSubmit}
                resetOnClose
                done
              />
            ))}
          </Modal>

          {touched.questions && errors.questions && <ErrorText className="error_text">{errors.questions}</ErrorText>}
          <div className="submit_button_criteria_container margin_top_8">
            {showDelete && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  deleteCriteria && deleteCriteria();
                }}
                className="submit_button_criteria margin_right_25"
              >
                <img className="checked_icon" width={21} height={25} src={cancel} alt="" />
                <span>Annuler ce critère</span>
              </div>
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit();
              }}
              className="submit_button_criteria"
            >
              <img className="checked_icon" src={checked} alt="" />
              <span>{done ? 'Modifier ce critère' : 'Ajouter ce critère'}</span>
            </div>
          </div>
        </div>
      </div>
    </AddPropForm>
  );
};

export default CriteriaForm;
