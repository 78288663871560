import React, { useState, useEffect } from 'react';
import SliderBase, { SliderProps } from '@material-ui/core/Slider/Slider';

import classNames from 'utils/classNames';

import './scss/slider.scss';

interface Props extends SliderProps {
  value: number;
  // TODO: fix this (broken with 4.11 ver)
  onChange: any;
  //onChange: (e: React.ChangeEvent<any>, value: number | number[]) => void;
  label?: string;
}

const Slider = ({ onChange, className, label, value, style, ...other }: Props) => {
  const [inputValue, setInputValue] = useState<any>(`${value}`);

  useEffect(() => {
    if (inputValue.indexOf(value) === -1) {
      setInputValue(`${value}`);
    }
    // eslint-disable-next-line
  }, [value]);

  /* function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line
    let { value } = e.target;
    value = value.replace(',', '.');
    if (value.startsWith('0')) value = value.slice(1);
    if (!value) value = '0';
    if (/^-?\d*\.?\d*$/.test(value)) {
      const number = Number(value);

      if (number >= 0 && number <= 100) {
        setInputValue(value);
        onChange(e, number);
      }
    }
  } */

  return (
    <div className={classNames('components_ui_slider flex_row width_100', className)}>
      {label && <span className="slider_label">{label}</span>}
      <div style={style} className="flex_1">
        <SliderBase {...other} value={value} onChange={onChange} className={'slider_root'} />
      </div>
      {/*         <input size={inputValue.length} className="slider_input" value={inputValue} onChange={onInputChange} />
       */}
    </div>
  );
};

export default Slider;
