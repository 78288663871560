import { axiosPatch, Response, axiosPostFilesData, axiosPost, axiosGet, axiosDelete } from './http';
import { Network, Social } from './network';
import { User } from './auth';

interface IUserTypeSocial {
  name: string;
  path: string;
  _id: string;
}

export interface UserType {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  fullName: string;
  role: string;
  structure: string;
  userType: string;
  age: string;
  completed: number;
  social: { social: IUserTypeSocial; link: string }[];
  image: string;
  active: boolean;
  activeToken: string;
  forgotToken: string;
  website?: string;
}
export interface Detentteur {
  firstName: string;
  lastName: string;
  age?: string;
  name?: string;
}

export const updateUser = (
  data: {
    email: string;
    password?: string;
    oldPassword?: string;
    firstName: string;
    lastName: string;
    structure: string;
    age: string;
    website?: string;
  },
  id: string,
): Promise<Response<User>> => axiosPatch(`/users/${id}`, { data });
export interface ISocial {
  _id: string;
  link: string;
  social: string;
}

export const updateImageUser = (data: { file: File }): Promise<Response<User>> => {
  const formData = new FormData();
  formData.append('file', data.file);
  return axiosPostFilesData('users/upload', { data: formData });
};

export const addSocial = (data: {
  social: {
    social: Social;
    link: string;
  }[];
}): Promise<Response<UserType>> => axiosPost('/users/addSocial', { data });

export const updateSocial = (data: {
  id: string;
  social: {
    social: Social;
    link: string;
  }[];
}): Promise<Response<UserType>> => axiosPatch(`/users/updateSocials/${data.id}`, { data });

export const getSocialUser = (id: string): Promise<Response<Network[]>> => axiosGet(`/users/userSocial/${id}`);
export const getImageUser = (id: string): Promise<Response<any>> => axiosGet(`/users/userImage/${id}`);
export const getDetentteur = (id: string): Promise<Response<Detentteur[]>> =>
  axiosGet(`/badge/detenteurs/${id}`);
export const deleteSocial = (id: string): Promise<Response<any>> => axiosDelete(`/users/deleteSocial/${id}`);
export const getUser = (id: string): Promise<Response<UserType>> => axiosGet(`/users/${id}`);
export const getIsuerUser = (id: string): Promise<Response<UserType>> => axiosGet(`/users/issuer/${id}`);