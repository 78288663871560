import React, { FunctionComponent, useMemo } from "react";

import useApiState from 'hooks/useApiState';
import { useDidMount } from 'hooks/useLifeCycle';
import { ListModel, Model } from 'requests/model';

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

import classNames from 'utils/classNames';

import './scss/modelSelect.scss';
import Title from '../common/Title/Title';
interface Props {
  onChange: (id: Model | null) => void;
  className?: string;
  value: Model | null;
}

const ModelSelect: FunctionComponent<Props> = ({ onChange, className, value }) => {
  const [state, getListModel] = useApiState(ListModel);

  useDidMount(() => {
    getListModel();
  });

  const models = useMemo(() => {
    return state.data?.filter((v) => v.type !== 'engagement') || [];
  }, [state.data]);

  const modelsCivicService = useMemo(() => {
    return state.data?.filter((v) => v.type === 'engagement') || [];
  }, [state.data]);

  return (
    <div>
      {models && modelsCivicService ? (
        <div>
          <div
            className={classNames(
              'components_modals_model_select height_100 width_100 flex_row',
              !state.data && 'flex_center',
              className,
            )}
          >
            {models.map((model) => (
              <div
                className={classNames('model flex_center', model._id === value?._id && 'selected_model')}
                key={model._id}
              >
                <img onClick={() => onChange(model)} src={model.file} alt="model" />
              </div>
            ))}
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginTop: '1.5em', marginBottom: '1em' }}>
              <Title title="Service Civique" size={30} />
            </div>
            <div
              className={classNames(
                'components_modals_model_select height_100 width_100 flex_row',
                !state.data && 'flex_center',
                className,
              )}
            >
              {modelsCivicService.map((model) => (
                <div
                  className={classNames('model flex_center', model._id === value?._id && 'selected_model')}
                  key={model._id}
                >
                  <img onClick={() => onChange(model)} src={model.file} alt="model" />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default ModelSelect;
