import React, { useContext } from 'react';
import Text from 'components/ui/Text';
import userContext from 'contexts/userContext';
import moment from 'moment';
import CategorieLogoConatiner from 'components/badge/CategorieLogo';
import { Badge } from 'requests/badges';
import classNames from 'utils/classNames';
import useStyles from './styles';

require('moment/locale/fr');

interface Props {
  data: Badge;
  disabledHeight?: boolean;
  className?: string;
  onClick?: () => void;
  perTree?: boolean;
}
const MiniCard = ({ data, disabledHeight, className, onClick, perTree }: Props) => {
  const classes = useStyles();
  const { user } = useContext(userContext);
  let isOwner = false;
  if (data.issuerId) {
    isOwner = user?._id.toString() === data.issuerId._id.toString();
  }
  return (
    <div style={{ height: disabledHeight ? "auto" : "", width: perTree ? "calc(100% / 3.33)" : "" }} className={classNames(classes.card_container, className)} onClick={onClick}>
      <div className={classes.header_card}>
        {data.mainCategory && (
          <CategorieLogoConatiner
            className={classes.logo_categorie_header}
            label={data.mainCategory.name}
            bgColor={data.mainCategory.background}
          />
        )}
        {data.secondaryCategory && (
          <CategorieLogoConatiner
            className={classes.logo_categorie_header}
            label={data.secondaryCategory.name}
            bgColor={data.secondaryCategory.background}
          />
        )}
      </div>
      <div className={classes.image_container}>
        <div className={classes.image_content}>
          <img alt="image_badge" src={data?.image} className={classes.img} />
        </div>
      </div>
      <div className={classes.title}>
        <Text label={data.title} className={classNames('text_menu_component', classes.titleText)} />
      </div>
      <div className={classes.subTitle}>
        <Text label={data.description} className={classNames('text_secondaire_component', classes.ellepsisText)} />
      </div>
      <div className={classes.info_categorie_footer}>
        {data.issuerId && (
          <div className={classes.info_categorie}>
            <span style={{  }}><strong>Émetteur</strong>: {data.issuerId?.fullName}</span>
          </div>
        )}
        {isOwner && data.deliveranceCode && (
          <div className={classes.info_categorie}>
            <span><strong>Code: </strong>: {data.deliveranceCode}</span>
          </div>
        )}
        {data.createdAt && (
          <div className={classes.info_categorie}>
            <Text label={moment(data.createdAt).format('L')} className="text_pink_component" />
          </div>
        )}
      </div>
    </div>
  );
};
export default MiniCard;
