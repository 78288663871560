import React, { useState } from 'react';
import TextInput from 'components/inputs/TextInput';

import editImage from 'assets/svg/picto-edit.svg';
import deleteImage from 'assets/svg/grey-poubelle.svg';
import cheked from 'assets/svg/checked.svg';

import useStyles from './styles';
import classNames from 'utils/classNames';
interface Props {
  index: number;
  val?: string;
  handleOptionChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) => void;
  addOption: () => void;
  deleteOption: (i: number) => void;
  optionValues: string[];
  errorText?: () => void;
  onOptionAddFailed?: () => void;
}

const OptionForm = ({
  index,
  val,
  handleOptionChange,
  addOption,
  deleteOption,
  optionValues,
  onOptionAddFailed,
}: Props) => {
  const classes = useStyles();
  const [optionAdd, setOptionAdd] = useState(false);
  const [optionEdit, setOptionEdit] = useState(false);

  function renderOptionForm() {
    if (optionEdit)
      return (
        <div className={classes.optionInputContainer}>
          <TextInput
            required
            name="option"
            value={val}
            className="option_input"
            label={`Réponse ${index + 1} possible`}
            onChange={(e) => handleOptionChange(e, index)}
            requiredClassName={classes.requiredText}
            classes={{ root: classes.heightInput }}
            textInputClassName={classes.textInputClassName}
            placeholder="Tapez le texte de la réponse..."
          />
          {val && <img alt="" src={cheked} className={classes.checkedIconInput} />}
        </div>
      );
    if (optionAdd)
      return (
        <div className={classes.optionAddTextContainer}>
          <span className={classes.optionAddText}>
            Réponse possible {index + 1} <span className={classes.requiredText}>*</span> :
          </span>
          {val}
        </div>
      );

    return (
      <div className={classes.optionInputContainer}>
        <TextInput
          required
          name="option"
          value={val}
          className="option_input"
          label={`Réponse  ${index + 1} possible`}
          onChange={(e) => handleOptionChange(e, index)}
          requiredClassName={classes.requiredText}
          classes={{ root: classes.heightInput }}
          textInputClassName={classes.textInputClassName}
          placeholder="Tapez le texte de la réponse..."
        />
        {val && <img alt="" src={cheked} className={classes.checkedIconInput} />}
      </div>
    );
  }
  return (
    <div key={index} className={classNames('option_container', 'first_row', 'flex_row', classes.optionRoot)}>
      {renderOptionForm()}
      <div className={classes.optionContainer}>
        {!optionAdd && (
          <div
            className={classNames('submit_button_option', classes.checkedIconInput)}
            onClick={() => {
              if (val) {
                setOptionAdd(true);
                addOption();
              } else {
                onOptionAddFailed && onOptionAddFailed();
              }
            }}
          >
            Ajouter cette option
          </div>
        )}
        {optionAdd && !!optionValues.find((c, i) => i === index) && (
          <div className={classNames(classes.optionIcons, optionEdit && classes.checkedIconInput)}>
            <div
              className={classes.buttonOption}
              onClick={() => {
                setOptionEdit(!optionEdit);
              }}
              // disabled={values.options.length === 1}
            >
              <span className={classes.optionIconText}> Modifier</span>
              <img alt="" src={editImage} />
            </div>

            <div
              className={classes.buttonOption}
              onClick={() => {
                deleteOption(index);
              }}
              // disabled={values.options.length === 1}
            >
              <span className={classes.optionIconText}> Supprimer</span>
              <img alt="" src={deleteImage} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionForm;
