import React, { useEffect, useState, useRef } from 'react';
import forEach from 'lodash/forEach';
import TextInput from 'components/inputs/TextInput';
import Slider from 'components/ui/Slider';

import { useDidMount } from 'hooks/useLifeCycle';
import { getText, getElementTransform, setElementTransform } from 'utils/svg';

import './scss/editModelText.scss';
import classNames from 'utils/classNames';

/* eslint-disable react-hooks/exhaustive-deps */

interface Props {
  id: string;
  className?: string;
}

const EditModelText = ({ id, className }: Props) => {
  const [svgTitle, setSvgTitle] = useState('');
  const [textTransformX, setTextTransformX] = useState(0);
  const [textTransformY, setTextTransformY] = useState(0);
  const tspanY = useRef(23.52);

  function assembleText(element: any) {
    if (element.nodeName === '#text') return element.data.trim();
    let res = '';
    if (element.nodeName === 'tspan') {
      const y = element.getAttribute('y');
      if (y) {
        tspanY.current = y;
      }
      res += '\n';
      if (element.x && element.x.baseVal[0]) {
        for (let i = 0; i < Math.floor(element.x.baseVal[0].value); i += 2) {
          res += ' ';
        }
      }
    }

    forEach(element.childNodes, (node) => {
      res += assembleText(node as Element);
    });

    return res;
  }

  useDidMount(() => {
    const text = getText(id);
    if (text) {
      setSvgTitle(assembleText(text));
      const { x, y } = getElementTransform(text);

      setTextTransformX(x);
      setTextTransformY(y);
    }
  });

  useEffect(() => {
    const textElement = getText(id);
    if (textElement) {
      while (textElement.firstChild) {
        textElement.removeChild(textElement.firstChild);
      }
    }
    if (textElement) {
      svgTitle.split('\n').forEach((text, index) => {
        const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');

        tspan.setAttribute('y', `${tspanY.current * index}`);
        tspan.setAttribute('x', `${text.split(' ').findIndex((r) => r) * 2}`);
        tspan.textContent = text.trim();
        textElement.appendChild(tspan);
      });
    }
  }, [svgTitle]);

  useEffect(() => {
    const text = getText(id);
    if (text) {
      const { x, y } = setElementTransform(text, textTransformX, textTransformY);
      text.setAttribute('transform', `translate(${x}, ${y})`);
    }
  }, [textTransformX, textTransformY, svgTitle]);

  function handleTitleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault();
    setSvgTitle(e.target.value);
  }

  function onTextTransformXChange(e: React.ChangeEvent<any>, value: number | number[]) {
    setTextTransformX(value as number);
  }

  function onTextTransformYChange(e: React.ChangeEvent<any>, value: number | number[]) {
    setTextTransformY(value as number);
  }

  return (
    <div className={classNames('components_badge_edit_model_text flex_column', className)}>
      <div className="edit_model_text_sliders_container">
        <div className={classNames('edit_text_title text_margin')}>Positionnement de l’intitulé</div>
        <Slider label=" Horizontal :" onChange={onTextTransformXChange} value={textTransformX} />
        <Slider label="Vertical :" onChange={onTextTransformYChange} value={textTransformY} />
      </div>
      <div className="edit_text_title">Intitulé de l'Open Badge</div>
      <div style={{ marginBottom: 15 }}>Vous pouvez le mettre sur 2 lignes si nécessaire en appuyant sur Entrée</div>
      <TextInput className="width_100" multiline rows={2} value={svgTitle} onChange={handleTitleChange} />
    </div>
  );
};

export default EditModelText;
