import React, { useState } from 'react';

import BadgeVisualInput from 'components/inputs/BadgeVisualInput/BadgeVisualInput';
import EchelonInput from '../EchelonInput/EchelonInput';

import useStyles from './styles';
interface Props {
  echelon: string;
  children?: React.ReactChild | React.ReactChild[];
  index: number;
  value: Blob | null;
  modelId: string;
  onChange: (blob: Blob | null, modelId: string) => void;
  className?: string;
  errorText?: string;
}

const EchelonVisual = ({ echelon, index, value, modelId, onChange, className, errorText }: Props) => {
  const [valueSrc, setValueSrc] = useState('');
  const classes = useStyles();

  return (
    <EchelonInput
      index={index}
      echelon={echelon}
      rightComponent={valueSrc && <img alt='' height={42} width={41} src={valueSrc} />}
      className={classes.collapseContainer}
      collapseContainer={classes.collapseContainerClassName}
      errorText={errorText}
    >
      <BadgeVisualInput
        value={value}
        modelId={modelId}
        onChange={onChange}
        className={className}
        errorText={errorText}
        setValueSrc={setValueSrc}
        valueSrc={valueSrc}
      />
    </EchelonInput>
  );
};

export default EchelonVisual;
