import React, { useState, useEffect } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import localforage from 'localforage';
import UserContext from 'contexts/userContext';
import NotifContext from 'contexts/notifContext';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import useCreateUri from 'hooks/useCreateUri';

// hoc
import Route from 'layout/Route/Route';
import useApiState from 'hooks/useApiState';

// containers
import HomeContainer from 'containers/HomeContainer/HomeContainer';
import LoginContainer from 'containers/LoginContainer/Login';
import ForgotPassword from 'containers/ForgetPasswordContainer/ForgetPassword';
import BadgeDetail from 'containers/BadgeDetailsContainer/BadgeDetail';
import confirmUser from 'containers/confirmUser';
import AccountContainer from 'containers/AccountContainer';
import MarketplaceContainer from 'containers/MarketplaceContainer';
import ProfileContainer from 'containers/ProfileContainer/ProfileContainer';
import NotificationContainer from 'containers/NotificationContainer/NotifPanel';
import EditEndossement from 'containers/Endossement';
import Awardbadge from 'containers/AwardBadgeContainer/AwardBadgeContainer';
import InsignoContainer from 'containers/InsignoContainer';

// header
import MainHeaderChild, { mainRoutes } from 'layout/MainHeader/MainHeaderV2';
// experimental assets
import Register from 'containers/RegisterContainer/Register';
import reset from 'containers/ResetPassword';
// hooks
import { useDidMount } from 'hooks/useLifeCycle';
import { refreshToken, User } from 'requests/auth';
import { setAuthorizationBearer } from 'requests/http';
// assets
import createSocket from 'requests/socket';
import { Notif, getAllNotification } from 'requests/notif';
// eslint-disable-next-line import/no-extraneous-dependencies
import UIfx from 'uifx';
import mp3File from 'assets/sound/to-the-point.mp3';
import Button from 'components/buttons/Button';

import useStyles from './styles';
import PublicAssertion from '../PublicAssertionContainer/PublicAssertionContainer';
import PublicIssuerContainer from 'containers/PublicIssuerContainer';
import CandidatureContainer from 'containers/CandidatureContainer';
import Error404Container from 'containers/404Container/404Container';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#0C3D9C', light: '#5B78C2' },
    secondary: { main: '#DDF7FF', light: '#f1fcff' },
    info: { main: '#D42061' },
    background: {
      default: '#F4F5FF',
    },
    grey: { A400: '#434343', A700: '#333333', A100: '#828282', A200: '#F6F8F9' },
    success: { main: '#00C4D1', light: '#A4FCFC', dark: '#008EAD' },
    error: {
      main: '#F14E7F',
      light: '#FFD1E2',
    },
  },
  typography: {
    fontFamily: 'Gangster Grotesk',
  },
});
async function onStart(): Promise<User | null> {
  try {
    const auth: any = await localforage.getItem('auth');
    if (!auth) throw new Error('no user');
    const { token, user } = auth;
    const nextToken = await refreshToken({ email: user.email, refreshToken: token.refreshToken });
    if (nextToken.status === 'OK') {
      localforage.setItem('auth', { user, token: nextToken.data });
      setAuthorizationBearer(nextToken.data.accessToken);
      return user;
    }
    throw new Error('end');
  } catch (e) {
    return null;
  }
}
const RootContainer = () => {
  const location = useLocation();
  const [newNotif, setNotif] = useState<Notif[]>([]);
  const [allNotifState, allNotifCall] = useApiState(getAllNotification);

  const beep = new UIfx(mp3File);
  const [startup, setStartup] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const classes = useStyles();
  const history = useHistory();
  const openBadgeUri = useCreateUri(0);

  const [showMobile, setShowMobile] = useState(false);

  useDidMount(() => {
    onStart().then((nextUser) => {
      if (nextUser) setUser(nextUser);
      setStartup(true);
      allNotifCall();
    });
    history.listen(() => {
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    });
  });
  useEffect(() => {
    if (user) {
      createSocket(user, {
        update_candidature: (data: any) => {
          const newArray = [...newNotif];
          newArray.push(data);
          setNotif(newArray);
        },
        add_candidature: (data: any) => {
          const newArray = [...newNotif];
          newArray.push(data);
          setNotif(newArray);
        },
      });
    }
  }, [user, setNotif, newNotif]);
  useEffect(() => {
    if (allNotifState.data) {
      const l = allNotifState.data.filter((n) => n.isRead === false);
      setNotif(l);
    }
  }, [allNotifState.data]);

  history.listen((location, action) => {
    if (!showMobile) {
      setShowMobile(false);
    }
  });

  if (!startup) return <div />;

  const showMobileFunc = () => {
    setShowMobile(!showMobile);
  };

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={{ user, setUser }}>
        <NotifContext.Provider value={{ newNotif, setNotif }}>
          <div className={classes.containersRoot}>
            <Switch>
              <Route
                headerProps={{
                  children: (
                    <div className={classes.headerRoot}>
                      <div className={classes.headerImageContainer}>
                        <div className={classes.headerTextContainer}>
                          <p className={classes.headerText}>
                            DIVA est une plateforme d'<span className={classes.textBadge}>Open Badges</span> pour tous{' '}
                          </p>
                        </div>
                      </div>
                      <div className={classes.btnHomeContainer}>
                        <p className={classes.paragraph}>
                          À travers votre Open Badge, mettez en lumière vos talents, vos expériences, vos compétences et
                          partagez-le pour vous créer des opportunités et faire avancer vos projets.
                        </p>
                        <Button
                          size="large"
                          className={classes.btnHome}
                          onClick={() => history.push({ pathname: '/', search: openBadgeUri })}
                        >
                          Qu'est-ce qu'un Open Badge ?
                        </Button>
                      </div>
                    </div>
                  ),
                }}
                path="/"
                exact
                component={HomeContainer}
                homePage
              />
              <Route
                showMobile={showMobile}
                exact
                path="/insigno"
                component={InsignoContainer}
                headerType="private"
                headerProps={{
                  children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} />,
                }}
              />
              <Route path="/register" component={Register} />
              <Route path="/reset" component={reset} />
              <Route exact path="/login" component={LoginContainer} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/myaccount" component={AccountContainer} protected />
              <Route
                showMobile={showMobile}
                exact
                path="/awardBadge/:id"
                headerType="private"
                headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
                component={Awardbadge}
                protected
              />
              <Route
                exact
                path="/notification"
                component={NotificationContainer}
                protected
                headerType="private"
                showMobile={showMobile}
                headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
              />

              <Route
                exact
                path="/badge/:id"
                component={BadgeDetail}
                headerType="private"
                showMobile={showMobile}
                headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
              />
              <Route
                exact
                path="/candidature/:id/:idCandidature"
                component={CandidatureContainer}
                headerType="private"
                showMobile={showMobile}
                headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
              />
              <Route
                exact
                path="/badge/endossement/:id"
                headerType="private"
                showMobile={showMobile}
                headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
                component={BadgeDetail}
              />
              <Route exact path="/confirm" component={confirmUser} addFooter />
              <Route
                exact
                path="/marketplace"
                component={MarketplaceContainer}
                headerType="private"
                showMobile={showMobile}
                headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
              />
              <Route
                exact
                path="/endossement/:id/:token"
                headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
                headerType="private"
                showMobile={showMobile}
                component={EditEndossement}
                protected
              />
              <Route exact path={'/public/assertion/:assertionId'} headerType="public" component={PublicAssertion} />
              <Route exact path={'/public/issuer/:issuerId'} headerType="public" component={PublicIssuerContainer} />
              {mainRoutes.map((route) => (
                <Route
                  headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
                  exact
                  path={route.path}
                  component={route.component}
                  key={route.path}
                  showMobile={showMobile}
                  protected
                />
              ))}
              <Route
                headerProps={{ children: <MainHeaderChild showMobile={showMobile} mobile={showMobileFunc} /> }}
                exact
                path="/profile"
                showMobile={showMobile}
                component={ProfileContainer}
                key="/profile"
                protected
              />
              <Route component={Error404Container} />
            </Switch>
          </div>
        </NotifContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  );
};
export default RootContainer;
