import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    optionContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    optionRoot: {
      marginTop: 24,
    },
    optionIcons: {
      display: 'flex',
    },
    optionAddTextContainer: {
      fontSize: 20,
      display: 'flex',
      flexDirection: 'column',
    },
    optionIconText: {
      marginRight: 10,

      fontWeight: 'bold',
      fontSize: 16,
      color: theme.palette.grey.A100,
      marginLeft: 30,
    },
    buttonOption: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    optionAddText: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      marginBottom: 20,
    },
    optionInputContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    requiredText: {
      color: '#dd4774 !important',
    },
    checkedIconInput: {
      display: 'flex',
      alignSelf: 'flex-end',
      marginBottom: 24,
      marginLeft: 25,
      cursor: 'pointer',
    },
    heightInput: {
      height: 73,
      width: '577px !important',
    },
    textInputClassName: {
      '&> div': {
        height: '100%',
      },
    },
  }),
);
