import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme, { size?: number }>((theme) => ({
  echelonRowContainer: {
    display: 'grid',
    gridTemplateAreas: '"image description "',
    gridTemplateColumns: '168px 1fr',
    gridGap: '80px',
    alignItems: 'center',
  },
  imageContainer: {
    gridArea: 'image',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  image: {
    height: (props) => (props.size ? props.size : 'auto'),
    width: (props) => (props.size ? props.size : 'auto'),
  },
  description: {
    gridArea: 'description',
  },
  echelonText: {
    fontSize: 20,
    margin: 0,
    marginBottom: 40,
  },
  echelonButton: {
    background: theme.palette.success.main,
    color: theme.palette.primary.main,
    padding: '0px !important',
    fontWeight: 'bold',

    '&:hover': {
      background: theme.palette.success.light,
    },
  },
}));
