import React, { useState, useEffect, useRef, useContext } from 'react';
import { RouteComponentProps, useParams, Link, useHistory } from 'react-router-dom';
import Text from 'components/ui/Text';

import userContext from 'contexts/userContext';
import Button from 'components/buttons/Button';
import LogoCompetence from 'components/badge/CategorieLogo';
import { CritereCard } from 'components/cards/CritereCard/CritereCard';
import moment from 'moment';
import { getBadge, getBadgeChilds, Badge } from 'requests/badges';

import useApiState from 'hooks/useApiState';
import Checkmark from 'assets/svg/checked.svg';

import { getAllEndossementById } from 'requests/candidature';
import Loader from 'components/ui/Loader';
import useStyles from './style';
import { getAward } from '../../requests/award';
import AddLogo from '../../assets/svg/defaultUser_v3.svg';
import EvidenceCard from './EvidenceCard';
import Modal from "@material-ui/core/Modal";

require('moment/locale/fr');

interface Props {
  ParentRoute?: string;
}

type IVariant = 'success' | 'warning' | 'error' | 'info';
const PublicAssertion = () => {
  const classes = useStyles();
  const history = useHistory();
  const [badgeState, callGetBadge] = useApiState(getBadge);
  const [childs, getchilds] = useApiState(getBadgeChilds);
  const [awardState, callGetAward] = useApiState(getAward);
  const [imgUrl, setImgUrl] = useState('');
  const [modalImg, setModalImg] = useState(false);

  const [endossementArray, setEndossementArray] = useState([]);
  const [fetchingAll, changeFetchingAll] = useState(false);

  const { assertionId } = useParams<any>();

  useEffect(() => {
    if (assertionId) {
      callGetAward(assertionId);
    }
  }, [assertionId, callGetAward, callGetBadge]);

  useEffect(() => {
    if (awardState.data) {
      callGetBadge(awardState.data?.idBadge._id);
    }
  }, [awardState.data, callGetBadge]);

  useEffect(() => {
    if (badgeState.fetching || childs.fetching || awardState.fetching) changeFetchingAll(true);
    else {
      changeFetchingAll(false);
    }
  }, [badgeState.fetching, childs.fetching, awardState.fetching]);

  useEffect(() => {
    if(badgeState.errorCode || childs.errorCode || awardState.errorCode) {
      history.push('/404')
    }
  }, [badgeState.errorCode, childs.errorCode, awardState.errorCode])

  useEffect(() => {
    if (awardState.data) {
      const array = (awardState.data as any)?.endorsements;
      setEndossementArray(array.filter((v: any) => v.status === "accepted"));
    }
  }, [awardState.data]);

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref && ref.current && ref.current.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  });

  const changeImgUrl = (url: string) => {
    setImgUrl(url);
    setModalImg(true);
  };
  const clearImgUrl = () => {
    setImgUrl('');
    setModalImg(false);
  };

  return (
    <>
    <div className={classes.badge_modal_conatiner}>
      {fetchingAll && (
        <div className={classes.backdrop}>
          <Loader />
        </div>
      )}
      <div className={classes.badge_details_header} />
      <div className={classes.badge_empty_container} style={{ height: height - 113 }} />
      <div className={classes.blue_mask} style={{ height: height + 400 }} />
      <div className={classes.badge_modal_content} ref={ref}>
        <div className={classes.badge_modal_header}>
          <div className={classes.badge_image_container}>
            <img
              src={`${process.env.REACT_APP_API_URL}/assertions/${awardState.data?._id}.png`}
              alt="logo"
              className={classes.badge_image}
            />
          </div>
          <div className={classes.badge_texts_container}>
            <div className={classes.badge_detail_title_container}>
              <Text label={badgeState.data?.title} className={classes.badge_detail_title} />
            </div>
            <div className={classes.badge_detail_description_container}>
              <Text label={badgeState.data?.description} className={classes.badge_detail_description} />
            </div>
            <div className={classes.btn_tags_container}>
              {badgeState.data?.tags.map((el) => (
                <Button key={el} variant="contained" className={classes.btn_tags}>
                  <Link to={`/marketplace?search=${el.replace('#', '')}`}>
                    <span className={classes.btn_tags_label}>#{el}</span>
                  </Link>
                </Button>
              ))}
            </div>
          </div>
          <div className={classes.badge_detail_info_container}>
            <div className={classes.info_container}>
              <Text label="Badge obtenu par : " className={classes.badge_detail_info_label} />
              <div className={classes.info_detenteur_container}>
                <div className={classes.imageUserCandidateur}>
                  <img alt="" src={awardState.data?.idUser?.image || AddLogo} className={classes.img} />
                </div>
                <Text label={` ${awardState.data?.idUser?.fullName}`} className={classes.badge_detail_info_value} />
              </div>
            </div>
            <div className={classes.info_container}>
              <Text label="Émetteur : " className={classes.badge_detail_info_label} />
              <div className={classes.info_detenteur_container}>
                <Link className={classes.issuerLinkWrapper} to={'/public/issuer/' + badgeState.data?.issuerId?._id}>
                  <div className={classes.imageUserCandidateur}>
                    <img alt="" src={badgeState.data?.issuerId?.image || AddLogo} className={classes.img} />
                  </div>
                  <Text label={` ${badgeState.data?.issuerId?.fullName}`} className={classes.badge_detail_info_value} />
                </Link>
              </div>
            </div>
            <div className={classes.info_container}>
              <Text label="Date d’obtention : " className={classes.badge_detail_info_label} />
              <Text
                label={awardState.data ? moment(awardState.data?.createdAt).format('L') : '-'}
                className={classes.badge_detail_info_value}
              />
            </div>
          </div>
        </div>
        <div className={classes.badge_detail_container}>
          <div className={classes.badge_details}>
            <>
              {(childs.data?.length || 0) > 0 && (
                <div>
                  <div className={classes.badge_criteres_title_container}>
                    <Text label="Echelons" className={classes.badge_criteres_title} />
                    <div className={classes.badge_criteres_title}>({childs.data?.length})</div>
                  </div>
                  <div className={classes.sub_badge_container}>
                    {childs.data?.map((v: Badge) => (
                      <div key={v._id}>
                        <div className={classes.bar} />
                        <div className={classes.sub_badge}>
                          <img className={classes.sub_badge_image} src={v.image} alt={v.title} />
                          <div className={classes.sub_badge_info}>
                            <strong className={classes.sub_badge_info_title}>{v.title}</strong>
                            <div className={classes.sub_badge_info_description}>{v.description}</div>
                          </div>
                          <div>
                            <Link to={`/badge/${v._id}`}>
                              <Button className={classes.sub_badge_btn}>Voir le badge</Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={classes.critere_badges}>
                <div className={classes.badge_criteres_title_container}>
                  <Text label="Critères" className={classes.badge_criteres_title} />
                  <div className={classes.badge_criteres_title}>({badgeState.data?.criterion.length})</div>
                </div>
                <div className={classes.bar} />
                <div>
                  {badgeState.data?.criterion.map((el, index) => (
                    <div className={classes.critere_item} key={el._id}>
                      <CritereCard
                        id={el._id}
                        question={el.questions}
                        title={el.title}
                        description={el.description}
                        type={el.type}
                        index={index + 1}
                        codeExist={undefined}
                        candidature={false}
                        handleResult={() => null}
                        onchangeChecked={() => null}
                      />
                      <EvidenceCard
                        user={awardState.data?.idUser}
                        evidence={awardState.data?.idCandidature?.criterions.find((v: any) => v.idCritere === el._id)}
                        onChangeImgUrl={changeImgUrl}
                      />
                      <div className={classes.bar} />
                    </div>
                  ))}
                </div>
                {endossementArray && endossementArray.length > 0 && (
                  <div>
                    <div style={{ display: 'flex', marginTop: '2em' }}>
                      <div className={classes.badge_criteres_title}>
                        Endosseurs ({endossementArray.length ?? 0})
                      </div>
                    </div>
                    <div style={{ marginTop: 18 }}>
                      {endossementArray
                        .map((e: any) => (
                          <div
                          style={{
                            margin: '5px 0px',
                            backgroundColor: '#ddf7ff',
                            padding: 14,
                            borderRadius: 10,
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 18 }}>
                              <img style={{ height: 48, borderRadius: 5 }} src={e?.idEndosseur?.image} />
                            </div>
                            <div style={{ flexShrink: 1 }}>
                              <div style={{ display: 'flex' }}>
                                <span style={{}}>
                                  <strong>{e.idEndosseur?.fullName}</strong> • {e.email}{' '}
                                  {e.idEndosseur?.website && (
                                    <span>
                                      •{' '}
                                      <a
                                        style={{ color: '#F14E7F' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={e.idEndosseur?.website}
                                      >
                                        {e.idEndosseur?.website}
                                      </a>
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div style={{ marginTop: 8 }}>
                            <span style={{ flexShrink: 1 }}>
                            {e.text}
                            </span>
                          </div>
                        </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
      <Modal open={modalImg}>
        <div className={classes.ModalImgContainer}>
          <div className={classes.modalContent}>
            <div onClick={clearImgUrl} className={classes.closeBtn}>
              Close
            </div>
            <img src={imgUrl} alt="imgToZoom" className={classes.imgModal} />
          </div>
        </div>
      </Modal>
      </>
  );
};
export default PublicAssertion;
