import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'utils/classNames';
import Menu from 'components/common/Menu/Menu';

import useCreateUri from 'hooks/useCreateUri';

import diva from 'assets/svg/divapublic.svg';

import userContext from 'contexts/userContext';

import useStyles from './styles';
import MobileHeader from 'layout/MobileHeader/MobileHeader';
interface Props {
  children?: React.ReactChild;
  homePage?: boolean;
  mobile?: boolean;
}

const PublicHeader = ({ children, homePage }: Props) => {
  const classes = useStyles({ homePage });

  const { user } = useContext(userContext);
  const links = [
    {
      label: 'QU’EST CE QUE C’EST ?',
      path: { pathname: '/', search: useCreateUri(0) },
    },
    {
      label: 'OBTENIR DES OPEN BADGES',
      path: { pathname: '/', search: useCreateUri(1) },
    },
    {
      label: 'ÉMETTRE DES OPEN BADGES ',
      path: { pathname: '/', search: useCreateUri(2) },
    },
    /*     { label: 'INSCRIPTION / CONNEXION', path: '/login' },
     */
  ];
  return (
    <>
      <AppBar position={homePage ? 'static' : 'fixed'} className={classNames(classes.appBar, classes.appBarHomePage)}>
        <Toolbar className={classNames(classes.toolbarContainer, homePage && classes.toolbarHomePage)}>
          {homePage ? (
            <div className={classes.headerContainer}>
              <div className={classes.headerImageContainer}>
                <img className={classes.image} src={diva} alt="" />
                <span className={classes.imageText}>DISPOSITIF DE VALORISATION DES ACQUIS</span>
              </div>
              <div className={classes.linksContainer}>
                {links.map((link, i) => {
                  return (
                    <Link key={i} className={classes.link} to={link.path}>
                      {link.label}
                    </Link>
                  );
                })}

                {user ? (
                  <Menu menuContainerClassName={classes.menuContainer} />
                ) : (
                  <Link
                    className={classes.link}
                    style={{ color: '#A4FCFC', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                    to={'/login'}
                  >
                    Inscription / Connexion
                  </Link>
                )}
              </div>
            </div>
          ) : (
            <Link to="/">
              <img src={diva} alt="" />
            </Link>
          )}
        </Toolbar>

        {children}
      </AppBar>
      <MobileHeader />
    </>
  );
};

export default PublicHeader;
