import React from 'react';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';
import classNames from 'utils/classNames';
import cancel from 'assets/images/cancel.png';
import cheked from 'assets/svg/checked.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import PasswordEye from 'assets/svg/passwordEye.svg';

import useStyles from './styles';
import { FormControlLabel, Radio } from '@material-ui/core';

interface IRadios {
  key: string;
  value: string;
}

interface IProps extends Omit<any, 'variant' | 'error'> {
  label?: string;
  error?: string;
  className?: string;
  required?: boolean;
  name?: string;
  value?: string;
  radios: Array<IRadios>;
  onChangeValue?: (value: string) => void;
}

const RadioButton = ({
  label,
  error,
  name,
  value,
  required,
  className,
  radios,
  onChangeValue,
  ...rest
}: IProps) => {
  const classes = useStyles({ error, value });

  return (
    <div className={classNames(classes.root, className)}>
      {label && (
        <div className={classes.label}>
          {label}
          {required ? <span>*</span> : null}
        </div>
      )}
      <div>
        {radios.map((r, i) => (
           <FormControlLabel checked={r.key === value} value={r.key} control={<Radio color="primary" />} label={r.value} onClick={() => onChangeValue?.call(null, r.key)} />
        ))}
        <div className={classes.errorContainer}>
          <span style={{ margin: "-12px 0" }} className={classNames(classes.errorMessage)}>{error}</span>
        </div>
      </div>
      <div className={classes.check}>
        {error && <img src={cancel} alt="" />}
        {value && !error && radios.find((v) => v.key === value) && <img src={cheked} alt="" />}
      </div>
    </div>
  );
};

export default RadioButton;
