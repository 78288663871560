import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  critere_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  critere_details_container: {
    width: '100%',
    display: 'flex',
  },
  critere_infos_container: {
    width: 640,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 44,
  },
  critere_name_container: {
    display: 'flex',
  },
  critere_index: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Rakkas',
    fontSize: 25,
    lineHeight: '24px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    width: 27,
    height: 27,
    borderRadius: '50%',
    marginRight: 17,
  },
  critere_name: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '16px',
    color: '#0c3d9c !important',
  },
  critere_description_container: {
    width: 595,
    paddingLeft: 44,
  },
  critere_description: {
    fontFamily: 'Gangster Grotesk',
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'justify',
    color: theme.palette.grey.A400,
    wordBreak: "break-all"
  },
  critere_preuve_container: {
    width: 225,
    display: 'flex',
    flexDirection: 'column',
  },
  preuve_type_label: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '28px',
    color: theme.palette.grey.A400,
  },
  preuve_type_container: {
    width: '100%',
    display: 'flex',
    marginBottom: 20,
  },
  preuve_type: {
    fontFamily: 'Gangster Grotesk',
    fontSize: 14,
    lineHeight: '23px',
    color: theme.palette.error.main,
    marginLeft: 10,
    textTransform: 'capitalize',
  },
  preuve_image: {
    width: 24,
    height: 24,
  },
  btn_response: {
    width: 155,
    height: 53,
    background: theme.palette.success.main,

    borderRadius: 10,
    '&:hover': {
      background: theme.palette.success.light,
    },
  },
  btn_response_label: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  question_popup_modal: {
    width: '100%',
    height: '100%',
    padding: '33px 41px 71px 124px',
  },
  question_popup_header: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2000,
  },
  question_modal_content: {
    width: '85%',
    height: '85%',
    backgroundColor: 'white',
    position: 'relative',
    padding: '40px',
    borderRadius: 5,
  },
  wrapperInfo: {
    overflow: 'scroll',
    height: '100%',
    paddingBottom: 80,
  },
  close_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  question_popup_close: {
    width: 35,
    height: 35,
    marginRight: 21,
    cursor: 'pointer',
  },
  criteria_title_container: {
    width: '100%',
    paddingBottom: 30,
  },
  criteria_title: {
    fontFamily: 'Rakkas',
    fontSize: 30,
    lineHeight: '28px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  validation_btn_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 44,
  },
  add_critere_container: {
    width: '100%',
    margin: '15px 0',
  },
  critere_input_container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  input: {
    display: 'none',
  },

  upload_image_container: {
    display: 'flex',
    marginLeft: 44,
    cursor: 'pointer',
  },
  upload_image_icon: {
    width: 28,
    height: 20,
    marginRight: 19,
  },
  upload_image_label: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.primary.light,
  },
  accepted_img_format_container: {
    marginLeft: 91,
  },
  accepted_img_format_label: {
    fontFamily: 'Gangster Grotesk',
    fontSize: 14,
    lineHeight: '21px',
    color: theme.palette.grey.A400,
  },
  critere_input_url: {
    marginBottom: 30,
  },
  critere_input_text: {
    marginLeft: 44,
  },
  input_url: {
    width: '100%',
  },
  input_text_label: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.grey.A400,
    marginTop: 25,
    marginBottom: 16,
  },
  input_text: {
    width: '795px !important',
    height: 98,
    borderRadius: 10,
    border: 'transparent !important',
    marginBottom: 20,
  },
  critere_input_questionnaire: {
    width: 835,
  },
  errorMessage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.error.main,
  },
  input_file: {
    display: 'none',
  },
  critere_header: {
    flexDirection: 'column',
    [theme.breakpoints.down(750)]: {
      flexDirection: 'column',
    },
  },
  textRequired: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
    marginLeft: 64,
  },
  requiredMark: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.info.main,
  },
}));
