import React from 'react';
import './scss/label.scss';
import classNames from 'utils/classNames';

interface Props extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  required?: boolean;
  requiredClassName?: string;
}
const Label = ({ children, className, required, requiredClassName, ...rest }: Props) => {
  return (
    // eslint-disable-next-line
    <div {...rest} className={classNames('components_inputs_label', className)}>
      {children}
      {required && <span className={classNames('components_inputs_required_field', requiredClassName)}> *</span>}
    </div>
  );
};

export default Label;
