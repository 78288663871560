import Button from 'components/buttons/Button';
import React from 'react';

import useStyles from './styles';

import { useHistory } from 'react-router';

const Error404Container = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.center}>
      <h1 className={classes.text}>La page recherchée n'existe pas.</h1>
      <Button onClick={() => {
          history.push('/');
      }} className={classes.backButton} size="large" variant="contained">
        Retourner sur DiVA
      </Button>
    </div>
  );
};

export default Error404Container;
