import React, { useState, useEffect } from 'react';

import classNames from 'utils/classNames';
import Slider from 'components/ui/Slider';

import { getIcon, getElementTransform, setElementTransform } from 'utils/svg';

import './scss/editModelIcon.scss';

interface Props {
  className?: string;
  id: string;
  path?: string;
}

const EditModelIcon = ({ className, id, path }: Props) => {
  const [transformX, setTransformX] = useState(0);
  const [transformY, setTransformY] = useState(0);

  useEffect(() => {
    const icon = getIcon(id);

    if (icon) {
      const { x, y } = getElementTransform(icon);
      setTransformX(x);
      setTransformY(y);
    }
    // eslint-disable-next-line
  }, [path]);

  useEffect(() => {
    const icon = getIcon(id);
    if (icon) {
      const { x, y } = setElementTransform(icon, transformX, transformY);
      const transform = icon.getAttribute('transform') || '';
      icon.setAttribute('transform', `${transform.slice(0, transform.indexOf('translate'))} translate(${x}, ${y})`);
    }
    // eslint-disable-next-line
  }, [transformX, transformY]);

  function onTransformXChange(e: React.ChangeEvent<any>, value: number | number[]) {
    setTransformX(value as number);
  }
  function onTransformYChange(e: React.ChangeEvent<any>, value: number | number[]) {
    setTransformY(value as number);
  }

  return (
    <div className={classNames('components_badge_edit_model_icon flex_column', className)}>
      <div className="edit_icon_title">Positionnement du pictogramme</div>

      <div className="flex_column">
        <Slider label="Horizontal :" value={transformX} onChange={onTransformXChange} />
        <Slider label="Vertical :" value={transformY} onChange={onTransformYChange} />
      </div>
    </div>
  );
};

export default EditModelIcon;
