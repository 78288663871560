import { makeStyles } from "@material-ui/core";
import { fontSize } from "@material-ui/system";

export default makeStyles((theme) => ({
    center: {
        textAlign: "center",
        margin: "25px 0"
    },
    text: {
        fontFamily: 'Rakkas',
        fontSize: 40,
        lineHeight: '38px',
        color: theme.palette.primary.main,
    },
    backButton: {
        margin: "15px 0",
        background: theme.palette.error.main,
        '&:hover': {
          background: theme.palette.info.main,
        },
      },
}));