import io from 'socket.io-client';
import { User } from 'requests/auth';

function createSocket(
  user: User,
  events: { update_candidature: (data: any) => void; add_candidature: (data: any) => void },
) {
  const options: SocketIOClient.ConnectOpts = {
    rememberUpgrade: true,
    transports: ['websocket'],
    secure: true,
    rejectUnauthorized: false,
    reconnectionAttempts: 10,
    reconnectionDelay: 2000,
    reconnectionDelayMax: 10000
  };
  const socket = io.connect('wss://api-dev.diva-badges.fr:5153', options);
  socket.on(`update_candidature_${user._id}`, events.update_candidature);
  socket.on(`add_candidature_${user._id}`, events.add_candidature);
}
export default createSocket;
