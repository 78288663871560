import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  connexionTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffedf3',
    padding: '50px 0px 40px 0px',
  },
  titleContainer: {
    marginBottom: 8,
  },
  titlesContainer: {
    zIndex: 1,
  },
  rowColor: {
    backgroundColor: '#F14E7F',
  },
  container_notif_panel: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '30px 0px',
  },
  data_notif_container: {
    padding: '0px 20px',
    marginBottom: '5px',
    borderBottom: '1px solid #F14E7F',
    width: '80%',
    display: 'flex',
    alignItems: 'baseline',
  },
  backgroundWhite: {
    backgroundColor: '#e8e8e8',
  },
  backgroundGray: {
    backgroundColor: '#F14E7F',
  },
  circleContainer: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    marginRight: 16,
  },
  link: {
    color: '#F14E7F',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  withOutMargin: {
    paddingTop: '0px !important',
  },
}));
