import React from 'react';

import Button from 'components/buttons/Button';
import classNames from 'utils/classNames';

import useStyles from './styles';
interface Props {
  children?: React.ReactChild;
  echelonSrc?: string;
  echelonText?: string;
  onClick?: () => void;
  buttonClassName?: string;
  imageClassName?: string;
  size?: number;
  className?: string;
}
const EchelonRow = ({
  echelonSrc,
  echelonText,
  children,
  onClick,
  className,
  buttonClassName,
  imageClassName,
  size,
}: Props) => {
  const classes = useStyles({ size });
  return (
    <div className={classNames(classes.echelonRowContainer, className)}>
      <div className={classes.imageContainer}>
        <img alt='' src={echelonSrc} className={classNames(classes.image, imageClassName)} />
      </div>
      <div className={classes.description}>
        <p className={classes.echelonText}>{echelonText}</p>
        <Button max size="large" className={classNames(classes.echelonButton, buttonClassName)} onClick={onClick}>
          {children}
        </Button>
      </div>
    </div>
  );
};

export default EchelonRow;
