import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  connexionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  connexionTitle: {
    height: 186,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  connexionRoot: {
    display: 'flex',
    padding: '100px 150px',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.light,
    flex: 1,
    width: '100%',
    minHeight: 700,
  },
  input: {
    marginBottom: 40,
    marginTop: 17,
  },
  linkContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 384px 1fr',
  },

  forgetPassword: {
    color: theme.palette.info.main,
    fontSize: 16,
    fontWeight: 'bold',
    gridColumn: '2/2',
  },
  checkbox: { gridColumn: '2/2', marginTop: 55 },
  submitButton: {
    gridColumn: '2/2',
    width: 284,
    marginTop: 60,
    fontSize: 17,
  },
  registerLink: {
    gridColumn: '2/2',
    marginTop: 37,
    width: 284,

    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  textRequired: {
    fontSize: 18,
    fontWeight: 'bold',
    gridColumn: '2/2',
  },
  requiredMark: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));
