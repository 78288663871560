import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme, { error?: string; value?: string }>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 384px 1fr',
    gridGap: '23px',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
    position: 'relative',
  },
  label: {
    gridColumn: '1/1',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
  },
  inputRoot: {
    gridColumn: '2/2',
    // overflow: 'hidden',
    borderRadius: 5,
  },
  check: {
    gridColumn: '3/3',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  inputBase: {
    height: 46,
    width: 384,
    borderRadius: 5,
    background: '#FFFFFF',
    '& fieldset': {
      border: (props) => {
        let color = '#E0E0E0';
        if (props.error) color = theme.palette.info.main;
        else if (props.value) color = theme.palette.success.main;
        return `1px solid ${color}`;
      },
    },
  },
  input: {
    fontSize: 14,
    flex: 1,
    '&::placeholder': {
      color: '#434343 !important',
      fontSize: 14,
      WebkitTextStroke: '#434343',
      WebkitTextStrokeWidth: '0.5px',
      opacity: 1,
    },
  },
  errorContainer: {
    position: 'absolute',
    display: 'grid',
    gridTemplateColumns: '1fr 384px 1fr',
    top: 'calc(100% + 5px)',
    height: 20,
    width: '100%',
    left: 0,
  },
  errorMessage: {
    color: theme.palette.info.main,
    gridColumn: '2/2',
    width: '100%',
  },
  showPasswordImage: {
    cursor: 'pointer',
  },
}));
