import React from 'react';
import Text from 'components/ui/Text';
import classNames from 'utils/classNames';
import useStyles from './style';

interface Props {
  className?: string;
  img: string;
  name: string;
}
const BoxRating = ({ img, name }: Props) => {
  const classes = useStyles();
  return (
    <div className={classNames('flex_row', classes.card_container_rating)}>
      <div className={classes.image_container_rating}>
        <img alt="image_badge" src={img} className={classes.img} />
      </div>
      <div className={classes.title_rating}>
        <Text label={name} className={classes.text_menu_component} />
      </div>
    </div>
  );
};
export default BoxRating;
