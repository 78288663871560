import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  connexionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  connexionTitleRegister: {
    height: 186,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  connexionRoot: {
    display: 'flex',
    padding: '100px 150px',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.light,
    flex: 1,
    minHeight: 700,
  },
  input: {
    marginBottom: 37,
  },
  linkContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 384px 1fr',
  },
  registerButtonContainer: {
    gridColumn: '2/2',
    display: 'flex',
  },
  registerButtonRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  forgetPassword: {
    color: theme.palette.info.main,
    fontSize: 16,
    fontWeight: 'bold',
    gridColumn: '2/2',
  },
  checkbox: { gridColumn: '2/2' },
  submitButton: {
    gridColumn: '2/2',
    width: 284,
    marginTop: 60,
    fontSize: 17,
    padding: '0px !important',
  },
  registerLink: {
    gridColumn: '2/2',
    marginTop: 37,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  inputPicker: {
    display: 'grid',
    gridTemplateColumns: '1fr 384px 1fr',
    gridGap: '23px',
    alignItems: 'center',
    width: '100%',
    marginBottom: 37,
  },
  labelPicker: {
    gridColumn: '1/1',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
  },
  option: {
    gridColumn: '2/2',
    display: 'flex',
  },
  optionWrapper: {
    marginRight: 25,
    marginTop: 10,
  },
  optionItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 384px 1fr',
    fontSize: 16,
    margin: '27px 0px',
  },
  checkOption: {
    color: theme.palette.primary.main,
    textDecoration: 'line-through',
  },
  checkboxLabel: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  textRequired: {
    fontSize: 18,
    gridColumn: '2/2',
    fontWeight: 'bold',
  },
  requiredMark: {
    color: theme.palette.primary.main,
  },
  label: {
    gridColumn: '1/1',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
  },
}));
