import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  profile_container: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentProfile: { width: 1080 },
  connexionTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    marginBottom: 8,
  },
  titlesContainer: {
    marginTop: 30,
  },
  headContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logoutLogo: {
    margin: '0px 10px 0px 15px',
  },
  logout: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    color: '#5B78C2',
    cursor: 'pointer',
  },
  containerBtnNavigate: {
    width: 162,
    height: 27,
    background: theme.palette.success.light,
    borderRadius: 5,
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  contentBtnNavigate: {
    textAlignLast: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    color: '#5B78C2',
  },
  notifContainer: {
    width: '100%',
    backgroundColor: '#FFD1E2',
    height: 78,
    display: 'flex',
    justifyContent: 'center',
  },
  notifContent: {
    maxWidth: 1080,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textNotif: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: '#FF5C97',
    cursor: 'pointer',
  },
  textNotifGrayContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 90,
  },
  lengthNotif: {
    width: 27,
    height: 27,
    backgroundColor: theme.palette.error.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 24,
    borderRadius: '50%',
    marginRight: 13,
  },
  textNotifGray: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#434343',
  },
  boldTextNotif: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    margin: '0px 4px',
  },
  closeImg: {
    width: 11,
    height: 11,
    marginRight: 10,
  },
}));
