import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  question_card_container: {
    width: '100%',
    display: 'flex',
  },
  item_container: {
    width: '50%',
  },
  answer_container: {},
  text_input_wrapper: {
    padding: '40px 0',
    display: 'flex',
  },
  icon_question_container: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    paddingRight: 24,
  },
  icon_question: {},
  multiple_wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  question_label: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    color: '#0c3d9c',
    paddingBottom: 44,
  },
  radio: {},
  checkbox: {
    marginBottom: 20,
  },
}));
