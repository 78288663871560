import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card_container: {
    width: 251,
    backgroundColor: '#ddf7ff',
    border: '6px solid rgba(123, 91, 194, 0.5)',
    boxSizing: 'border-box',
    borderRadius: 5,
    position: 'relative',
    cursor: 'pointer',
  },
  header_card: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    width: 84,
    top: -18.9,
    right: 0,
  },
  logo_categorie_header: {
    marginRight: 14,
    width: 34,
    height: 34,
    '&:first-child': {
      marginRight: 0,
    },
  },
  image_container: {
    display: 'flex',
    justifyContent: 'center',
    height: 90,
    alignItems: 'center',
    paddingTop: 40,
  },
  image_content: {
    width: 85,
    height: 85,
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 86,
    marginTop: 20,
  },
  titleText: {
    width: 179,
    textAlign: 'center',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    wordBreak: "break-all",
    overflow: "hidden"
  },
  subTitle: {
    overflow: 'hidden',
    fontFamily: 'Gangster Grotesk',
    fontSize: 14,
    lineHeight: 17,
    textAlign: 'center',
    color: '#434343',
    maxWidth: 218,
    minHeight: 56,
    marginLeft: 17,
    marginRight: 17,
  },
  ellepsisText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  info_categorie_footer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 10,
  },
  info_categorie: {
    textAlign: "center",
    fontSize: 14,
    marginBottom: 5,
    maxWidth: 215,
    wordBreak: "break-all",
 
    margin: "0 5px 5px 0"
  },
  card_text_bold: {
    fontWeight: 'bold',
    marginRight: 5,
  },
}));
