/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';

import Text from 'components/ui/Text';
import Graph from 'components/Graph/PieChart';
import RatingBox from 'components/profile/BoxRating/BoxRating';
import StarRose from 'assets/profile/calque-4@3x.svg';
import userContext from 'contexts/userContext';
import StarYellow from 'assets/profile/Badge_Etoile.svg';
import classesNames from 'utils/classNames';
import { getUser } from 'requests/user';
import useApiState from 'hooks/useApiState';
import { useDidMount } from 'hooks/useLifeCycle';
import useStyles from './style';

const RatingProfile = () => {
  const classes = useStyles();
  const [getUserState, getUserCall] = useApiState(getUser);
  const { user } = useContext(userContext);

  useDidMount(() => {
    if (user) {
      getUserCall(user._id);
    }
  });
  const data = [
    {
      name: 'J’ai créé mon compte',
      img: getUserState.data ? (getUserState.data.completed >= 1000 ? StarYellow : StarRose) : StarRose,
    },
    {
      name: 'J’ai obtenu mon 1er Open Badge',
      img: getUserState.data ? (getUserState.data.completed >= 1100 ? StarYellow : StarRose) : StarRose,
    },
    {
      name: 'J’ai créé un Open Badge',
      img: getUserState.data ? (getUserState.data.completed >= 1010 ? StarYellow : StarRose) : StarRose,
    },
    {
      name: 'J’ai endossé un Open Badge',
      img: getUserState.data ? (getUserState.data.completed === 1111 ? StarYellow : StarRose) : StarRose,
    },
  ];
  const getValue = () => {
    let res = 0;
    if (getUserState.data) {
      switch (getUserState.data.completed) {
        case 1000:
          res = 25;
          break;
        case 1100:
          res = 50;
          break;
        case 1010:
          res = 50;
          break;
        case 1110:
          res = 75;
          break;
        case 1111:
          res = 100;
          break;
        default:
          res = 25;
      }
    }
    return res;
  };
  return (
    <div className={classes.rating_component_container}>
      <div className={classesNames(classes.graphContainer, 'flex_column')}>
        <Text label="Profil complété" className={classes.sub_title_component} />
        <Graph data={getValue()} />
        <Text label={`${Number(getValue())}%`} className={classesNames(classes.sub_title_component, classes.font)} />
      </div>
      <div className={classes.rating_card_container}>
        {data.map((el) => (
          <RatingBox key={el.name} img={el.img} name={el.name} />
        ))}
      </div>
    </div>
  );
};
export default RatingProfile;
