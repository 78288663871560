import React, { FormEvent, useContext, useState } from 'react';
import localforage from 'localforage';
import { RouteComponentProps, Redirect, Link } from 'react-router-dom';
import userContext from 'contexts/userContext';

import { useForm } from 'hooks/useInputs';
import { useDidUpdate } from 'hooks/useLifeCycle';
import useApiState from 'hooks/useApiState';

import { login } from 'requests/auth';
import { setAuthorizationBearer } from 'requests/http';

import Checkbox from 'components/common/Checkbox/Checkbox';
import Button from 'components/buttons/Button';
import Title from 'components/common/Title/Title';
import Input from 'components/common/Input/Input';
import CustomizedSnackbars, { IVariant } from 'components/ui/SnackBar';

import { decodeUri } from 'utils/url';
import { validateEmail } from 'utils/validation';

import useStyles from './styles';

const LoginContainer = ({ location }: RouteComponentProps) => {
  const classes = useStyles();

  const { user, setUser } = useContext(userContext);
  const [loginState, loginCall] = useApiState(login);

  const [formState, formActions] = useForm({
    initialValues: {
      email: '',
      password: '',
      stayConnected: true,
    },
    validation: {
      email: validateEmail,
    },
    required: ['email', 'password'],
  });

  const { values, errors, touched } = formState;
  const { handleChange } = formActions;

  const [openModal, setOpenModal] = useState(false);
  const [variantModal, setVariantModal] = useState<IVariant>('success');
  const [textModal, setTextModal] = useState('');

  const [showPasswordState, setShowPassword] = useState(false);

  useDidUpdate(() => {
    if (!loginState.fetching && loginState.data) {
      if (values.stayConnected) {
        localforage.setItem('auth', loginState.data);
      }
      setAuthorizationBearer(loginState.data.token.accessToken);
      setUser(loginState.data.user);
    } else if (loginState.errorCode) {
      setOpenModal(true);

      setVariantModal('error');
      setTextModal("Nom d'utilisateur et/ou mot de passe invalide!");
    }
  }, [loginState.fetching]);

  if (user) {
    const { from } = decodeUri(location.search);
    return <Redirect to={from || '/profile'} />;
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (formActions.validateForm()) {
      loginCall(formState.values);
    } else {
      formActions.setTouched({ email: true, password: true });
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onShowPassword = () => {
    setShowPassword(!showPasswordState);
  };

  return (
    <div className={classes.connexionContainer}>
      <div className={classes.connexionTitle}>
        <Title row title="Connexion" />
      </div>
      <form onSubmit={onSubmit} className={classes.connexionRoot}>
        <Input
          label="Email"
          required
          name="email"
          onChange={handleChange}
          value={values.email}
          autoComplete="off"
          error={touched.email ? errors.email : ''}
        />
        <Input
          label="Mot de passe "
          required
          name="password"
          type={!showPasswordState ? 'password' : ''}
          value={values.password}
          onChange={handleChange}
          showPassword={() => onShowPassword()}
          autoComplete="off"
          error={touched.password ? errors.password : ''}
          className={classes.input}
        />
        <div className={classes.linkContainer}>
          <Link to="/forgot-password" className={classes.forgetPassword}>
            J’ai oublié mon mot de passe
          </Link>
          <Checkbox
            name="stayConnected"
            checked={values.stayConnected}
            onChange={handleChange}
            label="Se souvenir de moi"
            className={classes.checkbox}
          />

          <div className={classes.textRequired}>
            <span className={classes.requiredMark}>* </span>
            <span> champs obligatoires </span>
          </div>

          <Button type="submit" fetching={loginState.fetching} size="large" className={classes.submitButton}>
            Connexion
          </Button>

          <Link to="/register" className={classes.registerLink}>
            Je n’ai pas encore de compte{' '}
          </Link>
        </div>
      </form>
      <CustomizedSnackbars variant={variantModal} open={openModal} handleClose={handleCloseModal} message={textModal} />
    </div>
  );
};

export default LoginContainer;
