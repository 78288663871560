import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    createBadgeTypeContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    type_container: {
      width: 252,
      height: 268,
      padding: 25,
      backgroundColor: theme.palette.success.light,
      borderRadius: 4,
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      //flex: '1 1 0%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    typeContainerSelected: {
      backgroundColor: theme.palette.success.main,
      '&:before': {
        content: '',
        borderRadius: 4,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    renderType: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 30,
      marginLeft: 30,
      alignItems: 'center',
    },
    text: {
      color: theme.palette.grey.A400,
      fontSize: 20,
      margin: '50px 0px 20px 0px',
    },
    consigne: {
      color: theme.palette.grey.A100,
      fontSize: 18,
      marginBottom: '50px',
      width: 680,
      textAlign: 'center',
    },
    textWrapper: {
      display: 'flex',
      marginBottom: 86,
    },
    typeTitle: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 16,
      marginTop: 20,
    },
    title: {
      fontWeight: 'normal',
    },
  }),
);
