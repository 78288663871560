import React from 'react';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';
import classNames from 'utils/classNames';
import cancel from 'assets/images/cancel.png';
import cheked from 'assets/svg/checked.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import PasswordEye from 'assets/svg/passwordEye.svg';

import useStyles from './styles';

interface IProps extends Omit<OutlinedTextFieldProps, 'variant' | 'error'> {
  label?: string;
  error?: string;
  className?: string;
  required?: boolean;
  name?: string;
  value?: string;
  inputClassName?: string;
  inputBaseClassName?: string;
  inputRootClassName?: string;
  showPassword?: () => void;
  optionnalText?: boolean;
}

const Input = ({
  label,
  error,
  name,
  value,
  required,
  className,
  inputClassName,
  inputBaseClassName,
  inputRootClassName,
  showPassword,
  optionnalText,
  ...rest
}: IProps) => {
  const classes = useStyles({ error, value });

  return (
    <div style={{ marginBottom: optionnalText ? 50 : "" }} className={classNames(classes.root, className)}>
      {label && (
        <div className={classes.label}>
          {label}
          {required ? <span>*</span> : null}
        </div>
      )}
      <div className={classNames(classes.inputRoot, inputRootClassName)}>
        <TextField
          InputProps={{
            classes: {
              input: classNames(classes.input, inputClassName),
              root: classNames(classes.inputBase, inputBaseClassName),
            },
            endAdornment: (
              <InputAdornment position="end">
                {showPassword && (
                  <img src={PasswordEye} alt="view" onClick={showPassword} className={classes.showPasswordImage} />
                )}
              </InputAdornment>
            ),
          }}
          value={value}
          name={name}
          error={!!error}
          {...rest}
          variant="outlined"
        />
        <div className={classes.errorContainer}>
          {optionnalText && (
            <span style={{ color: 'rgb(150,150,150)', fontSize: 14 }} className={classNames(classes.errorMessage)}>
              Optionnel
            </span>
          )}
          <span className={classNames(classes.errorMessage)}>{error}</span>
        </div>
      </div>
      <div className={classes.check}>
        {error && <img src={cancel} alt="" />}
        {value && !error && <img src={cheked} alt="" />}
      </div>
    </div>
  );
};

export default Input;
