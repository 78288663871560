import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },

  backdrop: {
    background: '#011A5E !important',
    opacity: 0.5,
  },

  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 15,
    width: '100%',
    height: '100%',
    padding: 50,
    overflow: 'auto',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    width: '100%',
    maxWidth: 980,
    marginBottom: 54,
  },

  cancel: {
    display: 'flex',
    alignSelf: 'flex-end',
    height: 30,
    width: 30,
    cursor: 'pointer',
  },

  description: {
    margin: 0,
    marginBottom: 48,
    marginTop: 56,
    fontSize: 20,
  },
  title: {
    fontSize: 30,
  },
  addTextContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginTop: 90,
    cursor: 'pointer',
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 12,
  },
  submitButtonQuestion: {
    padding: '0px !important',
    fontWeight: 'bold',
    width: 309,
  },
  textRequired: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
  },
  requiredMark: {
    marginRight: 5,
    color: theme.palette.info.main,
  },
}));
