import { axiosGet, Response } from './http';

export interface Category {
  _id: string;
  name: string;
  description: string;
  background: string;
}

export const getCategories = (): Promise<Response<Category[]>> => axiosGet('/categories');
export interface badges {
  _id: string;
  title: string;
  descripption: string;
  mainCategory: string;
  secondaryCategory: string;
  modelBadge: string;
  resumeCriterion: string;
  startDate: string;
  endDate: string;
  autoDelivrate: boolean;
  tags: string;
}

export interface Icategories {
  _id: string;
  name: string;
  description: string;
  badges: badges[];
}

export const getCategoriesRandom = (): Promise<Response<Icategories[]>> => axiosGet('/categories/random');
