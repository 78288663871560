import { makeStyles } from '@material-ui/core/styles';
import BadgeBanner from "../../assets/svg/BadgeBanner.svg";
import BadgeBanner2 from "../../assets/svg/BadgeBanner2.svg";

export default makeStyles((theme) => ({
  badge_modal_conatiner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {},
  badge_details_header: {
    width: '100%',
    height: 422,
    backgroundImage: `url(${BadgeBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  badge_details_header_2: {
    width: '100%',
    height: 422,
    backgroundImage: `url(${BadgeBanner2})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  badge_empty_container: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  blue_mask: {
    width: '100%',
    backgroundColor: theme.palette.primary.light,
    opacity: 0.4,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  badge_modal_content: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    top: 200,
    width: 978,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    padding: '24px 38px 81px 57px',
    zIndex: 2,
  },
  competence_logos: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  textBack: {
    fontSize: 18,
  },
  logoBack: {
    width: 30,
    height: 30,
  },
  categorieWrapper: {
    display: 'flex',
  },
  competence_logo_container: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 47,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  competence_logo: {
    height: 35,
    width: 35,
  },
  competence_logo_name: {
    fontFamily: 'Gangster Grotesk',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.grey.A400,
    marginLeft: 16,
    textTransform: 'capitalize',
  },
  badge_modal_header: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    paddingTop: 50,
    paddingBottom: 70,
    backgroundColor: 'white',
  },
  badge_image_container: {
    width: 180,
    height: 200,
    alignSelf: 'center'
  },
  badge_image: {
    maxWidth: 180,
    maxHeight: 200,
  },
  badge_texts_container: {
    width: 380,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 45,
    marginRight: 45,
  },
  badge_detail_title_container: {
    /*  marginTop: 20,
    marginBottom: 20, */
  },
  badge_detail_title: {
    fontFamily: 'Rakkas',
    fontSize: 40,
    lineHeight: '38px',
    color: theme.palette.primary.main,
    wordBreak: "break-all"
  },
  badge_detail_description_container: {
    marginTop: 20,
    marginBottom: 20,
  },
  badge_detail_description: {
    fontFamily: 'Gangster Grotesk',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.grey.A400,
    wordBreak: 'break-word',
  },
  btn_tags_container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 380,
  },
  btn_tags: {
    height: 31,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.error.light,
    borderRadius: '10px',
    marginRight: 15,
    marginBottom: 10,
    '&:last-child': {
      marginLeft: 0,
    },
    '&:hover': {
      background: theme.palette.error.light,
    },
  },
  btn_tags_label: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.error.main,
    paddingBottom: 3,
  },
  badge_detail_info_container: {
    alignSelf: 'start'
  },
  name_container: {
    display: 'flex',
  },
  info_container: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  badge_detail_info_label: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '23px',
    color: theme.palette.grey.A400,
  },
  badge_detail_info_value: {
    fontFamily: 'Gangster Grotesk',
    fontSize: 14,
    lineHeight: '23px',
    color: theme.palette.error.main,
    marginLeft: 5,
    maxWidth: 218,
    wordBreak: "break-all"
  },
  badge_detail_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  badge_details: {
    /* paddingLeft: 226,
    paddingRight: 108, */
  },
  critere_badges: {
    paddingBottom: 40,
  },
  sub_badge_container: {
    marginBottom: "2em",
    display: "flex",
    flexDirection: "column",
  },
  sub_badge: {
    display: "flex",
  },
  sub_badge_info: {
    flex: 1,
    paddingLeft: "1.5em",
  },
  sub_badge_info_title: {
    fontFamily: 'Gangster Grotesk',
    fontSize: 16,
    lineHeight: 2,
    color: theme.palette.primary.main,
  },
  sub_badge_info_description: {
    fontFamily: 'Gangster Grotesk',
    wordBreak: "break-all"
  },
  sub_badge_image: {
    width: 85,
    height: 85
  },
  sub_badge_btn: {
    height: "3em",
  },
  badge_criteres_title_container: {
    display: 'flex',
  },
  badge_criteres_title: {
    fontFamily: 'Rakkas',
    fontSize: 25,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  bar: {
    width: 835,
    border: '1px solid #DDF7FF',
    marginTop: 20,
    marginBottom: 20,
  },
  critere_item: {
    marginTop: 25,
  },

  btn_recomandation_bottom: {
    width: '100%',
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40,
  },
  btn_badge: {
    width: 321,
    height: 70,
    background: theme.palette.success.main,
    color: theme.palette.primary.main,
    borderRadius: 10,
    margin: '0px 14px',
    '&:hover': {
      background: theme.palette.success.light,
    },
    '&:disabled': {
      background: theme.palette.success.main,
      opacity: 0.5,
    },
  },
  btn_partager_badge: {
    height: 70,
    background: theme.palette.success.main,
    color: theme.palette.primary.main,
    borderRadius: 10,
    margin: '0px 14px',
    '&:hover': {
      background: theme.palette.success.light,
    },
    '&:disabled': {
      background: theme.palette.success.main,
      opacity: 0.5,
    },
  },
  btn_badge_label: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
  },
  endosser_btn_badge: {
    width: 321,
    height: 70,
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    borderRadius: 10,
    margin: '0px 14px',
    display: 'block',
  },
  telecharger_badge: {
    color: theme.palette.primary.main,
    display: 'block',
    fontWeight: 'bold',
    alignSelf: 'center',
    fontSize: 18,
  },
  endossement_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  endossement_title: {
    fontFamily: 'Rakkas',
    fontSize: 25,
    lineHeight: '25px',
    color: theme.palette.primary.main,
  },
  moyen_endossement_container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: 27,
  },
  select_email_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  endossement_subtitle: {
    fontFamily: 'Gangster Grotesk',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: '#434343',
  },
  email_select: {
    width: 400,
    marginRight: 27,
  },
  email_select_input: {
    color: '#0C3D9C',
  },
  email_endosseur: {
    width: 405,
    marginTop: 18,
  },
  link_endossement: {
    color: 'var(--cherry)',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  endossement_button: {
    margin: '40px 0',
    width: '30%',
  },
  // comment
  demandeList: {
    padding: '0px 0px 30px 0px',
  },
  candidatureItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '20px 0px',
  },
  imageUserCandidateur: {
    width: 60,
    height: 60,
    marginRight: 30,
  },
  img: {
    width: '100%',
    height: '100%',
  },
  tagTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FF5C97',
    marginTop: 0,
    marginBottom: 0,
  },
  text_candidature: {
    borderRadius: 10,
    background: '#eefbff',
    padding: '13px 10px',
    marginTop: 5,
    minHeight: 99,
  },
  critere_title: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px ',
    color: theme.palette.primary.main,
    marginTop: 0,
    marginBottom: 0,
  },
  descriptionText: {
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.grey.A400,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginTop: 25,
    marginLeft: 15,
  },
  create_badges_button: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  refuser_badges_button: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  textBtn: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  textBtnRefuser: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#fff',
  },
  ModalImgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    width: '70%',
    height: '80%',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 5,
  },
  imgModal: {
    width: '100%',
    height: '100%',
  },
  previewImg: {
    width: 167,
    height: 83,
    cursor: 'pointer',
    marginTop: 10,
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    color: '#FF5C97',
  },
  linkPdf: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: 10,
  },
  textRequired: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 20,
  },
  requiredMark: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  critere_input_url: {
    marginBottom: 30,
  },
  input_url: {
    width: '100%',
  },
  badge_owned_pill: {
    height: 31,
    width: 220,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.success.light,
    color: theme.palette.success.dark,
    borderRadius: '5px',
    marginTop: 15,
    '&:last-child': {
      marginLeft: 0,
    }
  },
}));
