import React, { useEffect, useRef, useState } from 'react';

import useApiState from 'hooks/useApiState';
import { useForm } from 'hooks/useInputs';
import { useDidMount, useDidUpdate } from 'hooks/useLifeCycle';
import classNames from 'utils/classNames';

import { getCategories } from 'requests/categories';
import { getDomains } from 'requests/domain';
import useKeys from 'hooks/useKeys';

import TextField from 'components/inputs/TextInput';
import Select, { Option } from 'components/inputs/Select';
import TagsInput from 'components/inputs/TagsInput';
import CheckBox from 'components/common/Checkbox/Checkbox';
import Button from 'components/buttons/Button';
import EchelonForm, { EchelonFormValues } from 'components/forms/EchelonForm/EchelonForm';
import Title from 'components/common/Title/Title';
import TabNavigation from 'components/ui/TabNavigation/TabNavigation';
import CategorieLogo from 'components/badge/CategorieLogo';

import iconEchelon from 'assets/svg/badge-echelons.svg';
import badgeEchelon from 'assets/svg/badge-echelons-visual.svg';

import useStyles from './styles';

export interface FeatureValues {
  tags: string[];
  title: string;
  mainCategory: string;
  secondaryCategory: string;
  description: string;
  public: boolean;
  echelons: EchelonFormValues[];
}

interface Props {
  onSubmit: (values: FeatureValues) => void;
  initialValues: Partial<FeatureValues>;
  className?: string;
  title?: string;
  simple?: boolean;
}

const CreateBadgeFeatures = ({
 onSubmit, initialValues, className, title, simple,
}: Props) => {
  const classes = useStyles();

  const required: (keyof FeatureValues)[] = ['tags', 'title', 'mainCategory', 'description', 'secondaryCategory'];
  if (!simple) {
    required.push('echelons');
  }
  const [step, setStep] = useState(0);
  const validateEchelon = (echelons: EchelonFormValues[]) => {
    if (simple) return '';
    if (step === 0) return '';
    return echelons.length ? '' : 'Il faut ajouter au moins un echelon';
  };
  const [state, actions] = useForm({
    initialValues: {
      tags: [] as string[],
      title: '',
      mainCategory: '',
      secondaryCategory: '',
      echelons: [] as EchelonFormValues[],
      description: '',
      public: true,
      ...initialValues,
    },
    validation: {
      echelons: validateEchelon,
    },
    required,
  });

  const { values, touched, errors } = state;
  const {
 setValues, handleChange, validateForm, setTouched, setAllTouched, setErrors,
} = actions;
  const [categoriesState, getCategoriesCall] = useApiState(getCategories);
  const [getDomainState, getDomainCall] = useApiState(getDomains);
  const [keys, addKey, deleteKey] = useKeys(values.echelons);

  const emptyTableRef = useRef([]);
  const [showEchelon, setShowEchelon] = useState(true);
  let categories: Option[] = emptyTableRef.current;
  let secondaryCategory: Option[] = emptyTableRef.current;

  if (categoriesState.data) {
    categories = categoriesState.data.map((cat) => ({
      value: cat._id,
      label: cat.name,
      icon: <CategorieLogo label={cat.name} bgColor={cat.background} />,
    }));
  }
  if (getDomainState.data) {
    secondaryCategory = getDomainState.data.map((cateogry) => ({
      value: cateogry._id,
      label: cateogry.name,
    }));
  }
  useDidMount(() => {
    getCategoriesCall();
    getDomainCall();
  });

  useDidUpdate(() => {
    if (simple) setErrors({ echelons: '' });
    else setErrors({ echelons: values.echelons.length ? '' : 'Il faut ajouter au moins un echelon' });
  }, [simple]);

  useEffect(() => {
    if (validateForm()) {
      if (step === 0 && !simple) {
        setStep(1);
        setAllTouched(false);
        setErrors({ echelons: 'Il faut ajouter au moins un echelon' });
      }
    }
    // eslint-disable-next-line
  }, [simple]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [step]);

  function onTagsChange(tags: string[]) {
    if (!touched.tags) {
      setTouched({ tags: true });
    }
    setValues({ tags });
  }

  function checkForSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (validateForm()) {
      if (step === 0 && !simple) {
        setStep(1);
        setAllTouched(false);
        setErrors({ echelons: 'Il faut ajouter au moins un echelon' });
      } else onSubmit({ ...values, echelons: values.echelons.filter((val) => val) });
    } else {
      setAllTouched(true);
    }
  }

  const editEchelon = (echelonValues: EchelonFormValues, index: number) => {
    const nextEchelons = [...state.values.echelons];
    nextEchelons[index] = echelonValues;
    setValues({ echelons: nextEchelons });
  };

  const addEchelon = (echelonValues: EchelonFormValues) => {
    setValues({ echelons: [...state.values.echelons, echelonValues] });
  };

  useEffect(() => {
    if (values.echelons.length >= 1) setShowEchelon(false);
    else if (values.echelons.length === 0) setShowEchelon(true);
    window.scrollTo({ top: 150 });
  }, [values.echelons.length]);

  useEffect(() => {
    setErrors({ echelons: validateEchelon(values.echelons) });
    setTouched({ echelons: false });
    // eslint-disable-next-line
  }, [step]);

  const simpleBadge = (
    <div className={classNames(classes.formContainer, className)}>
      {!simple ? null : <Title title="Caractéristiques de l'Open Badge" className={classes.titleBadgeFeature} />}
      <div className={classes.featureFormSelectRoot}>
        <TextField
          name="title"
          required
          label="Intitulé de l'Open Badge"
          className={classes.title}
          value={values.title}
          onChange={handleChange}
          errorText={touched.title && errors.title}
        />
        <Select
          name="mainCategory"
          required
          label="Catégorie de l'Open Badge"
          itemClassName={classes.itemClassName}
          options={categories}
          className={classes.category}
          onChange={handleChange}
          value={values.mainCategory}
          errorText={touched.mainCategory && errors.mainCategory}
          selectInputClassName={classes.selectMenu}
        />
        <Select
          name="secondaryCategory"
          required
          label="Domaine de l'Open Badge"
          options={secondaryCategory}
          value={values.secondaryCategory}
          className={classes.domain}
          onChange={handleChange}
          errorText={touched.secondaryCategory && errors.secondaryCategory}
          selectInputClassName={classes.selectMenu}
        />
        <TextField
          name="description"
          required
          label="Description de l'Open Badge"
          placeholder="Décrivez l'Open Badge en quelques lignes"
          className={classes.description}
          multiline
          rows={6}
          value={values.description}
          onChange={handleChange}
          errorText={touched.description && errors.description}
        />
        <TagsInput
          required
          label="Mots-clés"
          tags={values.tags}
          onTagsChange={onTagsChange}
          className={classes.key}
          errorText={touched.tags && errors.tags}
          keywords
        />
        <CheckBox
          name="public"
          label="Rendre l'Open Badge visible sur le catalogue"
          className={classes.checkbox}
          checked={values.public}
          onChange={handleChange}
        />
      </div>
      <div className={classes.textRequired}>
        <span className={classes.requiredMark}>* </span>
        <span> champs obligatoires </span>
      </div>
    </div>
  );

  const BadgeEchelon = (
    <div className={classes.badgeEchelonContainer}>
      <img alt="" src={iconEchelon} />
      <span className={classes.badgeEchelonText}>Échelons</span>
      {' '}
      <span style={{ textTransform: 'lowercase', marginLeft: '5px' }}> de l'</span>
      <span>Open Badge</span>
    </div>
  );
  const BadgeFeature = (
    <div className={classes.badgeEchelonContainer}>
      <span className={classes.badgeEchelonText}>Caractéristiques</span>
      {' '}
      <span style={{ textTransform: 'lowercase', marginLeft: '5px' }}> de l'</span>
      <span>Open Badge</span>
    </div>
  );

  return (
    <form onSubmit={checkForSubmit} className={classNames(classes.formContainer, className)}>
      <span className="features_form_title">{title}</span>
      {simple ? (
        simpleBadge
      ) : (
        <TabNavigation
          isCreate
          fixed={false}
          value={step}
          className={classes.tabNavigation}
          selectedTabClassName={classes.selectedTabClassName}
          keepMounted
          onChange={(e, nextStep) => setStep(nextStep)}
          tabs={[
            {
              name: BadgeFeature,
              element: simpleBadge,
            },
            {
              name: BadgeEchelon,

              disabled: step !== 1 && !validateForm(),
              element: (
                <div className={classes.echelonFormRoot}>
                  <p className={classes.echelonDescription}>
                    Configurez les échelons de votre badge, l'obtention des différents échelons permettront d'obtenir le badge "mère".
                  </p>
                  {!simple && (
                    <div>
                      {values.echelons.map((echelonValues, index) => (
                        <EchelonForm
                          addFormPropBorder={classes.addFormPropBorder}
                          borderForm
                          className="features_form_echelon_input"
                          onDelete={() => {
                            deleteKey(index);
                            setValues({ echelons: state.values.echelons.filter((e, i) => index !== i) });
                          }}
                          onSubmit={(v) => editEchelon(v, index)}
                          // eslint-disable-next-line
                          key={keys[index]}
                          {...echelonValues}
                          resetOnClose
                          done
                          titleForm={(
                            <div className={classes.badgeEchelonContainer}>
                              <img alt="" src={badgeEchelon} className={classes.echelonImage} />
                              <span className={classes.echelonText}>
                                Échelon
                                {index + 1}
                              </span>
                            </div>
                          )}
                        />
                      ))}
                      {values.echelons.length < 4 && showEchelon && (
                        <div>
                          <EchelonForm
                            addFormPropComponentClassName={classes.addFormPropComponentClassName}
                            className="features_form_echelon_input"
                            onSubmit={(nextValues) => {
                              addKey();
                              addEchelon(nextValues);
                            }}
                            initialOpen
                            resetOnSubmit
                            errorText={touched.echelons && errors.echelons}
                            showTextNewEchelon={showEchelon && values.echelons.length >= 1}
                          />
                          <div className={classes.textRequired} style={{ marginBottom: '0 !important' }}>
                            <span className={classes.requiredMark}>* </span>
                            <span> champs obligatoires </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ),
            },
          ]}
        />
      )}
      {values.echelons.length >= 1 && !simple && step === 1 && (
        <Button className={classes.buttonAddNewEchelon} max size="large" onClick={() => setShowEchelon(true)}>
          Ajouter un nouvel échelon
        </Button>
      )}

      <Button className={classes.buttonFeature} max size="large" type="submit">
        Continuer
      </Button>
    </form>
  );
};

CreateBadgeFeatures.defaultProps = {
  initialValues: {},
};

export default CreateBadgeFeatures;
