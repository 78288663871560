import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    headerClosed: {
      height: 48,
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 4,
      display: 'flex',
    },
    buttonPopUp: {
      background: theme.palette.info.main,
      width: 232,
      marginRight: 24,
    },
    title: {
      fontSize: 20,
      fontWeight: 300,
      flex: '1 1 0%',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    TextTitleRow: {
      fontSize: 30,
      fontWeight: 'normal',
      marginRight: 60,
    },
    criteriaTextTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      maxWidth: 0,
      wordBreak: "break-all",
      textOverflow: "ellipsis"
    },
    iconsContainer: {
      display: 'flex',
      color: theme.palette.grey.A100,
      cursor: 'pointer',
    },
    iconsRoot: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 40,
    },
    iconText: {
      fontSize: 16,
      marginRight: 12,
      fontWeight: 'bold',
    },
    addPropFormContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },

    addFormPropComponent: {
      marginBottom: 27,
      padding: '12px 40px',
      borderRadius: 10,
      paddingLeft: 62,
    },

    addFormPropComponentError: {
      borderColor: theme.palette.error.main,
    },
    borderFormNone: {
      border: 'none',
    },
    addFormErrorText: {
      position: 'relative',
      bottom: 40,
      display: 'flex',
      alignItems: 'center',
      left: 62,
    },
    addFormPropBorder: {
      border: 'solid 1px #5b78c2',
    },

    cancelText: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 'bold',
      marginLeft: 24,
      cursor: "pointer"
    },

    popUpContainer: {
      marginTop: 39,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textDescription: {
      fontSize: 20,
      fontWeight: 'normal',
      marginTop: 48,
    },
    deleteContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 45,
    },
  }));
