// tslint-react-hooks/rules-of-hooks
import React, { useState, useContext } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import useApiState from 'hooks/useApiState';
import { confirmUser } from 'requests/auth';
import { useDidMount, useDidUpdate } from 'hooks/useLifeCycle';
import { setAuthorizationBearer } from 'requests/http';
import localforage from 'localforage';
import userContext from 'contexts/userContext';
import CustomizedSnackbars from 'components/ui/SnackBar';
import { decodeUri } from 'utils/url';

type IVariant = 'success' | 'warning' | 'error' | 'info';

const Confirm = ({ location }: RouteComponentProps) => {
  const [confirmUserState, confirmUserCall] = useApiState(confirmUser);

  const { setUser } = useContext(userContext);

  const [redirect, setRedirect] = useState('');

  const [textModal, setTextModal] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const [variantModal, setVariantModal] = useState<IVariant>('success');

  useDidMount(() => {
    const { token } = decodeUri(location.search);
    confirmUserCall({ token });
  });

  useDidUpdate(() => {
    if (!confirmUserState.fetching) {
      if (confirmUserState.data) {
        setAuthorizationBearer(confirmUserState.data.token.accessToken);
        localforage.setItem('auth', confirmUserState.data);
        setUser(confirmUserState.data.user);
        setOpenModal(true);
        setVariantModal('success');
        setTextModal('Confirmation avec succès');
        setTimeout(() => {
          setRedirect('/profile');
        }, 500);
      } else {
        setOpenModal(true);
        setVariantModal('error');
        setTextModal('Problème de confirmation');
        setTimeout(() => {
          setRedirect('/');
        }, 500);
      }
    }
    // eslint-disable-next-line
  }, [confirmUserState.fetching]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      Activation en cours ...
      <CustomizedSnackbars variant={variantModal} open={openModal} handleClose={handleCloseModal} message={textModal} />
    </div>
  );
};

export default Confirm;
