import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const HEADER_HEIGHT = 91;

export default makeStyles<Theme>((theme: Theme) =>
    createStyles({
        mainContainer: {
            height: '100%',
            padding: '0px 25px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                padding: '0px 10px',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0px 5px',
            },
        },
        mainRouteContainer: {
            display: 'flex',
            color: '#ffff',
            fontSize: 14,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        tabRootSelected: {
            backgroundColor: 'rgba(227, 229, 255, 0.3)',
        },
        headerFirstName: {
            backgroundColor: '#e3e5ff',
            borderRadius: '50%',
            width: 42,
            height: 42,
            marginLeft: 21,
            fontSize: 30,
            color: theme.palette.primary.main,
            fontWeight: 'normal',
            fontFamily: 'Rakkas',
            display: 'flex',
            alignItems: 'center !important',
            justifyContent: 'center',
        },
        menuContainer: {
            marginLeft: 20,
        },
        wrapperButton: {
            color: '#0C3D9C !important',
            backgroundColor: '#00C4D1',
            fontWeight: 'bold',
            fontSize: '14px',
            borderRadius: '5px',
            padding: '4px 12px',
        },

        responsiveWeb: {
            display: "flex",
            alignItems: "center",
            height: "100%",
            [theme.breakpoints.down('sm')]: {
                display: "none"
            },
        },

        headerMobile: {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.up('md')]: {
                display: "none"
            },
        },
        iconMobile: {
            cursor: "pointer",
            background: "rgba(227, 229, 255, 0.3)",
            height: 32,
            width: 32,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            padding: 21,
            boxShadow: "0 0 5px 5px rgba(0,0,0,.22)"
        }
    }),
);
