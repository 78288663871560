import React, { useContext, useState, useEffect } from 'react';
// components
import moment from 'moment';
import Text from 'components/ui/Text';
import userContext from 'contexts/userContext';
import RatingProfile from 'components/profile/RatingProfile/RatingProfile';

// Api
import { getSocialUser, getImageUser } from 'requests/user';
import { Network } from 'requests/network';
// hooks
import useApiState from 'hooks/useApiState';
import { useDidMount } from 'hooks/useLifeCycle';

import AddLogo from 'assets/svg/defaultUser_v3.svg';
// style
import useStyles from './style';
import { Container } from '@material-ui/core';

const CardUser = () => {
  const classes = useStyles();
  const { user } = useContext(userContext);

  const [getSocialState, getSocialCall] = useApiState(getSocialUser);
  const [getImageState, getImageCall] = useApiState(getImageUser);

  const [imageUser, setImage] = useState('');

  const [dataSocial, setDataSocial] = useState<Network[]>([]);
  useDidMount(() => {
    if (user) {
      getSocialCall(user._id);
      getImageCall(user._id);
    }
  });

  useEffect(() => {
    if (!getSocialState.fetching && !getSocialState.errors && getSocialState.data) {
      setDataSocial(getSocialState.data);
    }
  }, [getSocialState.fetching, getSocialState.errors, getSocialState.data]);

  useEffect(() => {
    if (!getImageState.fetching && !getImageState.errors && getImageState.data) {
      setImage(getImageState.data);
    }
  }, [getImageState.fetching, getImageState.errors, getImageState.data]);
  return (
    <div className={classes.card_user_wrapper}>
      <Container>
        <div style={{ display: 'flex', padding: '50px 0px', justifyContent: 'space-between' }}>
          <div className={classes.card_user_container}>
            <div className={classes.card_image_container}>
              <div className={classes.card_image_wrapper}>
                {imageUser ? (
                  <img src={imageUser} className={classes.imgUser} alt="user_Image" />
                ) : (
                  <div className={classes.add_image}>
                    <img src={AddLogo} alt="default_logo" />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.card_info_container}>
              <div className={classes.text_info_name}>
                <Text label={user?.fullName} className={classes.capitalizeTextRight} />
              </div>
              {user?.userType === 'user' && (
                <div className={classes.text_info_age}>
                  {user?.age && (
                    <Text
                      label={`${
                        user?.age
                          ? moment()
                              .diff(user?.age, 'years')
                              .toString()
                          : ''
                      } ans`}
                    />
                  )}
                </div>
              )}
              {user?.userType === "organization" &&
                <span className={classes.text_info_bannier}>Structure</span>
              }
              <div className={classes.network_container}>
                {dataSocial.map((el: Network) => (
                  <div className={classes.network_item} key={el._id}>
                    <a target="_blank" rel="noreferrer" href={el.link}>
                      <img src={el.social.path} alt="" className={classes.imgNetwork} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <RatingProfile />
        </div>
      </Container>
    </div>
  );
};
export default CardUser;
