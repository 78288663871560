import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const HEADER_HEIGHT = 91;

export default makeStyles<Theme>((theme: Theme) =>
  createStyles({
    listBarMobile: {
      background: '#0C3D9C',
      width: '100%',
      position: 'fixed',
      top: 91,
      zIndex: 99999999,
      borderTop: '2px solid rgba(227, 229, 255, 0.3)',
      [theme.breakpoints.up('md')]: {
        display: "none"
      },
    },
    mobileLink: {
      color: "#fff",
      padding: "10px 15px",
    },
    mobileLinkActive: {
      backgroundColor: "rgba(227, 229, 255, 0.3)"
    }
  }),
);
