import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  forgetPasswordContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  connexionTitle: {
    height: 186,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  forgetPasswordRoot: {
    display: 'flex',
    padding: '100px 150px',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.light,
    flex: 1,
    width: '100%',
    minHeight: 700,
  },
  submitButton: {
    width: 284,
    fontSize: 17,
  },
  forgetPasswordLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 30,
  },
  forgetPasswordText: {
    color: '#424242',
    fontSize: 20,
    marginRight: 85,
  },
  titleForgetPassword: {
    textAlign: 'center',
    lineHeight: 0.95,
  },
  imageContainer: {
    marginTop: '-10px',
  },
  textSuccesContainer: {
    marginTop: 67,
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 54,
    color: '#424242',
    lineHeight: '30px',
  },
  emailContainer: {
    color: theme.palette.success.main,
  },
  textRequired: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 40,
    marginTop: 15,
  },
  requiredMark: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));
