import React from 'react';
import Modal from '@material-ui/core/Modal';

import Title from 'components/common/Title/Title';
import Button from 'components/buttons/Button';

import cancel from 'assets/svg/cancel.svg';
import add from 'assets/svg/blueadd.svg';

import classNames from 'utils/classNames';

import useStyles from './styles';

interface IProps {
  open: boolean;
  handleClose: () => void;
  addNewQuestion?: () => void;
  children: React.ReactElement | React.ReactChild[];
  iconClassName?: string;
  title: React.ReactChild | React.ReactChild[];
  description?: React.ReactChild | React.ReactChild[];
  done?: boolean;
  onSubmit: () => void;
}

const ModalContainer = ({
  open,
  handleClose,
  children,
  iconClassName,
  title,
  description,
  done,
  addNewQuestion,
  onSubmit,
}: IProps) => {
  const classes = useStyles();
  return (
    <Modal
      BackdropProps={{ className: classes.backdrop }}
      open={open}
      onClose={handleClose}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
    >
      <div className={classes.modalContainer}>
        <div onClick={(e) => e.stopPropagation()} className={classes.modal}>
          <img src={cancel} alt="" onClick={handleClose} className={classNames(classes.cancel, iconClassName)} />
          <Title title={title} className={classes.title} />
          <p className={classes.description}>{description}</p>
          <div className={classes.header}>
            {children}
            <div onClick={addNewQuestion} className={classes.addTextContainer}>
              <img src={add} alt="" />
              <span className={classes.text}>Nouvelle question</span>
            </div>
          </div>
          <div className={classes.textRequired}>
            <span className={classes.requiredMark}>* </span>
            <span> champs obligatoires </span>
          </div>
          <Button max size="large" onClick={onSubmit} type="button" className={classes.submitButtonQuestion}>
            {done ? 'Modifier le questionnaire' : 'Finaliser le questionnaire'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalContainer;
