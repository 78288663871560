import React, { useState, useRef, useEffect } from 'react';

import classNames from 'utils/classNames';

import './scss/icon.scss';

const ICON_SIZE = 38;

interface Props {
  d: string;
  onClick?: () => void;
  className?: string;
  selected?: boolean;
}

const Icon = ({
 d, onClick, className, selected,
}: Props) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [size, setSize] = useState(ICON_SIZE);
  const [transform, setTransform] = useState('');

  // eslint-disable-next-line
  useEffect(() => {
    const svg = svgRef.current;

    if (svg) {
      const path = svg.querySelector<SVGPathElement>('path');

      if (path) {
        const pathBox = path.getBBox();
        if (pathBox.height !== 0 && pathBox.width !== 0) {
          const diff = Math.abs(pathBox.height - pathBox.width);
          let x = -pathBox.x;
          let y = -pathBox.y;

          if (pathBox.height > pathBox.width) {
            x += diff / 2;
          } else {
            y += diff / 2;
          }
          setTransform(`translate(${x} ,${y})`);
          setSize(ICON_SIZE * Math.min(300 / pathBox.height, 300 / pathBox.width));
        }
      }
    }
  });

  return (
    <div className={classNames('components_ui_icon flex_center', selected && 'components_ui_icon_selected', className)}>
      <div onClick={onClick} className="icon_container">
        <svg
          className="absolute_fill"
          width={size}
          height={size}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 300 300"
          ref={svgRef}
        >
          <path fill={selected ? '#fff' : '#5B78C2'} transform={transform} d={d} />
        </svg>
      </div>
    </div>
  );
};

export default Icon;
