import { axiosPost, Response } from './http';
import { Network } from './network';

export interface Token {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
}

export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  completed: number;
  active: boolean;
  role: 'user' | 'admin';
  structure: string;
  userType: string;
  age: string;
  image: string;
  social: Network[];
  website: string;
}

export interface LoginResponse {
  token: Token;
  user: User;
}

export const login = (data: { email: string; password: string }): Promise<Response<LoginResponse>> =>
  axiosPost('/auth/login', { data, sendToken: false });

export const refreshToken = (data: { email: string; refreshToken: string }): Promise<Response<Token>> =>
  axiosPost('/auth/refresh', { data, sendToken: false });
export const register = (data: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  structure: string;
  age: string;
  userType: string;
  website?: string;
}): Promise<Response<LoginResponse>> => axiosPost('/auth/register', { data, sendToken: false });

export const forgotPassword = (data: { email: string }): Promise<Response<any>> =>
  axiosPost('/auth/forgot', { data, sendToken: false });

export const resetPassword = (data: { token: string; password: string }): Promise<Response<any>> =>
  axiosPost('/auth/reset', { data, sendToken: false });

export const confirmUser = (data: any): Promise<Response<any>> =>
  axiosPost('/auth/confirm', { data, sendToken: false });
export const resendConfirm = (): Promise<Response<any>> => axiosPost('/auth/resend');
