import React, { useContext, useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { useForm } from 'hooks/useInputs';
import useApiState from 'hooks/useApiState';
import { useDidUpdate } from 'hooks/useLifeCycle';
import { resetPassword } from 'requests/auth';
import CustomizedSnackbars from 'components/ui/SnackBar';
import { decodeUri } from 'utils/url';
import userContext from 'contexts/userContext';
import { validatePassword } from 'utils/validation';
import Text from 'components/ui/Text';
import Button from 'components/buttons/Button';
import TextField from 'components/inputs/TextInput';
import './scss/reset.scss';

type IVariant = 'success' | 'warning' | 'error' | 'info';

const RegisterContainer = ({ location, history }: RouteComponentProps) => {
  const { user } = useContext(userContext);

  const [formState, formActions] = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validation: {
      password: validatePassword,
      confirmPassword: validatePassword,
    },
  });
  const { values, errors, touched } = formState;
  const { handleChange } = formActions;
  const [resetState, resetCall] = useApiState(resetPassword);
  const [openModal, setOpenModal] = useState(false);
  const [variantModal, setVariantModal] = React.useState<IVariant>('success');
  const [textModal, setTextModal] = useState('');
  const { from } = decodeUri(location.search);
  useDidUpdate(() => {
    if (!resetState.fetching) {
      setOpenModal(true);
      if (!resetState.errors && !resetState.errorCode) {
        setVariantModal('success');
        setTextModal('Mot de passe modifié ! Vous pouvez vous connecter !');
        setInterval(() => {
          history.push('/login');
        }, 3000);
      } else {
        setVariantModal('warning');
        setTextModal('probleme de changement de mot de passe');
      }
    }
  }, [resetState.fetching]);
  if (user) {
    return <Redirect to={from || '/'} />;
  }

  const onSubmit = () => {
    const { password, confirmPassword } = values;
    if (formActions.validateForm()) {
      const path = location.search;
      if (path.includes('token', 1)) {
        const token = path.slice(path.indexOf('=') + 1, path.length);
        if (token !== '' && password === confirmPassword) {
          resetCall({ token, password });
        }
      }
    } else {
      formActions.setTouched({ password: true, confirmPassword: true });
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <div className="flex_column flex_1 containers_login_reset">
      <div className="login_container_reset">
        <div className="login_Card_reset">
          <form onSubmit={onSubmit}>
            <Text label="Mise à jour de mot de passe" className="title_component" />
            <div className="input_wrapper_reset">
              <TextField
                id="outlined-basic"
                label="Nouveau mot de passe"
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                autoComplete="off"
              />
              <p className="errorMessage_reset">{touched.password ? errors.password : ''}</p>
            </div>
            <div className="inputWrapper_reset">
              <TextField
                id="outlined-basic"
                label="Nouveau mot de passe"
                name="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={handleChange}
                autoComplete="off"
              />
              <p className="errorMessage_reset">{touched.confirmPassword ? errors.confirmPassword : ''}</p>
            </div>
            <Button onClick={onSubmit} variant="contained">
              Valider
            </Button>
          </form>
          <CustomizedSnackbars
            variant={variantModal}
            open={openModal}
            handleClose={handleCloseModal}
            message={textModal}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterContainer;
