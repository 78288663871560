import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      display: 'none',
    },
    tabNavigationContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0%',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '60px 0px',
      overflowX: 'hidden',
      width: '100%',
    },
    tabNavigationHide: {
      display: 'none',
    },
    tabRoot: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 'bold',
      position: 'relative',
      maxWidth: 'none !important',
      padding: 20,
      width: 'auto !important',
      minWdth: 'auto',
      opacity: 1,
      minHeight: 80,
      background: theme.palette.success.light,
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'center',
    },

    wrapperButton: {
      color: theme.palette.primary.main,
      backgroundColor: '#00d197',
      fontWeight: 'bold',
      fontSize: '14px',
      borderRadius: '5px',
      padding: '4px 12px',
    },
    headerItems: {
      display: 'flex',
      alignItems: 'center',
    },
    headerFirstName: {
      backgroundColor: '#e3e5ff',
      borderRadius: '50%',
      width: 42,
      height: 42,
      marginLeft: 21,
      fontSize: 30,
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      fontFamily: 'Rakkas',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selectedTab: {
      background: theme.palette.success.main,
    },
    relativeTab: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
      background: theme.palette.success.light,
    },
    isCreatingTab: {
      background: 'transparent',
    },
    fixedTab: {
      position: 'fixed',
      top: 91,
      zIndex: 6,
    },
    placeHolder: {
      position: 'relative',
      width: '100%',
    },
  }),
);
