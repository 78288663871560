import React, { useState, useContext } from 'react';
import CustomizedSnackbars from 'components/ui/SnackBar';
import Title from 'components/ui/Text';
import Button from 'components/buttons/Button';
import { resendConfirm } from 'requests/auth';
import localforage from 'localforage';
import userContext from 'contexts/userContext';
import { setAuthorizationBearer } from 'requests/http';
import useApiState from 'hooks/useApiState';
import { useDidUpdate } from 'hooks/useLifeCycle';

import './scss/modalBlock.scss';

type IVariant = 'success' | 'warning' | 'error' | 'info';

const ModalBlock = () => {
  const [resendState, resendCall] = useApiState(resendConfirm);
  const [variantModal, setVariantModal] = React.useState<IVariant>('success');
  const [textModal, setTextModal] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { setUser } = useContext(userContext);

  const resend = () => {
    resendCall();
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useDidUpdate(() => {
    if (resendState.fetching) {
      setOpenModal(true);
      if (!resendState.errors) {
        setVariantModal('success');
        setTextModal('E-mail envoyé avec succès');
      } else {
        setVariantModal('error');
        setTextModal("probléme d'envoie");
      }
    }
  });
  const onClose = () => {
    localforage.clear();
    setAuthorizationBearer(null);
    setUser(null);
    setOpenModal(false);
  };
  return (
    <div className="width_100 height_100 flex_center">
      <CustomizedSnackbars variant={variantModal} open={openModal} handleClose={handleCloseModal} message={textModal} />
      <div className="modal_block_container">
        <div className="modal_block_content flex_center">
          <Title label="Pensez à activer votre compte !" className="title_component title_margin" />
          <Title
            label="Tant que votre compte n'est pas activé, vous ne pourrez pas commencer à émettre ou obtenir des Open Badges. Surveillez votre boîte mail !"
            className="text_menu_component title_margin"
          />
          <Title
            label="Vous n'avez pas encore reçu d'e-mail d'activation ? Cliquez sur le bouton ci-dessous pour le renvoyer :"
            className="text_tab_bar_component title_margin"
          />
          <div className="title_button">
            <Button variant="contained" onClick={resend} size="medium">
              Ré-envoyer
            </Button>
            <Button variant="contained" onClick={onClose} size="medium">
              Fermer
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalBlock;
