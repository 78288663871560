import React, { useState, useEffect, useContext, useMemo } from 'react';
import { uniqBy } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import localforage from 'localforage';
import CardUser from 'components/cards/CardUser/CardUser';
import BadgeContainer from 'components/profile/BadgeContainer/BadgeContainer';
import { setAuthorizationBearer } from 'requests/http';
import userContext from 'contexts/userContext';
import { getMyCandidature, Candidature } from 'requests/candidature';
import { getUserBadges, Badge } from 'requests/badges';
import useApiState from 'hooks/useApiState';
import { useDidMount } from 'hooks/useLifeCycle';
import classNames from 'utils/classNames';
import Title from 'components/common/Title/Title';
import Edit from 'assets/svg/picto_edit.svg';
import logoutBlue from 'assets/svg/logoutBlue.svg';

import useStyle from './style';
import { getMyAwards } from '../../requests/award';
import { Container } from '@material-ui/core';

const ProfileContainer = () => {
  const classes = useStyle();
  const history = useHistory();
  const { user, setUser } = useContext(userContext);

  const [getBadges, getBadgesCall] = useApiState(getUserBadges);

  // 2021-04-19: switch Candidature to AwardBadge
  // const [getCandidature, getCandidatureCall] = useApiState(getMyCandidature);
  // const [candidatureData, setCandidatureData] = useState<Candidature[]>([]);
  const [getAwards, getAwardsCall] = useApiState(getMyAwards);

  const [data, setDate] = useState<Badge[] | undefined>([]);
  useDidMount(() => {
    user?.userType === "user" && getAwardsCall()
    getBadgesCall();
    // 2021-04-19: switch Candidature to AwardBadge
    // getCandidatureCall();
  });
  useEffect(() => {
    if (!getBadges.fetching && !getBadges.errors && getBadges.data) {
      setDate(getBadges.data);
    }
  }, [getBadges.fetching, getBadges.errors, getBadges.data]);

  // 2021-04-19: switch Candidature to AwardBadge
  /* useEffect(() => {
    if (getCandidature.data) {
      setCandidatureData(getCandidature.data);
    }
  }, [getCandidature.data]);
  const formattedArray = uniqBy(
    candidatureData.filter((b) => b.status === 'accepted').map((el) => el.idBadge),
    ({ _id }) => _id,
  ); */

  const formattedAwards = useMemo(() => {
    if (getAwards.data) return getAwards.data.map((v: any) => v.idBadge);
    return [];
  }, [getAwards.data]);

  return (
    <div className={classNames(classes.profile_container, 'flex_column')}>
      <div className={classes.titlesContainer}>
        <div className={classes.connexionTitle}>
          <Title row title="Mon profil" className={classes.titleContainer} />
        </div>
        <div className={classes.headContainer}>
          <Link to="/myaccount">
            <div className={classes.containerBtnNavigate}>
              <span className={classes.contentBtnNavigate}>Modifier mes infos</span>
              <img src={Edit} alt="edit" width={11} height={11} />
            </div>
          </Link>
          <div
            className={classes.logout}
            onClick={() => {
              localforage.clear();
              setAuthorizationBearer(null);
              setUser(null);
              history.push('/');
            }}
          >
            <img src={logoutBlue} alt="" className={classes.logoutLogo} />
            Déconnexion
          </div>
        </div>
      </div>
      <CardUser />
      <Container>
        {user?.userType === 'user' && (
          <BadgeContainer
            targetPath="/marketplace"
            label={`Mes Open Badges (${formattedAwards.length})`}
            data={formattedAwards}
            type="candidature"
          />
        )}
        <BadgeContainer
          targetPath="/create-badge"
          label={data?.length ? `Mes Open Badges émis (${data?.length})` : 'Mes Open Badges émis'}
          data={data}
          type="user"
        />
      </Container>
    </div>
  );
};
export default ProfileContainer;
