import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@material-ui/core';

const Spinner: FunctionComponent = () => (
  <div style={{ marginTop: "1rem", marginBottom: "1rem", textAlign: "center" }}>
    <CircularProgress />
  </div>
  );

export default Spinner;
